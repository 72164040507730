export default {
  pageIntro: "Application reference number:",
  weWillCallYou: {
    paragraphOne:
      "One of our colleagues will contact you within the next 5 days.",
    paragraphTwo:
      "Please do not submit another application as this could affect your credit record.",
    pageSubheading: "What you can do now",
    pageCopy:
      "If you want to talk to us about your application please call us on 0800 997 996*"
  },
  callUs: {
    warningBox: {
      paragraphOne:
        "Please call us on 0800 997 996* to confirm the next steps.",
      paragraphTwo:
        "Do not submit another application as this could affect your credit record."
    }
  },
  timeMonFri: "Mon-Fri: 8am-9pm",
  timeSat: "Sat: 8am-8pm",
  timeSun: "Sun: 10am-6pm",
  link: "https://bank.marksandspencer.com/",
  linkText: "Navigate to M&S Bank"
};
