import repaymentMethods from "../../i18n/lookups/repaymentMethods";

export default code => {
  var status;
  for (var i = repaymentMethods.length; i--; ) {
    if (repaymentMethods[i].value === code) {
      status = repaymentMethods[i].label;
      break;
    }
  }
  return status;
};
