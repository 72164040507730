import {
  personalDetails,
  contactDetails,
  employmentDetails,
  financialDetails,
  setupPreferences
} from "./developmentValues";

export default [
  {
    index: 0,
    label: "Personal Details",
    loadData: []
  },
  {
    index: 1,
    label: "Contact Details",
    loadData: { ...personalDetails }
  },
  {
    index: 2,
    label: "Employment Details",
    loadData: { ...personalDetails, ...contactDetails }
  },
  {
    index: 3,
    label: "Financial Details",
    loadData: { ...personalDetails, ...contactDetails, ...employmentDetails }
  },
  {
    index: 4,
    label: "Setup and preferences",
    loadData: {
      ...personalDetails,
      ...contactDetails,
      ...employmentDetails,
      ...financialDetails
    }
  },
  {
    index: 5,
    label: "Review",
    loadData: {
      ...personalDetails,
      ...contactDetails,
      ...employmentDetails,
      ...financialDetails,
      ...setupPreferences
    }
  }
];
