export const TRACK_TIMEOUT_POPUP = {
  event_category: "content",
  event_action: "popup",
  event_content: "timeout warning"
};

export const TRACK_EXAMPLES_OF_CHANGE = {
  event_category: "content",
  event_action: "accordion",
  event_content: "examples of change"
};

export const TRACK_TAX_RESIDENCY_INFO = {
  event_category: "content",
  event_action: "accordion",
  event_content: "why do we need tax residency info	"
};
