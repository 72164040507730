export const titles = [
  { label: 'Mr', value: 'mr' },
  { label: 'Mrs', value: 'mrs' },
  { label: 'Miss', value: 'miss' },
  { label: 'Ms', value: 'ms' },
  { label: 'Dr', value: 'dr' },
  { label: 'Lady', value: 'lady' },
  { label: 'Lord', value: 'lord' },
  { label: 'Rev', value: 'rev' }
];
