import axios from "axios";

import Headers from "./headers";
import { productDecisionRequest } from "./requestBuilder";
import { afisCall } from "./afisCall";
// This is necessary to allow mocks in tests
// https://stackoverflow.com/questions/51269431/jest-mock-inner-function
import * as submitApplication from "./submitApplication";

export const callApi = async (url, data, headers, method = "POST") =>
  axios({
    url,
    method,
    data,
    headers
  })
    .then(response => ({
      success: true,
      result: response
    }))
    .catch(({ response }) => ({
      success: false,
      result: response
    }));

// These are necessary as a minimum so we can test correctly
// but also to abstract the individual calls
export const callDivaResult = async headers =>
  await submitApplication.callApi(
    process.env.REACT_APP_DIVA_RESULT,
    {},
    headers,
    "GET"
  );
export const callAfis = async (afisData, headers) =>
  await submitApplication.callApi(
    process.env.REACT_APP_AFIS,
    afisData,
    headers
  );
export const callProductDecision = async (productDecisionValues, headers) =>
  await submitApplication.callApi(
    process.env.REACT_APP_PRODUCT_DECISION,
    productDecisionValues,
    headers
  );
export const callGlobalChecks = async (productDecisionValues, headers) =>
  await submitApplication.callApi(
    process.env.REACT_APP_GLOBAL_CHECK,
    productDecisionValues,
    headers
  );
export const callDivaArchive = async (divaArchiveData, headers) =>
  await submitApplication.callApi(
    process.env.REACT_APP_DIVA_ARCHIVE,
    divaArchiveData,
    headers
  );

// APPLICATION SUBMIT SEQUENCE
export const submit = async (values, context) => {
  const {
    divaTransactionId,
    divaXHsbcState,
    divaCompleted,
    sessionId
  } = context;
  const afisData = JSON.stringify(afisCall(values, sessionId));
  const productDecisionValues = JSON.stringify(productDecisionRequest(values));
  let productDecisionResponse = {};
  let headers = Object.assign({}, Headers);

  try {
    // DIVA RESULT
    // set x-hsbc-state with the /diva-init one if DIVA is completed
    if (divaCompleted) {
      headers["x-hsbc-state"] = divaXHsbcState;
      const divaResultResponse = await submitApplication.callDivaResult(
        headers
      );

      // DIVA RESULT - SUCCESS
      // override /diva-init x-hsbc-state with the /diva-result one
      // if it fails, we still send the /diva-init state to /afis-checks
      if (divaResultResponse.success)
        headers["x-hsbc-state"] =
          divaResultResponse.result.headers["x-hsbc-state"];
    }

    // AFIS
    const afisResponse = await submitApplication.callAfis(afisData, headers);

    // AFIS RESULT - SUCCESS
    // override /diva-init or /diva-result add x-state with the /afis-checks one
    if (afisResponse.success)
      headers["x-hsbc-state"] = afisResponse.result.headers["x-hsbc-state"];

    // PRODUCT DECISION
    productDecisionResponse = await submitApplication.callProductDecision(
      productDecisionValues,
      headers
    );

    // PRODUCT DECISION - ERROR
    if (!productDecisionResponse.success)
      return submitApplication.handleError(productDecisionResponse.result); // maybe history too

    // PRODUCT DECISION - SUCCESS
    // override /diva-init or /diva-result add x-state with the /cc-ntb-application one
    headers["x-hsbc-state"] =
      productDecisionResponse.result.headers["x-hsbc-state"];

    // DIVA ARCHIVE
    if (divaCompleted) {
      const divaArchiveData = JSON.stringify({
        transactionId: divaTransactionId,
        postcode: values["post-code"]
      });
      await submitApplication.callDivaArchive(divaArchiveData, headers);
    }

    // GLOBAL CHECK
    if (productDecisionResponse.result.data.conductGScheck) {
      const globalCheckResponse = await submitApplication.callGlobalChecks(
        productDecisionValues,
        headers
      );

      // GLOBAL CHECK - ERROR
      if (!globalCheckResponse.success)
        return submitApplication.handleGlobalCheckError(
          globalCheckResponse.result
        );

      // GLOBAL CHECK - SUCCESS
      // If we do global checks, we want to ignore the showAgreement flag from product decision
      // as the one from global checks will be the final one on whether to show the agreements page
      productDecisionResponse.result.data.showAgreement =
        globalCheckResponse.result.data.showAgreement;
    }

    // If no errors were returned above
    // HANDLE DECISION BACK IN <APP/>
    return submitApplication.handleDecision(productDecisionResponse.result);
  } catch (exception) {
    // All exceptions are handled within the try block
    // This will never be called
  }
};
export const handleDecision = response => {
  return {
    success: true,
    result: response,
    xState: response.headers["x-hsbc-state"]
  };
};
export const handleGlobalCheckError = error => ({
  success: false,
  result: error,
  referPageType: "exception",
  history: {
    url: "/complete",
    options: {
      redirection: true,
      page: "referException"
    }
  }
});
export const handleError = error => ({
  success: false,
  result: error,
  history: {
    url: "/error",
    options: {
      redirection: true,
      page: "error"
    }
  }
});
