import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "babel-polyfill";

/* eslint-env browser */
import content from "../../i18n/content/setUpPreferences.js";
import { setUpPreferencesSchema } from "../../shared/validation-schema.js";
import Account from "../../organisms/Account";
import { link, view } from "../../shared/analytics/tealium/events";
import { TRACK_CARD_SETUP } from "../../shared/analytics/pageData.js";
import {
  valueToTealiumLabel,
  utagGlobal,
  validationErrors
} from "../../shared/analytics/tealium.js";
import repaymentMethods from "../../i18n/lookups/repaymentMethods";
import { AppConsumer } from "../../shared/appContext";

import {
  Section,
  Heading,
  HorizontalRule,
  Paragraph,
  Callout
} from "@piggybank/core";
import creditLimitIncrease from "../../i18n/lookups/creditLimitIncrease";
import Timer from "../../components/Timer";
import {
  Legend,
  Checkbox,
  Form,
  Field,
  Fieldset,
  FieldFeedback,
  FormFeedback,
  Navigation,
  Radio
} from "@piggybank/form";
import FormWrapper from "../../organisms/FormWrapper/index.js";
import formatTitle from "../../shared/transformations/formatTitle";

const initialValues = {
  sortCode: "",
  accountNumber: "",
  directDebit: "",
  "marketing-preferences": false,
  "credit-limit": "",
  repaymentMethod: "",
  bankDetailsCorrect: "N",
  "repayment-account": ""
};

const reviewData = values => {
  if (values.directDebit !== "yes") {
    values.repaymentMethod = "";
    values.sortCode = "";
    values.accountNumber = "";
  }
};

const eventTaggingConstants = {
  page_name: TRACK_CARD_SETUP.page_name,
  page_url: TRACK_CARD_SETUP.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_CARD_SETUP.funnel_step_name
};

const eventTagging = values => {
  link({
    ...eventTaggingConstants,
    event_category: "content",
    event_action: "radio",
    event_content:
      "credit limit offer: " +
      valueToTealiumLabel(values["credit-limit"], creditLimitIncrease)
  });
  if (values.repaymentMethod !== "") {
    link({
      ...eventTaggingConstants,
      event_category: "content",
      event_action: "radio",
      event_content:
        "direct debit repayment amount: " +
        valueToTealiumLabel(values.repaymentMethod, repaymentMethods)
    });
  }

  if (values["repayment-account"] !== "") {
    link({
      ...eventTaggingConstants,
      event_category: "content",
      event_action: "radio",
      event_content:
        "confirm direct debit repayments statements: " +
        values["repayment-account"]
    });
  }
  if (values["marketing-preferences"] === true) {
    link({
      ...eventTaggingConstants,
      event_category: "content",
      event_action: "checkbox",
      event_content: "marketing preferences opt-in"
    });
  }
};

class SetUpPreferences extends Component {
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_CARD_SETUP));
    document.title = formatTitle(content.pageTitle);
  }

  handleSubmit = (next, rest) => {
    if (
      rest.values.directDebit === "yes" &&
      rest.values["repayment-account"] === "no"
    ) {
      rest.values.directDebit = "no";
    }
    eventTagging(rest.values);
    next(rest);
  };

  render() {
    return (
      <FormWrapper>
        <div className="SetUpPreferences">
          <Section marginBottom={7}>
            <Heading level={1}>{content.pageTitle}</Heading>
            <Paragraph lead>{content.pageIntro}</Paragraph>
            <HorizontalRule />
          </Section>
          <Form
            initialValues={initialValues}
            validationSchema={setUpPreferencesSchema}
            // test custom validation
            validate={values => {
              let errors = {};
              if (
                values.bankDetailsCorrect === "N" &&
                values.directDebit === "yes" &&
                values["repayment-account"] === "yes"
              ) {
                errors.bankDetailsCorrect = content.bankDetails.errorBlank;
              }
              return errors;
            }}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
            onSubmit={this.handleSubmit}
          >
            {({ values, validateForm }) => (
              <Fragment>
                <FormFeedback />

                <Account
                  content={content}
                  values={values}
                  validateForm={validateForm}
                />

                <Section marginBottom={6}>
                  <Heading level={2} data-bdd="credit-limit-increase-heading">
                    {content.creditLimit.sectionTitle}
                  </Heading>

                  <Paragraph data-bdd="credit-limit-increase-copy">
                    {content.creditLimit.sectionCopy}
                  </Paragraph>
                  <Callout type="information">
                    <Paragraph data-bdd="credit-limit-increase-info-one">
                      {content.creditLimit.infoCopyOne}
                    </Paragraph>
                    <Paragraph
                      data-bdd="credit-limit-increase-info-two"
                      marginBottom={0}
                    >
                      {content.creditLimit.infoCopyTwo}
                    </Paragraph>
                  </Callout>
                  <Fieldset
                    name="credit-limit"
                    data-bdd="credit-limit"
                    marginBottom={4}
                  >
                    <Legend marginBottom={1} id="credit-limit--label">
                      {content.creditLimit.label}
                    </Legend>
                    {creditLimitIncrease.map(cli => (
                      <Radio fullWidth value={cli.value} key={cli.value}>
                        {cli.label}
                      </Radio>
                    ))}
                    <FieldFeedback marginTop={1} />
                  </Fieldset>
                </Section>
                <Section marginBottom={6}>
                  <Heading level={2} data-bdd="marketing-preferences-heading">
                    {content.marketingPreferences.sectionTitle}
                  </Heading>

                  <Section data-bdd="marketing-preferences-copy">
                    <Paragraph marginBottom={3}>
                      {content.marketingPreferences.paragraph}
                    </Paragraph>

                    <Section data-bdd="marketing-preferences">
                      <Field
                        name="marketing-preferences"
                        data-bdd="marketing-preferences"
                        id="marketing-preferences"
                      >
                        <Checkbox>
                          {content.marketingPreferences.label}
                        </Checkbox>
                      </Field>
                    </Section>
                  </Section>
                </Section>
                <Section>
                  <HorizontalRule />
                  <Navigation
                    textMap={{ previous: "Back", next: "Continue" }}
                    id="next"
                    onNext={(next, rest) => {
                      if (this.context.BDVVerified !== true) {
                        this.context.BDVVerified = false;
                      }
                      this.context.Continue = true;
                      reviewData(values);
                      next(rest);
                    }}
                  />
                </Section>
              </Fragment>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormWrapper>
    );
  }
}
SetUpPreferences.propTypes = {
  values: PropTypes.object,
  navigateTo: PropTypes.func
};
SetUpPreferences.displayName = "SetUpPreferences";
SetUpPreferences.contextType = AppConsumer;

export default SetUpPreferences;
