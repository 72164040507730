export default {
    pageTitle: "Legal Information",
    pageHeading: "Important information",
    masthead: {
        pageIntro: 'We\'ve accepted your application for a ',
        pageSubheading: 'M&S Credit Card',
        pageSubheadingCardOffer: "offer"
    },
    warningBox: {
        title: 'It\'s important that you read and fully understand the following documents. If anything is unclear, please  contact us before signing.',
        sectionOneLinkText: 'Pre-Contract Credit Information',
        sectionTwoLinkText: 'Key Points About Your Credit Card Agreement',
        sectionThreeLinkText: 'Credit Card Agreement'
    },
    preContract: {
        title: 'PRE-CONTRACT CREDIT INFORMATION',
        subTitle: '(Standard European Consumer Credit Information)',
        pointOne: {
            title: 'Contact details',
            table: [
                {
                    columnOne: {
                        paragraphOne: 'Creditor.',
                        paragraphTwo: 'Address.',
                        paragraphThree: 'Telephone number(s).'
                    },
                    columnTwo: {
                        paragraphOne: 'Marks & Spencer Financial Services plc',
                        paragraphTwo: 'Kings Meadow, Chester CH99 9FB',
                        paragraphThree: '0345 900 0900'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Credit intermediary.',
                        paragraphTwo: 'Address.',
                    },
                    columnTwo: {
                        paragraphOne: 'One of the following may have acted as credit intermediary in connection with the agreement:',
                        list: [
                            'Marks and Spencer plc, Waterside House, 35 North Wharf Road, London W2 1NW',
                            'We Are Rectangle Limited, Marland House, Huddersfield Road, Barnsley S70 2LW',
                        ]
                    }
                }
            ]
        },
        pointTwo: {
            title: 'Key features of the credit product',
            table: [
                {
                    columnOne: {
                        paragraphOne: 'The type of credit'
                    },
                    columnTwo: {
                        paragraphOne: 'Credit card'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'The total amount of credit.',
                        paragraphTwo: 'This means the amount of credit to be provided under the proposed credit agreement or the credit limit.'
                    },
                    columnTwo: {
                        paragraphOne: 'We will confirm your credit limit after we have signed the agreement. If we change it later, we will tell you before we make the change.',
                        paragraphTwo: 'Your limit will be based on our view of your ability to repay us from information we get from credit reference agencies, your application and how you manage the account.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'How and when credit would be provided.'
                    },
                    columnTwo: {
                        paragraphOne: 'You will normally be able to use the account when we have sent you a card.',
                        paragraphTwo: 'You, and any person you have asked us to give an additional card to, can borrow for the following types of transaction:',
                        list: [
                            'To make a purchase.',
                            'To obtain cash or make a cash related payment.',
                            'To send a payment from your account to a bank account in your name (money transfer) or to reduce or repay an amount you owe another lender (balance transfer) (where available).'
                        ]
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'The duration of the credit agreement.'
                    },
                    columnTwo: {
                        paragraphOne: 'The agreement will begin when we have signed it and opened the account linked to your card.',
                        paragraphTwo: 'The agreement does not have a fixed end date.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Repayments.',
                        paragraphTwo: 'Your repayments will pay off what you owe in the following order.'
                    },
                    columnTwo: {
                        paragraphOne: 'We will send you a statement each month showing the minimum payment for that month (we will not send a statement if you do not need to make a payment). You can always pay more than the minimum payment to reduce the amount of interest you have to pay.',
                        paragraphTwo: 'The minimum payment will be the higher of:',
                        list: [
                            'The sum of (a) interest for the period from the last statement, (b) any default charges and (c) 1% of the full amount you owe as shown on your monthly statement (not including interest and default charges).',
                            '2.5% of the full amount you owe as shown on your monthly statement (including interest and charges).',
                            '£5.'
                        ],
                        paragraphThree: 'If you owe less than £5, you must pay the full amount you owe.',
                        paragraphFour: 'If the amount you pay in a month is less than the full amount you owe, we will apply your payment first to any unpaid arrears or amounts over the credit limit.',
                        paragraphFive: 'We apply what is left in this order:',
                        paragraphSix: 'First: up to the minimum payment.',
                        paragraphSeven: 'Second: to the rest of the amount you owe us as shown in your statement.',
                        paragraphEight: 'Third: to any transactions, interest or charges not yet included in a statement.',
                        paragraphNine: 'In each case, we will apply the payment first to any amounts on your account which we charge at the highest interest rate followed by amounts we charged at lower rates.  We always pay off interest and charges first.',
                        paragraphTen: 'If some amounts are charged at the same interest rate, we will apply your payment to the oldest amounts first.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'The total amount you will have to pay.',
                        paragraphTwo: 'This means the amount you have borrowed plus interest and other costs.'
                    },
                    columnTwo: {
                        paragraphOne: '£<TOTAL AMOUNT>',
                        paragraphTwo: 'We do not know when this agreement is made how much you will actually pay, or the cost, because this depends on how much you borrow, how quickly you repay it and whether we make any changes to interest rates or charges or other terms (e.g. if we increase the minimum monthly payment).',
                        paragraphThree: 'For this reason, regulations require us to assume when we work out the APR and total amount payable that the credit limit is £1,200, you spend the full amount on a purchase when the account is opened and you repay it, with interest at the standard rate for purchases stated in this agreement, by 12 equal monthly payments.'
                    }
                }
            ]
        },
        pointThree: {
            title: 'Costs of the credit',
            table: [
                {
                    columnOne: {
                        paragraphOne: 'The rates of interest which apply to the credit agreement'
                    },
                    columnTwo: {
                        subTitleOne: 'Introductory rates',
                        paragraphOne: 'The following annual interest rates will apply for the periods set out below:',
                        listOne: [
                            '<OFFER APR BT>% on balance transfers made within <BT OFFER ELIGIBILITY PERIOD> days of account opening for <OFFER BT TERM> months from the date of the transfer; and',
                            '<OFFER APR RETAIL>% on purchases for <OFFER RETAIL TERM>  months after account opening.'
                        ],
                        subTitleTwo: 'Standard rates',
                        paragraphTwo: 'In all other cases, and after the end of the periods set out above, the following variable standard annual interest rates will apply:',
                        listTwo: [
                            '<STANDARD APR BT>% on balance transfers or money transfers;',
                            '<STANDARD APR RETAIL>% on purchases; and',
                            '<STANDARD APR CASH>% on cash or cash related payments.'
                        ],
                        subTitleThree: 'Changes',
                        paragraphThree: 'We may change the rates we charge as set out in your agreement. This includes if we change our view of your ability to repay us due to information we receive from credit reference agencies or the way you manage your account.',
                        paragraphFour: 'At least 30 days before we make any change we will tell you about the change and how it will affect you. We will do this by including a message in your monthly statement or by writing to or emailing you separately.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Annual Percentage Rate of Charge (APR).',
                        paragraphTwo: 'This is the total cost expressed as an annual percentage of the total amount of credit.',
                        paragraphThree: 'The APR is there to help you compare different offers.'
                    },
                    columnTwo: {
                        paragraphOne: '<STANDARD APR RETAIL>% variable',
                        paragraphTwo: 'The APR is calculated using the same assumptions as for calculating the total amount you will have to pay above.',
                        paragraphThree: 'If the APR were to be calculated using the interest rate applicable to another form of drawdown, such as cash or withdrawals, then the APR may be higher.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Related costs'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Any other costs deriving from the credit agreement.',
                        paragraphTwo: 'Conditions under which the above charges can be changed.'
                    },
                    columnTwo: {
                        paragraphOne: 'You will pay charges in the circumstances set out below:',
                        subTitleOne: 'Introductory charges',
                        paragraphTwo: 'If you make a balance transfer, <OFFER BT FEE>% (or a minimum of £<OFFER BT MINIMUM FEE>) of a balance transfer made within <BT OFFER ELIGIBILITY PERIOD> days of account opening.',
                        subTitleTwo: 'Standard charges',
                        paragraphThree: 'If you make a balance transfer or money transfer, the charge will be set out in any offer made to you.',
                        paragraphFour: 'If you obtain cash, <CASH ADVANCE FEE>% (or a minimum of £<CASH ADVANCE MINIMUM FEE>) of any cash amount advanced.',
                        paragraphFive: 'If you obtain cash or make a purchase in a non-Sterling currency, <CASH ADVANCE FEE NON STERLING>% of the amount in Sterling (or a minimum of £<CASH ADVANCE MINIMUM FEE NON STERLING> for cash).',
                        subTitleThree: 'Default charges',
                        paragraphSix: 'For going over the credit limit (even if we permit it) £<OVERLIMIT FEE>.',
                        paragraphSeven: 'For payments which are returned unpaid £<UNPAID PAYMENT FEE>.',
                        paragraphEight: 'For taking action to obtain repayment, we may require you to pay our reasonable costs and expenses, including legal and tracing costs.',
                        subTitleFour: 'Changes',
                        paragraphNine: 'We may change our charges for the reasons set out in your agreement. We will notify you of changes in the same manner as set out above for interest rates.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Costs in the case of late payments.'
                    },
                    columnTwo: {
                        paragraphOne: 'You will pay £<LATE PAYMENT FEE> for paying late.',
                        paragraphTwo: 'We do not charge any interest on this or other default charges but we will charge interest on the outstanding balance at the relevant rate.',
                        paragraphThree: 'We will only change the interest rate and charges for the reasons set out above and in your agreement.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Consequences of missing payments.'
                    },
                    columnTwo: {
                        paragraphOne: 'If you repeatedly fail to pay minimum payments this could have severe consequences for you. We may:',
                        list: [
                            'demand immediate repayment of the amount you owe on the account;',
                            'disclose information about you and the conduct of your account to licensed credit reference agencies (which may make it more difficult for you to borrow in future);',
                            'restrict access to credit on other accounts you have with us (such as an overdraft or other credit cards);',
                            'bring legal action to recover what you owe us (which could include asking a court to order that, if a property you own is sold for any reason, some of the sale proceeds are used to repay this loan); and',
                            'require you to pay our reasonable costs and expenses for taking action to obtain payment, including legal costs.'
                        ],
                        paragraphTwo: 'You will lose any introductory or promotional rate if you are late in making the minimum payment in any month.'
                    }
                }
            ]
        },
        pointFour: {
            title: 'Other important legal aspects',
            table: [
                {
                    columnOne: {
                        paragraphOne: 'Right of withdrawal.'
                    },
                    columnTwo: {
                        paragraphOne: 'You have the right to withdraw from this agreement, without giving any reason. This right begins the day after you receive written confirmation from us that we have signed the agreement and ends after 14 days.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Early repayment.'
                    },
                    columnTwo: {
                        paragraphOne: 'You can repay all, or part, of the amount you have borrowed at any time.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Consultation with a Credit Reference Agency.'
                    },
                    columnTwo: {
                        paragraphOne: 'We will inform you immediately and without charge if we reject your credit application on the basis of a consultation with a credit reference agency.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Right to a draft credit agreement.'
                    },
                    columnTwo: {
                        paragraphOne: 'You have the right, upon request, to obtain a copy of the draft credit agreement free of charge, unless we are unwilling at the time of the request to proceed to the conclusion of the credit agreement.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'The period of time during which the creditor is bound by the pre-contractual information.'
                    },
                    columnTwo: {
                        paragraphOne: 'This information is valid for 30 days from the date you receive it.'
                    }
                }
            ]
        },
        pointFive: {
            title: 'Additional information in the case of distance marketing of financial services',
            table: [
                {
                    columnOne: {
                        paragraphOne: '(a) concerning the creditor'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Registration number.'
                    },
                    columnTwo: {
                        paragraphOne: 'Financial Services Register no. ',
                        paragraphOnePartTwo: '151427'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'The supervisory authority.'
                    },
                    columnTwo: {
                        paragraphOne: 'The Financial Conduct Authority.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: '(b) concerning the credit agreement'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'The law taken by the creditor as a basis for the establishment of relations with you before the conclusion of the credit agreement.'
                    },
                    columnTwo: {
                        paragraphOne: 'The laws of England and Wales.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'The law applicable to the credit agreement and/or the competent court.'
                    },
                    columnTwo: {
                        paragraphOne: 'This agreement is governed by the laws of England and Wales. Any dispute can be dealt with by an English or Welsh court unless your address is in:',
                        list: [
                            'Scotland (where it will be dealt with by the courts of Scotland); or',
                            'Northern Ireland (where it will be dealt with by the courts of Northern Ireland).'
                        ]
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Language to be used in connection with the credit agreement.'
                    },
                    columnTwo: {
                        paragraphOne: 'We will communicate with you at all times in English.'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: '(c) concerning redress'
                    }
                },
                {
                    columnOne: {
                        paragraphOne: 'Access to out-of-court complaint and redress mechanism.'
                    },
                    columnTwo: {
                        paragraphOne: {
                            text: 'If you have a complaint and you are not happy with the way that we deal with your complaint, you may be able to refer your complaint to the Financial Ombudsman Service by writing to Financial Ombudsman Service, Exchange Tower, London E14 9SR, calling 0800 023 4 567 or 0300 123 9 123 or sending an email to [MAIL-LINK].',
                            mailLink: 'complaint.info@financial-ombudsman.org.uk'
                        }
                    }
                },
            ]
        },
    },
    keyPoints: {
        title: 'KEY POINTS ABOUT YOUR CREDIT CARD AGREEMENT',
        pointOne: {
            title: 'Is this credit card right for you?',
            list: [
                'We want you to be sure the credit card meets your needs before you proceed. Please consider the Pre-Contract Credit Information we\'ve provided and the points set out below. If we\'ve given this to you in person, you can take it away to read. If you\'re joint borrowers this applies to both of you.',
                'If you\'d like any more information or have any questions about the agreement, you can call us on 0345 900 0900.'
            ]
        },
        pointTwo: {
            title: 'What should you note about the credit card?',
            list: {
                itemOne: 'Any cardholder can use the account up to the credit limit to make a purchase, obtain cash or make a cash related payment. You are responsible for use of the card by all cardholders, including any use that makes you break the agreement (e.g. if a cardholder makes a payment which takes the account over the credit limit).',
                itemTwo: 'Different interest rates and charges apply (including charges for transactions in a non-Sterling currency and default charges). For example, there\'s a charge for cash advances but not for making a purchase. All rates and charges are set out in your agreement. We do not charge interest on default charges added to the account.',
                itemThree: 'Rates and charges can be changed by us but we will tell you about the change and how it will affect you at least 30 days before we make any change. If you don\'t want to accept an interest rate increase you can close the account and pay off the amount you owe at the unchanged rate.',
                itemFour: 'If you have an introductory or promotional rate, you’ll lose it if you’re late making the minimum payment in any month. ',
                itemFive: 'If you are eligible for an introductory offer on balance transfers or purchases, the interest free or reduced interest period will last for the period set out in the agreement, after which the standard rate will apply. For balance transfers made within <BT OFFER ELIGIBILITY PERIOD> days of account opening, a balance transfer fee of <OFFER BT FEE>% (minimum £<OFFER BT MINIMUM FEE>) is payable on the amount of the transfer. We may make balance transfers or money transfers available to you at other times. If we do, we’ll tell you the charges that apply. You cannot use a balance transfer to repay amounts you owe to another company in the HSBC Group (we will tell you if we cannot make a balance transfer for this reason).',
                itemSix: {
                    item: "We'll give you a statement each month. This will show the payment due date and the minimum payment but you can always pay more to reduce the amount of interest and time it will take to pay off what you owe. The minimum payment will be the higher of:",
                    list: [
                        'The sum of (a) interest for the period from the last statement, (b) any default charges, and (c) 1% of the principal shown on your monthly statement (not including interest and default charges).',
                        '2.5% of the outstanding balance shown on your monthly statement (including interest and charges).',
                        '£5.',
                    ],
                    paragraph: 'If you owe less than £5, you must pay the full amount you owe.'
                },
                itemSeven: {
                    item: 'If you repeatedly fail to pay minimum payments on time or go over your credit limit, this could have serious consequences for you, including:',
                    list: [
                        'the total cost of your debt will grow as you will incur default charges and you’ll also keep paying interest on the amounts you should have paid;',
                        'We may:',
                        [
                            'demand that you repay the amount you owe on the account immediately;',
                            'disclose information about you and the conduct of your account to credit reference agencies (which may make it more difficult for you to borrow in future);',
                            'restrict access to credit on this account or other accounts you have with us (such as an overdraft) if we change our view of your ability to repay us;',
                            'bring legal action to recover what you owe us (which could include asking a court to order that, if a property you own is sold for any reason, some of the sale proceeds are used to repay this amount and that you pay our legal costs).'
                        ]
                    ],
                },
                itemEight: {
                    item: 'We can also cancel or suspend the use of the account if for two 18-month periods in a row, you’ve:',
                    list: [
                        'been in “persistent debt” (this is when you’ve paid more interest, fees and charges than the amount you’ve repaid. For example, if you owe £2,000 on your account at an interest rate of 18% and you incur a £12 default charge, your minimum payment for the first month would be £62, made up of interest (£30), default charges (£12) and 1% of £2,000 (the amount you owe (£20)); and ',
                        'either not responded or been unable to meet our requests to increase your repayment amounts, or it is otherwise reasonable for us to do so. '
                    ]
                },
                itemNine: 'If we change our view of your ability to repay us because of information we get from credit reference agencies or the way you manage the account (for example, because you often pay us or other lenders late), we may change the rates that apply to you (unless we are aware that you’re at risk of financial difficulties) and we may reduce your credit limit.',
                itemTen: 'This card may not be right for you if you want more certainty about the amount you borrow or repay.  Depending how you use the card there may be cheaper ways to borrow - for example, if you intend to borrow a large amount and repay it over a long period.'
            }
        },
        pointThree: {
            title: 'What can you do if you change your mind after we have given the credit card to you?',
            list: [
                'You can withdraw from this agreement (without giving any reason) by giving us written notice or calling within 14 days, starting the day after we confirm that we have signed the agreement.',
                'You must repay the amount you have borrowed within 30 days, starting the day after you tell us that you want to withdraw, with interest at the relevant rate or rates up to the date you repay. If you ask us we will tell you how much interest is payable per day. You can repay by calling us.'
            ]
        }
    },
    cardAgreement: {
        title: 'CREDIT CARD AGREEMENT',
        subTitleOne: 'Key Terms',
        sectionOne: {
            title: 'Credit Card Agreement Number ',
            titlePartTwo: '<CREDIT CARD NUMBER>',
            subTitle: 'Credit Card Agreement regulated by the Consumer Credit Act 1974',
            paragraphOne: 'We will open your account and give you a credit card when you have signed and returned this agreement. The card will have a card scheme logo or logos on it (for example Visa or Mastercard). We can change card schemes in future but, if we do, the terms of this agreement will stay the same.',
            paragraphTwo: 'In this agreement:',
            paragraphThree: '‘We’ are Marks & Spencer Financial Services plc, whose registered office is at Kings Meadow, Chester CH99 9FB.',
            paragraphFour: '‘You’ are',
            paragraphNameAndAddress: '<CUSTOMER NAME> <CURRENT ADDRESS>.',
            paragraphFive: 'You must tell us immediately if your name, address, telephone number or e-mail address change. The agreement is made up of the key terms and the additional terms.',
            paragraphSix: 'One of the following may have acted as credit intermediary in connection with the agreement:',
            list: [
                'Marks and Spencer plc, Waterside House, 35 North Wharf Road, London W2 1NW',
                'We Are Rectangle Limited, Marland House, Huddersfield Road, Barnsley S70 2LW'
            ]
        },
        sectionTwo: {
            title: 'How much can you borrow?',
            paragraphOne: 'You can borrow up to the credit limit. We will confirm your credit limit after we have signed the agreement. If we change it later, we will tell you before we make the change.'
        },
        sectionThree: {
            title: 'How much do you have to pay each month and when?',
            paragraphOne: 'We will give you a statement each month showing the minimum payment for that month (we may not give you a statement if you do not need to make any payment).',
            paragraphTwo: 'The minimum payment will be the higher of:',
            list: [
                'The sum of (a) interest for the period from the last statement, (b) any default charges, and (c) 1% of the full amount you owe as shown on your monthly statement (not including interest and default charges).',
                '2.5% of the full amount you owe as shown on your monthly statement (including interest and charges).',
                '£5.'
            ],
            paragraphThree: 'For example, if the amount you owe on the statement date is £450 including £15 interest and £12 charges, A will give the highest amount so the minimum payment will be £31.23 (£15 (interest), £12 (charges) and £4.23 (1% of £423)). (B would be £11.25 (2.5% of £450)).',
            paragraphFour :'If you owe less than £5, you must pay the full amount you owe (e.g. if you owe £2.50 that will be the minimum payment).',
            paragraphFive: 'The minimum payment must reach us by the due date shown in your statement. This will be 25 days from your statement date. You can always pay more than the minimum payment to reduce the amount of interest you have to pay. Information about how to make sure payments reach us on time is on the back of the statement.',
            paragraphSix: 'You must also pay immediately the amount of any missed payments and the amount you owe above the credit limit. If you pay by Direct Debit we will collect these amounts with your minimum payment if you have not paid them already.',
            paragraphSeven: 'You can ask us to collect payments for you (e.g. by Direct Debit). You can choose how much you want to pay each month as long as it is not less than the minimum payment.  You must try not to pay into the account more than you owe us. We will refund any credit on your account. We will not normally allow you to spend it.'
        },
        sectionFour: {
            title: 'How much does it cost to borrow?',
            subSectionOne: {
                title: 'Introductory and promotional rates and charge',
                paragraphOne: 'Introductory interest rates or charges will apply for the period set out below if you qualified for them when you applied for the card.',
                table: [
                    {
                        columnOne: {
                            paragraphOne: 'Transaction type'
                        },
                        columnTwo: {
                            paragraphOne: 'Annual interest rate'
                        },
                        columnThree: {
                            paragraphOne: 'Charge'
                        },
                        columnFour: {
                            paragraphOne: 'Period'
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'Payment to reduce or repay an amount you owe another lender (balance transfer) made within <BT OFFER ELIGIBILITY PERIOD>  days of account opening'
                        },
                        columnTwo: {
                            paragraphOne: '<OFFER APR BT>%'
                        },
                        columnThree: {
                            paragraphOne: '<OFFER BT FEE>% min £<OFFER BT MINIMUM FEE>'
                        },
                        columnFour: {
                            paragraphOne: '<OFFER BT TERM> months from the date of transfer'
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'Purchase'
                        },
                        columnTwo: {
                            paragraphOne: '<OFFER APR RETAIL>%'
                        },
                        columnThree: {
                            paragraphOne: 'N/A'
                        },
                        columnFour: {
                            paragraphOne: '<OFFER RETAIL TERM> months after account opening'
                        }
                    },
                ],
                paragraphTwo: 'We may make promotional rates and charges available from time to time. If we do we\'ll tell you. We\'ll also tell you when an introductory or promotional rate is about to end.',
                paragraphThree: 'You will lose any introductory or promotional rate if you are late in making the minimum payment in any month.'
            }
        },
        sectionFive: {
            subSectionOne: {
                title: 'Standard rates and charges',
                paragraphOne: 'In all other cases (and after the end of an introductory or promotional period) our variable standard rates and charges will apply. These are the current standard rates and charges.',
                table: [
                    {
                        columnOne: {
                            paragraphOne: 'Transaction type'
                        },
                        columnTwo: {
                            paragraphOne: 'Annual interest rate'
                        },
                        columnThree: {
                            paragraphOne: 'Charges added to the account with the transaction '
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'Balance transfer and/or money transfer'
                        },
                        columnTwo: {
                            paragraphOne: '<PURCHASES EFFECTIVE APR>%'
                        },
                        columnThree: {
                            paragraphOne: 'As set out in any offer made to you'
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'Purchase'
                        },
                        columnTwo: {
                            paragraphOne: '<PURCHASES EFFECTIVE APR>%'
                        },
                        columnThree: {
                            paragraphOne: '<STANDARD RETAIL FEE>% for a purchase in Sterling'
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'Cash or cash related payment'
                        },
                        columnTwo: {
                            paragraphOne: '<CASH EFFECTIVE APR>%'
                        },
                        columnThree: {
                            paragraphOne: '<CASH ADVANCE FEE>% of the cash or payment (minimum £<CASH ADVANCE MINIMUM FEE>)'
                        }
                    },
                ],
                paragraphTwo: 'We treat buying foreign currency from an M&S Bureau de Change as a purchase, meaning purchase rates and charges will apply to these transactions (and not rates and charges applicable to cash or cash related payments).',
                paragraphThree: 'We charge interest from the date each transaction or charge is added to your account until the amount you owe us has been repaid in full. We work out interest on a daily basis and add it to the account on your monthly statement date. If you pay at least the minimum payment each month you will pay off all the interest added to the account that month but, if you don\'t, we will charge interest on that interest as well as on transactions added to your account.',
                paragraphFour: 'There are two exceptions to this. We do not charge any interest on default charges. We also do not charge interest on any purchases shown in a statement if you repay the full amount you owe us on that statement date by the next payment due date.',
                paragraphFive: 'This means that if, for example, you repay in full the amount shown in your July statement by the payment due date in August, we will not charge interest on any purchases added to your account and shown in your July statement. However, even if you don\'t use your account at all after the July statement date, there may still be \'trailing\' interest shown in your August statement. This will be interest we have charged:',
                list: [
                    'on any cash advances or payments sent from your account (such as balance transfers) in your July statement (because we always charge interest on these); and',
                    'on any amounts carried over from your June statement.'
                ],
                paragraphSix: 'We also charge interest on trailing interest so even if you pay the trailing interest in full in September we may charge further interest on the account.'
            },
            subSectionTwo: {
                title: 'Non-Sterling transaction charges',
                table: [
                    {
                        columnOne: {
                            paragraphOne: 'Transaction type'
                        },
                        columnTwo: {
                            paragraphOne: 'Charges added to the account with the transaction'
                        },
                        columnThree: {
                            paragraphOne: 'Exchange rates for the day the transaction is converted into Sterling'
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'Purchase and Cash or cash related payment'
                        },
                        columnTwo: {
                            paragraphOne: '<CASH ADVANCE FEE NON STERLING>% of the transaction in Sterling (minimum charge for cash or cash payment £<CASH ADVANCE MINIMUM FEE NON STERLING>). These charges are in addition to other charges (for example a cash transaction in Euro will have two charges).'
                        },
                        columnThree: {
                            paragraphOne: {
                                text: 'The card schemes use wholesale market rates. You can find the current exchange rates by visiting [LINK-ONE] or [LINK-TWO].',
                                linkOne: 'www.visaeurope.com/making-payments/exchange-rates',
                                linkTwo: 'www.mastercard.us/en-us/consumers/get-support/convert-currency.html'
                            }
                        }
                    },
                ],
                paragraph:'From 19 April 2020, you can find out how the cost of making card transactions within the EU compares to the foreign exchange rate(s) issued by the European Central Bank by visiting [LINK].  ',
                link: 'marksandspencer.com/OverseasPayments'
            },
            subSectionThree: {
                title: 'Default charges',
                table: [
                    {
                        columnOne: {
                            paragraphOne: 'Reason'
                        },
                        columnTwo: {
                            paragraphOne: 'Charge'
                        },
                        columnThree: {
                            paragraphOne: 'Added to the account'
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'For paying late'
                        },
                        columnTwo: {
                            paragraphOne: '£<LATE PAYMENT FEE>'
                        },
                        columnThree: {
                            paragraphOne: 'Up to 4 days after the date you should have made the payment'
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'For going over the credit limit (even if we permit it)'
                        },
                        columnTwo: {
                            paragraphOne: '£<OVERLIMIT FEE>'
                        },
                        columnThree: {
                            paragraphOne: 'When you go over the limit'
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'For payments which are returned unpaid'
                        },
                        columnTwo: {
                            paragraphOne: '£<UNPAID PAYMENT FEE>'
                        },
                        columnThree: {
                            paragraphOne: 'When your bank tells us it can\'t make the  payment'
                        }
                    },
                    {
                        columnOne: {
                            paragraphOne: 'For enforcing repayment, including legal and tracing costs'
                        },
                        columnTwo: {
                            paragraphOne: 'Our reasonable costs'
                        },
                        columnThree: {
                            paragraphOne: 'When we apply for enforcement'
                        }
                    }
                ]
            }
        },
        sectionSix: {
            title: 'APR ',
            titleTwo: '<APR>% variable',
            titlePartTwo: 'Total amount payable - £<TOTAL AMOUNT>',
            paragraphOne: 'We do not know when this agreement is made how much you will actually pay, or the cost, because this depends on how much you borrow, how quickly you repay it and whether we make any changes to interest rates or charges or other terms (e.g. if we increase the minimum monthly payment). For this reason, regulations require us to assume when we work out the APR and total amount payable that the credit limit is £1,200, you spend the full amount on a purchase when the account is opened and you repay it, with interest at the standard rate for purchases stated in this agreement, by 12 equal monthly payments.'
        },
        sectionSeven: {
            title: 'Can we change the cost of borrowing or any other terms?',
            subSectionOne: {
                title: 'Changes to terms that are personal to you',
                paragraphOne: 'If we change our view of your ability to repay us because of information we get from credit reference agencies or the way you manage the account (for example because you often pay us or other lenders late), we may change the rates that apply to you (unless we are aware that you\'re at risk of financial difficulties) and we may reduce your credit limit.'
            },
            subSectionTwo: {
                title: 'Other changes to the credit limit',
                paragraphOne: 'From time to time we may tell you that a higher credit limit is available to you and you can ask us to consider giving you a higher credit limit. You can also contact us to tell us:',
                list: [
                    'you’d like a lower credit limit;',
                    'you don’t want to receive any future credit limit increases; or',
                    'never to increase your credit limit without checking with you first'
                ],
                paragraphTwo: '(and you can change your mind at any time).',
                paragraphThree: 'A higher credit limit may offer greater flexibility and convenience to meet your financial needs but it may also increase the risk of borrowing too much, which could be expensive or take a long time to pay off. You should carefully consider whether you need a higher limit.'
            },
            subSectionThree: {
                title: 'Changes to rates, charges and other terms for particular reasons',
                paragraphOne: 'We may also:',
                list: [
                    'increase or decrease rates and charges to take account of an actual, or reasonably expected, change in the interest rates we have to pay for example as a result of a change to the Bank of England Base Rate; or',
                    'increase or decrease our rates and charges and change any other terms to reflect an actual, or reasonably expected, change in our underlying costs or in law or regulation or to our way of doing business, including the introduction of new technology; or',
                    'change any term to reflect any other actual or reasonably expected change that affects us if it is reasonable for us to pass the impact of the change on to you; or',
                    'if you are employed by us, change the interest rates and charges to our standard rates and charges when your employment ends (except if you retire).'
                ],
                paragraphTwo: 'Any change we make will be reasonably proportionate to the impact of the underlying change on us. We will not make changes to cover the same cost twice.'
            },
            subSectionFour: {
                title: 'Other changes',
                paragraphOne: 'As this agreement has no fixed end date, we may also change rates and charges and other terms for reasons that we cannot predict at the outset.'
            },
            subSectionFive: {
                title: 'Telling you about changes',
                paragraphOne: 'At least 30 days before we make any change we will tell you about the change and how it will affect you.  We will do this by including a message in your monthly statement, by writing to or emailing you separately or by sending you a mobile message or message using any secure storage facility we provide online.'
            },
            subSectionSix: {
                title: 'Your rights when we tell you about changes',
                paragraphOne: 'If you do not want to accept any change we tell you about, you can end the agreement without charge (in fact you can end the agreement at any time). The change will still apply until you have paid everything you owe us. However, if the change is an increase to an interest rate, you can repay what you owe us at the unchanged interest rate if you tell us within 60 days of notice of the increase that you do not want to accept the higher rate.'
            }
        },
        sectionEight: {
            title: 'When will the agreement begin and end?',
            paragraphOne: 'The agreement will begin when we have opened the account linked to your card. The agreement does not have a fixed end date but you and we can end it as explained below. You will normally be able to use the account when we have sent you a card.'
        },
        sectionNine: {
            itemOne: {
                title: 'Sign here only if you want to be legally bound by the terms of this Agreement.',
                paragraphOne: 'This agreement will be signed by electronic means',
                paragraphTwo: 'Signature of customer:',
                paragraphThree: 'Date of signature:'
            },
            itemTwo: {
                paragraphOne: 'Signature on behalf of M&S Bank:',
                paragraphTwo: 'Date of signature:'
            }
        },
        sectionTen: {
            title: 'Can you change your mind?',
            paragraphOne: {
                text: 'You can withdraw from this agreement (without giving any reason) by giving us written notice or calling within 14 days, starting the day after we confirm that we have signed the agreement. To tell us that you want to withdraw, please use the details under [INTERNAL-LINK] below.',
                linkText: 'Important Information',
                link: '#card-agreement-important-information'
            },
            paragraphTwo: 'You must repay the amount you have borrowed within 30 days, starting the day after you tell us that you want to withdraw, with interest at the relevant rate or rates up to the date you repay. If you ask us we will tell you how much interest is payable per day. You can repay by calling us.'
        },
        sectionEleven: {
            title: 'Can you make a claim against us where you have a claim against a supplier?',
            paragraphOne: 'If you purchase any item of goods or services costing between £100 and £30,000 using credit under this agreement you may have a right of redress against us as well as the supplier if, for example, the goods fail to arrive or are not of satisfactory quality or the supplier made a representation which you relied on about the goods which was not accurate.',
            paragraphTwo: 'However, if you have a dispute with someone else over a transaction, you must keep making payments to us while you are resolving it.'
        },
        sectionTwelve: {
            title: 'What could happen if you miss payments?',
            paragraphOne: 'Missing payments could make it more difficult to obtain credit and result in legal action or bankruptcy proceedings to recover any debt owed under this agreement.'
        },
        additionalTerms: {
            title: 'Additional Terms',
            sectionOne: {
                title: 'Who can use the account and how?',
                itemOne: {
                    paragraphOne: 'You, and any person you have asked us to give an additional card to, can use the account for the following types of transaction:',
                    list: [
                        'To make a purchase.',
                        'To obtain cash or make a cash related payment (such as buying travellers cheques or foreign currency, placing a bet (or for some other gambling payment), or purchasing money orders). We will tell you if there is a limit on the amount of cash you can withdraw each day.',
                        'To send a payment from your account to a bank account in your name or to reduce or repay an amount you owe to another lender (where available) (although we don’t normally allow you to do this where the other lender in the HSBC Group).',
                    ],
                },
                itemTwo: 'If you ask us to send a payment and it doesn’t arrive by the working day after we send it from your credit card account, you can ask us to ask the receiving bank to treat the payment as if it had been made on time.',
                itemThree: 'We will cancel an additional card when you tell us to. We do not have an agreement with any additional cardholder so we cannot give them any information about the account and you are responsible for use of the account by an additional cardholder, including any use that makes you break this agreement. For example if an additional cardholder makes a payment that takes the amount on the account over the credit limit you will be liable.'
            },
            sectionTwo: {
                title: 'How do you authorise payments?',
                itemOne: 'When you, or an additional cardholder, make a transaction it must be authorised before we can charge it to your account.  You or the additional cardholder will need to provide the card or the card number and will be told the additional steps needed to authorise the payment (except for some low value contactless payments). This could be by signing a paper or electronic document, by using the personal identification number (or PIN), biometric data (such as finger print) or other security details we may agree from time to time.',
                itemTwo: {
                    paragraphOne: 'If the card, card number and PIN, biometric data or other security details have been used we will assume the transaction has been authorised by you or an additional cardholder. You must therefore tell us immediately if you do not recognise a transaction charged to your account and shown in your statement. You and an additional cardholder must not:',
                    list: [
                        'use the card or account for any illegal purpose (or allow anyone else to do so); or',
                        'go over your credit limit.'
                    ],
                    paragraphTwo: 'When working out whether a transaction you want to make is within your credit limit, we take account of transactions which we have already authorised but have not yet charged to the account. For example, if you have £150 left within your credit limit and we give a hotel authorisation for a payment of £100, we will not allow more than £50 to be spent on the account until the authorisation is cancelled.'
                },
                itemThree: 'If you, or an additional cardholder, allow someone else to use a card or the card number, you will be liable for all transactions they make until you tell us that the card may be misused using the contact details set out below. You will not be liable for (and we will immediately refund) any other transactions not authorised by you or an additional cardholder, including transactions made with the card or card number remotely (for example by the Internet, phone or mail order).'
            },
            sectionThree: {
                title: 'When will we give refunds for payments you have authorised?',
                list: [
                    'If a retailer gives you a refund for a purchase we will only credit it to the account when we receive details from the retailer. Until then we will charge the relevant rate of interest on the purchase.',
                    'Where you, or an additional cardholder, authorise a payment but the amount of the payment is not known (e.g. if the card is used to pay a hotel bill but the amount of the payment is not known until check out) then, if the payment charged to your account is higher than reasonably expected, you can ask us to make a refund of the amount charged to your account, as long as you do this within 8 weeks of the date that the payment was charged to your account. If you do this, we may need to ask you for information to help us find out what happened. If you are entitled to a refund, we will make it within ten days of the date that we have all the information we need to make a decision.',
                    'You can\'t ask for a refund where you or the additional cardholder gave consent for the transaction directly to us and we or the retailer provided information about the transaction (or made it available) at least four weeks before the payment was made.',
                    'If you give us the wrong details for a payment or cash related transaction, or we can show that the bank we made the payment to did receive it, we won\'t have to give you a refund but we will help you to recover the money (we may charge a fee to cover our costs).'
                ]
            },
            sectionFour: {
                title: 'Can you stop payments you have authorised? ',
                list: [
                    'You can cancel payments which are due to be made on a future date (for example, if you set up a regular payment) by contacting us by the end of the business day before the payment is due to be made (normally 5pm). You must also contact the retailer to tell them you have cancelled the payment.',
                    'You cannot cancel any other transaction after you or an additional cardholder have authorised it (for example, it\'s not possible to cancel a cash withdrawal or payment for goods or services provided at the time of payment).'
                ]
            },
            sectionFive: {
                title: 'Can we refuse to authorise a transaction or suspend your right to use the account?',
                itemOne: {
                    paragraphOne: 'We may refuse to authorise a transaction and cancel or suspend use of the account and refuse to replace or reissue a card if authorising the transaction would cause a breach of this agreement or put us in breach of any legal requirements that apply to us or we reasonably:',
                    list: [
                        'believe that there is a significantly increased risk that you may not be able to repay the amount you owe us (this could be because of the way you manage this account or other accounts you have with us, information we get from credit reference agencies or us finding that you are bankrupt or have made an arrangement with your creditors);',
                        'suspect fraudulent or unauthorised use of the account;',
                        'consider it necessary for the security of the account or card;',
                        'suspect that you, any additional cardholder or third party has committed or is about to commit a crime or other abuse in connection with use of the card or the account.'
                    ],
                    paragraphTwo: 'You may be asked for further information, including verification of your identity, when we are asked to authorise a transaction.'
                },
                itemTwo: {
                    paragraphOne: 'We can also cancel or suspend the use of the account if:',
                    list: [
                        'for two 18-month periods in a row, you’ve been in “persistent debt”. (This is when you’ve paid more interest, fees and charges than the amount you’ve repaid. For example, if you owe £2,000 on your account at an interest rate of 18% and you incur a £12 default charge, your minimum payment for the first month would be £62, made up of interest (£30), default charges (£12) and 1% of £2,000 (the amount you owe) (£20)); and',
                        'you have either not responded or been unable to meet our requests to increase your repayment amounts, or it is otherwise reasonable for us to do so.'
                    ]
                },
                itemThree: 'We will tell you if we refuse to send a payment but, for other transactions, you will usually find out if we have refused a transaction because you will be asked for payment by another card, cheque or cash. We will tell you why we have refused the transaction (unless the law prevents us) if you call the contact details below.',
                itemFour: 'We are not responsible if a retailer or supplier refuses to accept your Card.'
            },
            sectionSix: {
                title: 'How do we apply payments?',
                list: {
                    itemOne: 'If the amount you pay in a month is less than the full amount you owe, we will apply your payment first to any unpaid arrears or amounts over the credit limit.',
                    itemTwo: {
                        paragraphOne: 'We apply what is left in this order:',
                        paragraphTwo: 'First: up to the minimum payment.',
                        paragraphThree: 'Second: to the rest of the amount you owe us and shown in your statement.',
                        paragraphFour: 'Third: to any transactions, interest or charges not yet included in a statement.',
                    }
                },
                listTwo: ['In each case, we will apply the payment first to amounts on your account which we charge at the highest interest rate followed by amounts we charged at lower rates or which we do not charge interest on. We always pay off interest and charges first.',
                    'If some amounts are charged at the same interest rate, we will apply your payment to the oldest amounts first.'
                ]
            },

            sectionSeven: {
                title: 'Payments into your account by mistake or fraud',
                list: [
                    'If we make a payment into your account by mistake or as a result of a systems error, we’ll automatically take the amount out of your account. If we suspect a payment into your account was the result of fraud, we’ll remove it.',
                    'If we’re told that a payment from within the European Economic Area (EEA) was made into your account by someone else’s mistake (for example if the payer gave the wrong account number), but, when we contact you, you tell us the payment was intended for you, we are legally required to share all relevant information including your name and address and transaction information with the bank the payment came from if they ask us so that the payer may contact you.',
                    'In all other cases we’ll tell you about it and, depending on how long ago the payment was made into your account, we’ll either give you time to show us that it was intended for you before we return it or we’ll get your consent. During this time we’ll make sure you can’t use the payment (we might do this by taking the amount out of your account or by limiting access to the amount on the account).'
                ]
            },
            sectionEight: {
                title: 'What steps do you need to take to keep your account secure?',
                paragraphOne: 'You must:',
                list: [
                    'take all reasonable precautions to prevent the account from being used for fraud (when we send a card to you or an additional cardholder we will tell you what precautions you can take, for example not keeping your PIN or passwords with the card or making it easy for someone else to find them);',
                    'tell us immediately using the contact details below if you think the card is lost or stolen or the card number, PIN or security details may be used by someone else or fraudulently (if you find a card you have reported as lost you must destroy it and not use it);',
                    'if we ask, make sure all cards issued on your account are destroyed immediately; and',
                    'co-operate with us and the police to investigate any unauthorised transactions.'
                ]
            },
            sectionNine: {
                title: 'Using a third party provider (TPP)',
                list: [
                    'A TPP is a third party service provider that’s authorised by or registered with the FCA or another EEA regulator or otherwise permitted by law to access information and make payments on online payment accounts operated by other providers (such as banks, building societies and credit card issuers) and has your permission to do this.',
                    'An EEA regulator is a regulator in a member state of the European Union, Iceland, Liechtenstein or Norway.',
                    'If you choose to use a TPP, the terms of this agreement will still apply. We’ll give the TPP access to account information and you’ll be able to make the same payments through the TPP that you would be able to make if you were dealing with us online. Account information will include details about who you make payments to and receive payments from. This might contain some sensitive personal information, for example, if you pay a Trades Union or Church membership subscription from your account.',
                    'Although you must not, generally, give your security details to anyone else, you may give them to a TPP if it’s necessary to allow them to provide their services to you. We may refuse to allow a TPP to access your account if we are concerned that the TPP is acting on an unauthorised or fraudulent basis. We’ll tell you if we do this (unless doing so would compromise our reasonable security measures or would otherwise be unlawful).',
                    'You must check from the information provided to you by the TPP that they are authorised. If you give your security details to an unauthorised third party, we’ll assume it’s you that is authorising us to give access to information about your accounts and you’ll be responsible for any payments made as a result. If we are aware that an unauthorised third party is using your security details, we’ll block access to your accounts.',
                    'If you think a payment made through a TPP was unauthorised or incorrect you must call us as soon as you can.'
                ]
            },
            sectionTen: {
                title: 'How and when can this agreement be ended?',
                paragraphOne: 'Ending the agreement on notice',
                itemOne: 'You can end this agreement at any time and without charge by giving us notice. We can end the agreement by giving you 2 months\' notice in writing. In either case, you (and any additional cardholder) must stop using the account and if we provide any benefits with your card, they will also end. The agreement will continue until you have repaid everything you owe.',
                paragraphTwo: 'Ending the agreement for breach',
                itemTwo: {
                    paragraphOne: 'If:',
                    itemOne: 'you repeatedly fail to pay minimum payments on time or go over your credit limit; or',
                    itemTwo: 'we find that any information you provided as part of your application was materially incorrect or misleading; or',
                    itemThree: 'you are declared bankrupt or have similar proceedings taken against you; or',
                    itemFour: {
                        paragraphOne: 'we reasonably suspect any fraud or other criminal activity in connection with this agreement and/or any other facility or account that you have with us;',
                        paragraphTwo: 'we can:',
                        list: [
                            'demand immediate repayment of the amount you owe on the account;',
                            'disclose information about you and the conduct of your account to licensed credit reference agencies (which would make it more difficult for you to borrow in future);',
                            'restrict access to credit on other accounts you have with us (such as an overdraft or other credit cards);',
                            'bring legal action to recover what you owe us (which could include getting a court order giving us the right to payment out of the sale proceeds for your home or other property); and',
                            'require you to pay our reasonable costs and expenses for taking action to obtain payment, including legal costs;'
                        ],
                        paragraphThree: 'but we will give you advance notice and the opportunity to fix the problem before taking any of these steps (unless the problem cannot be fixed).'
                    }
                },
                itemThree: 'If we choose not to enforce our rights under this agreement at any time, we may enforce them at a later date instead.'
            },
            sectionEleven: {
                title: 'Can we transfer our rights and obligations under this agreement?',
                paragraphOne: 'We may transfer our rights and our obligations under this agreement to a third party, including information about you and how you have managed your account which the third party needs to know.  We will tell you if we do this.'
            },
            sectionTwelve: {
                title: 'What law governs this agreement?',
                paragraphOne: 'This agreement and our dealings with you before the agreement is made are governed by the laws of England and Wales. Any dispute can be dealt with by an English or Welsh court unless your address is in:',
                list: [
                    'Scotland (where it will be dealt with by the courts of Scotland); or',
                    'Northern Ireland (where it will be dealt with by the courts of Northern Ireland).'
                ],
                paragraphTwo: 'We will communicate with you at all times in English.'
            },
            sectionThirteen: {
                title: 'What happens if you cannot make a payment or access your account?',
                paragraphOne: 'We will not be liable for any loss due to:',
                list: [
                    'any failure or delay in providing our service caused by strikes, industrial action, failure of power supplies or computer equipment, or other unusual or unforeseeable causes beyond our reasonable control; or',
                    'a retailer or cash machine not accepting, or retaining, your card, or the way in which this is conveyed to you.'
                ]
            },
            sectionFourteen: {
                title: 'Information we need from you and when we can disclose information about you',
                itemOne: {
                    paragraphOne: 'If we make a reasonable request for information, you must give it to us as soon as possible. If you don’t give it to us, or if we suspect fraudulent or criminal activity of any kind:',
                    list: [
                        'you might not be able to carry on doing some or all of your banking with us anymore;',
                        'we might try to get it from another source, ourselves; or',
                        'we could block or close your account(s).'
                    ],
                },
                itemTwo: 'It’s up to you to make sure the information you give us is accurate and up to date. And you must tell us if anything changes, within 30 days.',
                itemThree: 'We’ll use your information as explained in our Privacy Notice. In addition, we’ll give it to others if we’re compelled to do so by law, we have a public duty to disclose it, we need to disclose to protect our own interests (for example in any legal proceedings) or if we have your specific agreement. For example, if we believe you may have tax obligations in other countries, we may have to disclose information about you directly to HM Revenue & Customs (HMRC) or other local tax authorities.'
            },
            sectionFifteen: {
                title: 'Tax compliance',
                paragraphOne: 'Your tax responsibilities (e.g. filing tax returns, paying tax and complying with any other tax laws or regulations) depend on where you live, where you do business and some other factors. It’s up to you to make sure you meet these obligations. We can’t provide tax or legal advice so if you think having an account with M&S Bank might affect your tax position, you should speak to an independent advisor. This also applies to connected persons e.g. anyone you send a payment to, who operates an account for you, is entitled to money in your account(s) etc.'
            }
        },
        contactUs: {
            title: 'IMPORTANT INFORMATION',
            sectionOne: {
                title: 'How to contact us',
                paragraphOne: 'If you need to update your contact details, ask for information, tell us that you want to withdraw from the agreement or make a complaint please:',
                list: [
                    'write to Marks & Spencer Financial Services plc, PO Box 3848, Chester CH1 9FJ or',
                    'call 0345 900 0900'
                ],
                paragraphTwo: 'We and our authorised agents and representatives may record and/or monitor telephone conversations with you and any additional cardholder so that we can maintain our service standards.'
            },
            sectionTwo: {
                title: 'How we\'ll communicate with you',
                paragraphOne: 'We\'ll communicate with you in English.',
                paragraphTwo: 'We may give you notices or other information about your credit card, for example, in your monthly statements (in paper and electronic).',
                paragraphThree: 'We’ll use the most recent contact details you’ve given us which could include your post and email address, telephone and mobile numbers. If we need to contact you about any actual or suspected fraud or security threats, we’ll do this using the fastest and most secure way of contacting you (for example, we may try to send a text message rather than telephone).',
                paragraphFour: 'If you have given us your mobile phone number or email address, we’ll register you for free alerts so we can send you messages about certain activity on your credit card account.',
                paragraphFive: 'We are legally required to send some alerts, but you can opt out of getting other alerts. You can opt out by calling us on 0345 900 0900.'
            },
            sectionThree: {
                title: 'How can you make a complaint?',
                paragraphOne: 'If we do not give the standard of service you expect, or if you think we have made a mistake, please let us know so we can investigate, put matters right and take steps to prevent it happening again.',
                paragraphTwo: {
                    text: 'If you are not happy with the way that we deal with your complaint, you may be able to refer your complaint to the Financial Ombudsman Service by writing to Financial Ombudsman Service, Exchange Tower, London E14 9SR, calling 0800 023 4 567 or 0300 123 9 123 or sending an email to [EMAIL-LINK]. You can find out more about the Financial Ombudsman Service on their website at [LINK].',
                    link: 'www.financial-ombudsman.org.uk',
                    emailLink: 'complaint.info@financial-ombudsman.org.uk'
                }
            },
            sectionFour: {
                title: 'Direct Debit Guarantee',
                paragraphOne: 'The following Direct Debit guarantee applies to customers opting to make credit card repayments by Direct Debit.',
                list: [
                    'The Guarantee is offered by all banks and building societies that accept instruction to pay Direct Debits.',
                    'If there are any changes to the amount, date or frequency of your Direct Debit M&S Bank will notify you (normally 10 working days) in advance of your account being debited or as otherwise agreed. If you request M&S Bank to collect a payment, confirmation of the amount and date will be given to you at the time of the request.',
                    'If an error is made in the payment of your Direct Debit, by M&S Bank or your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society. If you receive a refund you are not entitled to, you must pay it back when M&S Bank asks you to.',
                    'You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify M&S Bank.'
                ]
            },
            sectionFive: {
                title: 'Taxes',
                paragraphOne: 'Taxes or costs may apply to you that are not charged by us and/or will not be paid through us.  If we have to pay any tax or duty for providing credit to you, we will charge you the amount of that tax or duty.'
            },
            sectionSix: {
                title: 'Copies',
                paragraphOne: 'You may at any time during the term of this Agreement request a copy of this Agreement.'
            },
            sectionSeven: {
                title: 'How we are authorised',
                paragraphOne: {
                    partOne: 'M&S Bank is a trading name of Marks & Spencer Financial Services plc and is established and has its registered office at Kings Meadow, Chester, CH99 9FB. We are a financial services organisation and banking institution authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Our Financial Services Register number is ',
                    partTwo: '151427',
                    partThree: '.',
                },
                paragraphTwo: {
                    text: 'You can check these details by visiting the Financial Conduct Authority website [LINK] or by contacting the Financial Conduct Authority on 0800 111 6768. The Financial Conduct Authority is the supervisory authority under the Consumer Credit Act 1974. Its address is 12 Endeavour Square, London, E20 1JN.',
                    link: 'www.fca.org.uk'
                }
            },
            sectionEight: {
                title: 'Privacy',
                paragraphOne: 'Your privacy is important to M&S Bank.',
                paragraphTwo: 'You explicitly consent to us accessing, processing and retaining any information you provide to us, for the purposes of providing payment services to you. This does not affect any rights and obligations you or we have under data protection legislation. You may withdraw this consent by closing your account. If you do this, we’ll stop using your data for this purpose, but may continue to process your data for other purposes.',
                paragraphThree: {
                    text: 'Our Privacy Notice explains how we collect, use, disclose, transfer and store your information and sets out your rights to your information. We have provided our Privacy Notice to you separately and will inform you when we make any changes to it. You can also find this at [LINK].',
                    link: 'marksandspencer.com/bankprivacynotice'
                }
            }
        },
    },
    backToTop: '↑ back to top',
    buttonLabel: "Continue"
};
