import React, { Component } from "react";
import {
  Section,
  Heading,
  Paragraph,
  Grid,
  Row,
  Cell,
  Image,
  UnorderedList,
  ListItem,
  HorizontalRule,
  FormLayout
} from "@piggybank/core";
import { Navigation } from "@piggybank/form";
import content from "../content/accept.js";
import "./Accept.css";
import { view } from "../../shared/analytics/tealium/events";
import { utagGlobal } from "../../shared/analytics/tealium";
import { TRACK_ACCEPT_PAGE } from "../../shared/analytics/pageData.js";
import AcceptMasthead from "../../organisms/AcceptMasthead";
import Timer from "../../components/Timer";
import formatTitle from "../../shared/transformations/formatTitle";
import history from "../../shared/history";

const eventTaggingConstants = {
  page_url: TRACK_ACCEPT_PAGE.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_ACCEPT_PAGE.funnel_step_name
};
class Accept extends Component {
  componentDidMount() {
    TRACK_ACCEPT_PAGE.application_id = this.props.applicationId;
    view(Object.assign(utagGlobal, TRACK_ACCEPT_PAGE));
    document.title = formatTitle(content.pageTitle);
  }

  handleErrorNavigation = () => {
    const { errorState, errorCode, applicationId } = this.props;
    if (errorState === "callYou" || errorState === "callUs") {
      TRACK_ACCEPT_PAGE.application_id = "";
      view(Object.assign(utagGlobal, TRACK_ACCEPT_PAGE));

      history.push("/error-accept", {
        redirection: true,
        eqaNumber: applicationId,
        errorState: errorState,
        errorCode: errorCode
      });
    }
  };

  render() {
    const values = this.props.acceptFields;
    return (
      <>
        <AcceptMasthead content={content.masthead} />
        <FormLayout>
          <div className="acceptMasthead__image">
            <Cell offsetLarge={7} size={6} sizeMedium={4} sizeLarge={3}>
              <Image src={this.props.cardImage} alt="" role="presentation" marginBottom={0} />
            </Cell>
          </div>
          <Section>
            {values !== undefined && (
              <>
                <Heading level={2} data-bdd="offer-sub-heading">
                  {content.offerSubHeading}
                  <br />
                  {this.props.cardType + " " + content.offerType}
                </Heading>
                <Grid>
                  <Row marginBottom={5}>
                    <Cell size={12} sizeMedium={5} sizeLarge={3} id="cell-1">
                    <Paragraph marginBottom={3}>
                        <span className="label">
                          {content.creditLimit.label}
                        </span>
                        <br />
                        {content.creditLimit.copy +
                          values.indicativeCreditLimit}
                      </Paragraph>
                      <Paragraph marginBottom={3}>
                        <span className="label">{content.apr.label}</span>
                        <br />
                        {values.standardRetailApr + content.apr.copy}
                      </Paragraph>
                    </Cell>
                    <Cell size={12} sizeMedium={4} sizeLarge={3} id="cell-2">
                      <Paragraph marginBottom={3}>
                        <span className="label">
                          {content.shoppingOffer.label}
                        </span>
                        <br />
                        {values.initialRetailApr +
                          content.shoppingOffer.copy1 +
                          values.initialRetailTerm +
                          content.shoppingOffer.copy2}
                      </Paragraph>
                      <Paragraph marginBottom={3}>
                        <span className="label">
                          {content.balanceTransfer.label}
                        </span>
                        <br />
                        {values.initialBtApr +
                          content.balanceTransfer.copy1 +
                          values.initialBtTerm +
                          content.balanceTransfer.copy2 +
                          values.initialBtEligiblePeriod +
                          content.balanceTransfer.copy3 +
                          values.initialBtFee +
                          content.balanceTransfer.copy4 +
                          values.initialBtMinFee +
                          content.balanceTransfer.copy5}
                      </Paragraph>
                    </Cell>
                  </Row>
                  <Paragraph marginBottom={7}>{content.creditLimit.asterisk(values)}</Paragraph>
                </Grid>
              </>
            )}

            <Heading level={2} data-bdd="page-sub-heading">
              {content.pageSubHeading}
            </Heading>
            <Paragraph marginBottom={3}>{content.pageCopy1}</Paragraph>
            <UnorderedList marginBottom={6}>
              {content.pageCopy1ListItems.map((item, key) => {
                return (
                  <ListItem key={item.substring(0, 3) + key}>{item}</ListItem>
                );
              })}
            </UnorderedList>
            <Section>
              <HorizontalRule />
              <Navigation
                textMap={{ next: "Continue" }}
                currentPage={this.props.currentPage}
                isLastPage={false}
                onPrevious={() => {}}
                onNext={(next, rest) => {
                  this.handleErrorNavigation();
                  next(rest);
                }}
                data-bdd="next"
              />
            </Section>
            <Timer pageTags={eventTaggingConstants} />
          </Section>
        </FormLayout>
      </>
    );
  }
}

export default Accept;
