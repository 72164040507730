import React, { Component } from "react";
import { Section, Row, Cell, Heading } from "@piggybank/core";
import { Field, FieldFeedback, Label, Select } from "@piggybank/form";
import { DateTime } from "luxon";
import { countries } from "../../i18n/lookups/countries.js";
import { UKAddress, OverseasAddress } from "../../organisms/AddressFields";

class AddressWrapper extends Component {
  constructor(props) {
    super(props);
    const showCountry = this.props.path.split(".")[1] > 0;
    this.state = {
      selectCountry: showCountry ? true : false,
      ukAddress: showCountry ? false : true,
      overseasAddress: false
    };
  }

  handleCountrySelection = value => {
    if (value === "UK") {
      value = "GB";
    }
    if (value === "GB") {
      this.setState({ ukAddress: true, overseasAddress: false });
    } else {
      this.setState({ ukAddress: false, overseasAddress: true });
    }
    return value;
  };

  componentDidUpdate() {
    const parts = this.props.path.split(".");
    if (parts[1] === "0" && this.state.selectCountry) {
      this.setState({
        selectCountry: false,
        ukAddress: true,
        overseasAddress: false
      });
      this.props.values[parts[0]][parts[1]].country = undefined;
    }

    if (this.props.path !== "NO_PATH") {
      const country = this.props.values[parts[0]][parts[1]].country;
      if (country && !this.state.ukAddress && !this.state.overseasAddress) {
        this.handleCountrySelection(country);
      }
    }
  }

  render() {
    const { content, values, path } = this.props;
    const pathDot = path ? `${path}.` : "";
    const movedOut = values => {
      var date = values["date-moved"];
      if (path) {
        let index = path.split(".")[1];
        if (index > 0) {
          date = values.previousAddresses[index - 1]["date-moved"];
        }
      }
      return date !== undefined &&
        date !== "incomplete" &&
        date !== "invalid" &&
        date.length === 7
        ? DateTime.fromISO(date + "-01").toFormat("MMMM yyyy")
        : "";
    };
    return (
      <Section aria-live="assertive" marginBottom={0}>
        <Heading level={3}>
          Where did you live before {movedOut(values)}?
        </Heading>
        {this.state.selectCountry && (
          <Field
            name={`${pathDot}country`}
            onChange={(next, { value, ...rest }) => {
              value = this.handleCountrySelection(value);
              next({ value, ...rest });
            }}
          >
            <Label marginBottom={1}>{content.countrySelect.label}</Label>
            <Row>
              <Cell size={11} sizeMedium={7} sizeLarge={5}>
                <Select
                  fullWidth
                  options={countries}
                  placeholder={content.countrySelect.placeholder}
                />
              </Cell>
            </Row>
            <FieldFeedback />
          </Field>
        )}
        {this.state.ukAddress && <UKAddress {...this.props} />}
        {this.state.overseasAddress && <OverseasAddress {...this.props} />}
      </Section>
    );
  }
}

export default AddressWrapper;
