import React, { Component } from "react";
import { getIn } from "formik";

import { Section, Heading, VisuallyHidden, Row, Cell } from "@piggybank/core";

import {
  Label,
  Select,
  Field,
  FieldFeedback,
  Hint,
  AddAnother
} from "@piggybank/form";
import content from "../../i18n/content/personalDetails.js";
import { countries } from "../../i18n/lookups/countries.js";

class Nationality extends Component {
  handleCountriesChange = value => {
    if (value === "UK") {
      return "GB";
    } else return value;
  };

  render() {
    const { values } = this.props;
    return (
      <Section marginBottom={6}>
        <Heading level={2} data-bdd="nationality-and-residency">
          {content.nationalitySection}
        </Heading>

        <Field
          name="country-of-birth"
          data-bdd="country-of-birth"
          onChange={(next, { value, ...rest }) => {
            value = this.handleCountriesChange(value);
            next({ value, ...rest });
          }}
        >
          <Label marginBottom={1}>{content.countryOfBirth.label}</Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <Select
                fullWidth
                options={countries}
                placeholder={content.countryOfBirth.placeholder}
              />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>

        <Field
          name="country-of-residence"
          data-bdd="country-of-residence"
          onChange={(next, { value, ...rest }) => {
            value = this.handleCountriesChange(value);
            next({ value, ...rest });
          }}
        >
          <Label marginBottom={1}>{content.countryOfResidence.label}</Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <Select fullWidth options={countries} />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>
        <Field
          name="nationality"
          data-bdd="nationality"
          onChange={(next, { value, ...rest }) => {
            value = this.handleCountriesChange(value);
            next({ value, ...rest });
          }}
          marginBottom={0}
        >
          <Label marginBottom={1}>{content.nationality.label}</Label>
          <Hint data-bdd="nationality-hint">{content.nationality.hint}</Hint>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <Select
                fullWidth
                placeholder={content.nationality.placeholder}
                options={
                  countries.filter(
                    ({ value }) =>
                      !values["additional-nationalities"].includes(value) &&
                      !(
                        value === "UK" &&
                        (values.nationality === "GB" ||
                          values["additional-nationalities"].includes("GB"))
                      )
                  )
                  // Remove the values from additionalNationalities.
                  // Remove UK if GB already selected anywhere.
                }
              />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>
        <Row>
          <Cell sizeMedium={7} sizeLarge={5}>
            <AddAnother
              name="additional-nationalities"
              data-bdd="additional-nationalities"
              fullWidth
              itemInitialValue={""}
              max={2}
              showAdd={!!values.nationality}
              marginBottom={0}
              textMap={{
                addButton: (
                  <>
                    Add another
                    <VisuallyHidden> nationality</VisuallyHidden>
                  </>
                ),
                removeButton: (
                  <>
                    Remove
                    <VisuallyHidden> nationality</VisuallyHidden>
                  </>
                )
              }}
            >
              {({ path }) => (
                <Field
                  name={path}
                  onChange={(next, { value, ...rest }) => {
                    value = this.handleCountriesChange(value);
                    next({ value, ...rest });
                  }}
                  marginBottom={0}
                >
                  <Label marginBottom={1}>
                    {content.nationality.labelAdditional}
                  </Label>
                  <Select
                    fullWidth
                    placeholder={content.nationality.placeholder}
                    options={
                      countries.filter(
                        ({ value }) =>
                          value === getIn(values, path) ||
                          (value !== values.nationality &&
                            !values["additional-nationalities"].includes(
                              value
                            ) &&
                            !(
                              value === "UK" &&
                              (values.nationality === "GB" ||
                                values["additional-nationalities"].includes(
                                  "GB"
                                ))
                            ))
                      )
                      // Include the current value.
                      // Remove the value of the nationality field.
                      // Remove the other values from additionalNationalities.
                      // Remove UK if GB already selected.
                    }
                  />
                  <FieldFeedback />
                </Field>
              )}
            </AddAnother>
          </Cell>
        </Row>
      </Section>
    );
  }
}

export default Nationality;
