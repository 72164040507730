import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import AppContextWrapper from "./App";
import { Router } from "react-router-dom";
import { AppProvider } from "./shared/appContext";
import "@piggybank/core/lib/styles.mands.css";
import "@piggybank/form/lib/styles.mands.css";
import "./index.css";
import history from "./shared/history";

ReactDOM.render(
  <Router history={history}>
    <AppProvider>
      <AppContextWrapper />
    </AppProvider>
  </Router>,
  document.getElementById("root")
);
