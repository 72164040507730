import { trimmedCountryName } from "../transformations";

export default list => {
  const countries = [];
  list.forEach(function(item, index) {
    (function(index) {
      countries.push(country(item, index));
    })(index);
  });
  return countries;
};

const country = (code, index) => ({
  index,
  nationality: trimmedCountryName(code),
  nationalityCode: code
});
