import React, { Component } from "react";
import { Paragraph, Heading, Section } from "@piggybank/core";
import { Navigation } from "@piggybank/form";
import content from "../../../i18n/content/divaError.js";
import { disableBackButton } from "../../../shared/browser";
import { view } from "../../../shared/analytics/tealium/events";
import { TRACK_DIVA_ERROR } from "../../../shared/analytics/pageData.js";
import { utagGlobal } from "../../../shared/analytics/tealium";
import { setFocus } from "../../../shared/utils";

class DivaError extends Component {
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_DIVA_ERROR));
    document.title = `${content.pageTitle}`;
    disableBackButton();
    setFocus("page-title");
  }

  render() {
    return (
      <Section id="diva-error-bio">
        <Heading
          level={1}
          data-bdd="page-title"
          id="page-title"
          tabIndex={-1}
          marginBottom={5}
          aria-live="polite"
        >
          {content.pageTitle}
        </Heading>
        <Paragraph data-bdd="paragraph-one">{content.paragraphOne}</Paragraph>
        <Paragraph data-bdd="paragraph-two" marginBottom={6}>
          {content.paragraphTwo}
        </Paragraph>

        <Navigation
          textMap={{ submit: content.ctaText }}
          disablePrevious={true}
          isLastPage={true}
          currentPage={1}
          onPrevious={() => {}}
          id="cta-button"
          data-bdd="cta-button"
          marginBottom={4}
        >
          {content.ctaText}
        </Navigation>
      </Section>
    );
  }
}
export default DivaError;
