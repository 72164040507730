export default {
  pageTitle: "You're nearly there",
  pageCopy1: "Application reference number",
  pageCopy2:
    "To comply with the UK’s anti-money laundering requirements and to protect your security we need to confirm your identity and address. We will be unable to proceed with your application until we receive the required documentation.",
  pageSubHeading: "	What you need to do",
  pageCopy3:
    "Make a note of your reference number and attach it to the acceptable documents below.",
  pageCopy4: "Please see our list of ",
  pageCopy5: "Post your documents to",
  pageCopy7: "We'll send you an email once we have processed your documents.",
  pageCopy8: "If you have any questions please",
  addressLine1: "M&S Bank",
  addressLine2: "PO Box 10573",
  addressLine3: "51 Saffron Road",
  addressLine4: "Wigston",
  addressLine5: "LE18 9GD",
  pageLink1: "acceptable documents",
  pageLinkUrl1: "https://bank.marksandspencer.com/pdf/UW-IDV.pdf",
  pageLink3: " contact us",
  pageLinkUrl3: "https://bank.marksandspencer.com/explore/contact-us/",
  buttonLabel: "Navigate to M&S Bank"
};
