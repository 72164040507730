import React, { Component } from "react";
import {
  FormLayout,
  Section,
  Heading,
  Paragraph,
  Button,
  Cell,
  Image,
  Link
} from "@piggybank/core";
import content from "../content/acceptComplete.js";
import { view } from "../../shared/analytics/tealium/events";
import { utagGlobal } from "../../shared/analytics/tealium";
import { TRACK_COMPLETE_PAGE } from "../../shared/analytics/pageData.js";
import AcceptMasthead from "../../organisms/AcceptMasthead";
import Timer from "../../components/Timer";
import { disableBackButton } from "../../shared/browser";

const eventTaggingConstants = {
  page_url: TRACK_COMPLETE_PAGE.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_COMPLETE_PAGE.funnel_step_name
};
class AcceptComplete extends Component {
  componentDidMount() {
    TRACK_COMPLETE_PAGE.application_id = this.props.applicationId;
    view(Object.assign(utagGlobal, TRACK_COMPLETE_PAGE));
    document.title = `${content.pageTitle}`;
    disableBackButton();
  }

  handleRedirect() {
    window.location = "https://bank.marksandspencer.com/6154";
  }

  render() {
    return (
      <>
        <AcceptMasthead content={content.masthead} />
        <FormLayout>
          <div className="acceptMasthead__image">
            <Cell
              offsetLarge={7}
              size={6}
              sizeMedium={4}
              sizeLarge={3}
              id="card-image-cell"
            >
              <Image src={this.props.cardImage} alt="" role="presentation" marginBottom={0} />
            </Cell>
          </div>

          <Section>
            <>
              <Heading level={2} data-bdd="offer-sub-heading">
                {content.pageHeading}
              </Heading>
              <Paragraph marginBottom={7}>{content.pageCopy1}</Paragraph>

              <Heading data-bdd="page-sub-heading" level={2}>
                {content.digitalBanking.heading}
              </Heading>
              <Paragraph>
                {content.digitalBanking.preLinkCopy}
                <Link external href={content.digitalBanking.link.url}>
                  {content.digitalBanking.link.text}
                </Link>
                {content.digitalBanking.postLinkCopy}
              </Paragraph>
              <Paragraph>
                {content.digitalBanking.activationCopy}
              </Paragraph>
              <Paragraph>
                {content.digitalBanking.deviceCompatibilityCopy}
              </Paragraph>

              <Button
                data-bdd="home-page-button"
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  this.handleRedirect();
                }}
              >
                {content.buttonLabel}
              </Button>
            </>
          </Section>
          <Timer pageTags={eventTaggingConstants} />
        </FormLayout>
      </>
    );
  }
}

export default AcceptComplete;
