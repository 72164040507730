export default {
  pageTitle: "Card set-up and preferences",
  pageIntro:
    "Tell us whether you'd like to set up Direct Debit payments and how you'd like to be contacted.",
  directDebit: {
    sectionTitle: "Direct Debit repayments",
    labelRadio: "Would you like to set up Direct Debit repayments?",
    errorBlank: "Tell us if you would like to set up a Direct Debit"
  },
  bankDetails: {
    errorBlank: "Verify your bank details before continuing"
  },
  repaymentMethod: {
    sectionTitle: "Repayment amount",
    label: "Choose how much you want to repay each month.",
    info:
      "If you would like to set up a Direct Debit payment for a fixed amount, you can do this via Internet Banking once your account is open. Alternatively you can call us on 0345 900 0900.",
    errorBlank: "Choose your repayment option",
    review: "How much do you want to repay each month?"
  },
  creditLimit: {
    sectionTitle: "Credit limit increase offers",
    sectionCopy:
      "From time to time we may send you an increased credit limit offer for your M&S credit card.",
    infoCopyOne:
      "You can update your credit limit preference at any time in the future by signing into Internet Banking and amending your Credit limit options, calling us on 0345 900 0900.",
    infoCopyTwo:
      "A higher credit limit may offer greater flexibility and convenience to meet your financial needs. However, it may also increase the risk of taking on too much debt, which may be costly, or take a long time to pay off. You should carefully consider whether you need a higher limit.",
    label:
      "How would you like your credit limit increases to be applied to your account?",
    errorBlank: "Choose how you would like credit limit increases to be applied"
  },
  repaymentAccount: {
    sectionTitle: "Repayment account",
    sectionIntro: "Please confirm the following statements are correct:",
    criteria1: "The account to be debited is in my name.",
    criteria2: "The account is a personal account and not a business account.",
    criteria3:
      "I am the only person required to authorise Direct Debits from this bank account.",
    labelRadio: "I confirm the above statements are correct.",
    errorBlank: "Confirm the statements are correct.",
    copy:
      "A Direct Debit can only be set up on a personal account in your own name where you are the only person required to authorise Direct Debits from this account. You can set up a Direct Debit via Internet Banking once your account is open.",
    sectionCopy: "Please tell us your sort code and account number."
  },

  marketingPreferences: {
    sectionTitle: "Marketing preferences",
    paragraph:
      "We, other members of the HSBC Group, members of Marks and Spencer Group and other organisations approved by Marks and Spencer plc (which may include insurance companies) would like to keep you informed about special offers, products and services available from the HSBC Group, the Marks and Spencer Group and selected third parties by post, telephone, email, mobile text and picture messaging.",
    label: "Tick this box if you wish to receive this information",
    review: "Do you wish to receive marketing information?"
  }
};
