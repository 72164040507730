import React, { Component } from "react";
import URLSearchParams from "@ungap/url-search-params";
import cardType from "../i18n/lookups/cardType.js";
import uuidv4 from "uuid/v4";
import { getDeviceDetails } from "diva-web-sdk/dist/main.min.js";
import { isDivaAvailable } from "../shared/utils";
import getCardImage from "./helpers/getCardImage";

const AppContext = React.createContext();
const sessionId = uuidv4();
const { isDeviceSupported } = getDeviceDetails();

export class AppProvider extends Component {
  constructor(props) {
    super(props);
    let initialState = {
      sessionId: sessionId,
      queryString: "",
      sourceCode: "",
      sourceCodeValid: "",
      mccCode: "",
      mccCodeValid: "",
      paramsInvalid: "",
      paramsSet: "",
      cardType: "",
      marital_status: [],
      residential_status: [],
      occupation: [],
      BDV: 0,
      // DIVA values
      divaCompleted: false,
      divaSkipped: false,
      divaInScope: false
    };
    initialState = Object.assign(initialState, this.getStateFromURL());
    this.state = Object.assign(initialState, props);
  }

  getStateFromURL = () => {
    // Take the url params
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());

    const source = urlParams.get("source");
    const MCC = urlParams.get("mcc");

    // Check source and update state
    const sourceCodeValid = source && source.length === 4;

    // Check MCC and update State
    const ValidCodeRegex = new RegExp("^[1-3][0-9]{3}$");
    const regexValid = ValidCodeRegex.test(MCC);
    const paramsInvalid = !(regexValid && sourceCodeValid);

    // Get card type and update state
    const cardTypeID = regexValid && MCC.charAt(0) !== "" ? MCC.charAt(0) : "";
    const cardTypeName =
      regexValid && cardType[parseInt(cardTypeID) - 1] !== ""
        ? cardType[parseInt(cardTypeID) - 1]
        : "";

    // DIVA scope
    const divaInScope =
      regexValid &&
      isDeviceSupported &&
      isDivaAvailable() &&
      process.env.REACT_APP_FEATURE_DIVA_ON === "true" // [ORL-7102]: Prevent DIVA from activating for customers without removing from codebase

    return {
      queryString: window.location.search,
      sourceCode: source,
      mccCode: MCC,
      cardImage: getCardImage(MCC),
      paramsSet: true,
      sourceCodeValid: sourceCodeValid,
      mccCodeValid: regexValid,
      paramsInvalid: paramsInvalid,
      cardType: cardTypeID,
      cardTypeName: cardTypeName,
      divaInScope: divaInScope
    };
  };

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const AppConsumer = AppContext;
