import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ErrorAccept.css";
import {
  Button,
  Heading,
  Paragraph,
  HorizontalRule,
  Callout
} from "@piggybank/core";
import content from "../../i18n/content/errorAccept.js";
import GenericErrorPage from "../../pages/GenericErrorPage";
import { view } from "../../shared/analytics/tealium/events";
import {
  TRACK_CALLUS_ERROR,
  TRACK_CALLBACK_ERROR
} from "../../shared/analytics/pageData.js";

import { utagGlobal } from "../../shared/analytics/tealium";
import { ariaTextBloat } from "../../shared/helpers";
import { disableBackButton } from "../../shared/browser";

const eventTaggingConstantsCallUs = {
  page_url: TRACK_CALLUS_ERROR.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_CALLUS_ERROR.funnel_step_name
};

const eventTaggingConstantsCallback = {
  page_url: TRACK_CALLBACK_ERROR.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_CALLBACK_ERROR.funnel_step_name
};

class ErrorAccept extends Component {
  componentDidMount() {
    if (this.errorType === "callUs") {
      TRACK_CALLUS_ERROR.event_subcategory = this.props.errorCode;
      view(Object.assign(utagGlobal, TRACK_CALLUS_ERROR));
    } else {
      TRACK_CALLBACK_ERROR.event_subcategory = this.props.errorCode;
      view(Object.assign(utagGlobal, TRACK_CALLBACK_ERROR));
    }
    disableBackButton();
  }

  // These need to be stored outside the render
  // because if the user tries to navigate back in the browser
  // the component re-renders and wipes the values out
  // which causes errors and re-renders a white page
  errorCode = this.props.location.state.errorCode + "";
  errorType = this.props.location.state.errorState;
  eqaNumber = this.props.location.state.eqaNumber;

  render() {
    const props = this.props;
    return (
      <GenericErrorPage
        eventTaggingConstants={
          this.errorType === "callUs"
            ? eventTaggingConstantsCallUs
            : eventTaggingConstantsCallback
        }
        errorCode={this.errorCode}
        {...props}
      >
        <Paragraph lead data-bdd="page-intro">
          <b>{content.pageIntro}</b>{" "}
          <span data-bdd="eqa-number" className="aria-bloated-text">
            {ariaTextBloat(this.eqaNumber)}
          </span>
        </Paragraph>

        {this.errorType === "callYou" ? (
          <>
            <Paragraph data-bdd="paragraph-one" className="callYou">
              {content.weWillCallYou.paragraphOne}
            </Paragraph>
            <Paragraph data-bdd="paragraph-two">
              {content.weWillCallYou.paragraphTwo}
            </Paragraph>
            <HorizontalRule marginBottom={7} />
            <Heading level={2} data-bdd="page-sub-heading">
              {content.weWillCallYou.pageSubheading}
            </Heading>
            <Paragraph data-bdd="page-copy">
              {content.weWillCallYou.pageCopy}
            </Paragraph>
          </>
        ) : (
          ""
        )}

        {this.errorType === "callUs" ? (
          <>
            <Callout type="warning">
              <Paragraph data-bdd="call-us-paragraph-one">
                {content.callUs.warningBox.paragraphOne}
              </Paragraph>
              <Paragraph data-bdd="call-us-paragraph-two" marginBottom={0}>
                {content.callUs.warningBox.paragraphTwo}
              </Paragraph>
            </Callout>
          </>
        ) : (
          ""
        )}

        <Paragraph data-bdd="time-mon-fri">{content.timeMonFri}</Paragraph>
        <Paragraph data-bdd="time-sat">{content.timeSat}</Paragraph>
        <Paragraph data-bdd="time-sun">{content.timeSun}</Paragraph>

        <Button elementType="a" href={content.link} data-bdd="home-link">
          {content.linkText}
        </Button>
      </GenericErrorPage>
    );
  }
}

ErrorAccept.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      errorState: PropTypes.string.isRequired,
      eqaNumber: PropTypes.string.isRequired,
      errorCode: PropTypes.number.isRequired
    })
  })
};

export default ErrorAccept;
