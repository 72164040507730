export default {
  pageTitle: "Application complete",
  masthead: {
    heading: "Congratulations, your card is on its way"
  },
  pageHeading: "What happens next?",
  pageCopy1:
    "We’re sending out your M&S Credit Card and PIN, these will arrive separately for additional security. You’ll receive these in the next 5 working days. In the meantime please check your email for more information on your M&S Credit Card.",
  pageCopy2:
    "In your Welcome Pack, we’ll provide some details of how you can earn 500 M&S points (worth £5) straight away. It’s just our way of saying thank you for choosing to take the M&S Credit Card.",
  digitalBanking: {
    heading: "Digital banking - access your account safely and securely at your convenience",
    link: {
      text: "marksandspencer.com/digitalbanking",
      url: "https://marksandspencer.com/digitalbanking"
    },
    preLinkCopy: "When you have received your card, visit us at ",
    postLinkCopy: " and register to access our digital banking services. Once you’re all set up you’ll be able to view your statements, check your balance, transactions and more.",
    activationCopy: "You can also activate your card within the M&S Banking App* and get in touch using our 'Chat with us' feature.",
    deviceCompatibilityCopy: '*Available on compatible devices'
  },
  buttonLabel: "Go to M&S Club Rewards",
  pageLinkUrl: "https://bank.marksandspencer.com/explore/contact-us/"
};
