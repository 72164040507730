export default {
  pageTitle: "Let's verify your identity",
  introPara: "First, we need to check it's you.",
  idPara: {
    paraOne: "You'll need one of the following forms of",
    paraTwo: " ID:"
  },
  idItem1: "Photocard driving licence",
  idItem2: "Passport",
  idItem3: {
    paraOne: "National EEA / Swiss",
    paraTwo: " ID",
    paraThree: " card"
  },
  subTitle: "What you'll need to do:",
  wtdItem1: {
    paraOne: "Take a photo of your",
    paraTwo: " ID"
  },
  wtdItem2:
    "Record a short video or take a photo of yourself to prove it's you applying",
  closingPara:
    "By selecting 'Continue', you'll make it easier for us to verify your identity."
};
