import React from "react";
import { Grid, Row, Cell, Heading, IconConfirm } from "@piggybank/core";
import "./AcceptMasthead.css";

const AcceptMasthead = props => {
  const content = props.content;
  return (
    <div
      id="acceptMasthead"
      className="acceptMasthead"
      style={{ display: "block" }}
    >
      <Grid>
        <Row>
          <div className="acceptMasthead__inner">
            <Cell offsetLarge={1} size={10} sizeMedium={9} sizeLarge={6}>
              <div className="acceptMasthead__description">
                <div
                  style={{
                    color: "#4d8210",
                    margin: "3px 0px 3px -2px",
                    padding: "3px"
                  }}
                >
                  <IconConfirm width="36" data-bdd="confirm-icon" />
                </div>
                {content.heading && (
                  <Heading level={1} data-bdd="page-heading" marginBottom={2}>
                    {content.heading}
                  </Heading>
                )}
                {content.subHeading && (
                  <Heading
                    id="headingMasthead"
                    level={2}
                    data-bdd="page-mast-sub-heading"
                    marginBottom={0}
                  >
                    {content.subHeading}
                  </Heading>
                )}
              </div>
            </Cell>
          </div>
        </Row>
      </Grid>
    </div>
  );
};

export default AcceptMasthead;
