export default {
  pageIntro: "Sorry, there's been a technical problem downloading the PDF",
  subHeading: "What happens next?",
  paragraphOne:
    "If you are happy to continue without receiving a copy of the pre-contract information before signing the agreement.",
  acceptListItemOne: "Please close this window and complete your application.",
  acceptListItemTwo:
    "A copy of the full terms and conditions will be sent to you by email.",
  paragraphTwo:
    "If you want to receive a copy of the pre-contract information before signing the agreement, please do not continue the online application.",
  declineListItemOne: "Call us on 0800 997 996 to confirm the next steps.",
  declineListItemTwo:
    "Do not submit another application as this could affect your credit record.",
  pageSubHeading: "Opening hours",
  timeMonFri: "Mon-Fri: 8am-9pm",
  timeSat: "Sat: 8am-8pm",
  timeSun: "Sun: 10am-6pm"
};
