import React, { Component } from "react";
import {
  Section,
  Heading,
  Paragraph,
  UnorderedList,
  ListItem,
  HorizontalRule,
  Button
} from "@piggybank/core";
import { Navigation } from "@piggybank/form";
import content from "../../../i18n/content/divaIntro.js";
import { AppConsumer } from "../../../shared/appContext";
import "./DivaIntro.css";
import { link } from "../../../shared/analytics/tealium/events";
import { ariaTextBloat } from "../../../shared/helpers";

class DivaIntro extends Component {
  componentDidMount() {
    document.title = `${content.pageTitle}`;
  }
  render() {
    const { eventTaggingConstants } = this.props;
    return (
      <Section id="diva-intro-bio" data-bdd="diva-intro">
        <Heading tabindex={0} level={1} data-bdd="page-title">
          {content.pageTitle}
        </Heading>
        <Paragraph>{content.introPara}</Paragraph>
        <span id="idItem3" className="hidden">
          {content.idItem3.paraOne +
            ariaTextBloat(content.idItem3.paraTwo) +
            content.idItem3.paraThree}
        </span>
        <span id="wtdItem1" className="hidden">
          {content.wtdItem1.paraOne + ariaTextBloat(content.wtdItem1.paraTwo)}
        </span>
        {/* Temporary fix for using role="text" 
        without compiling errors. More info in the link bellow
        https://github.com/dequelabs/axe-core/issues/1597 */}
        {/*  eslint-disable-next-line  */}
        <Paragraph role="text">
          <span
            aria-label={
              content.idPara.paraOne + ariaTextBloat(content.idPara.paraTwo)
            }
          >
            <span>{content.idPara.paraOne + content.idPara.paraTwo}</span>
          </span>
        </Paragraph>
        <UnorderedList data-bdd="id-list">
          <ListItem>{content.idItem1}</ListItem>
          <ListItem>{content.idItem2}</ListItem>
          {/*  eslint-disable-next-line  */}
          <ListItem role="text" aria-labelledby="idItem3">
            {content.idItem3.paraOne +
              content.idItem3.paraTwo +
              content.idItem3.paraThree}
          </ListItem>
        </UnorderedList>
        <HorizontalRule />
        <Heading level={2} data-bdd="sub-title">
          {content.subTitle}
        </Heading>
        <UnorderedList data-bdd="wtd-list">
          {/*  eslint-disable-next-line  */}
          <ListItem role="text" aria-labelledby="wtdItem1">
            {content.wtdItem1.paraOne + content.wtdItem1.paraTwo}
          </ListItem>
          <ListItem>{content.wtdItem2}</ListItem>
        </UnorderedList>
        <Paragraph>{content.closingPara}</Paragraph>
        <Button
          data-bdd="cta-continue"
          type="submit"
          onClick={() => {
            this.props.callback();
            link({
              ...eventTaggingConstants,
              event_category: "content",
              event_action: "button",
              event_content: "continue"
            });
          }}
        >
          Continue
        </Button>
        <br />
        <Navigation
          data-bdd="cta-skip"
          textMap={{ previous: "Back", next: "Skip" }}
          id="skip"
          onNext={(next, rest) => {
            this.context.divaSkipped = true;
            next(rest);
          }}
          onClick={() => {
            link({
              ...eventTaggingConstants,
              event_category: "content",
              event_action: "button",
              event_content: "skip"
            });
          }}
        />
      </Section>
    );
  }
}

DivaIntro.displayName = "DivaIntro";
DivaIntro.contextType = AppConsumer;

export default DivaIntro;
