import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  withContext,
  Heading,
  IconChevronLeft,
  IconChevronRight,
  VisuallyHidden
} from "@piggybank/core";
import { Hint, WizardContext } from "@piggybank/form";
import {
  urlDetails,
  personalDetails,
  contactDetails,
  employmentDetails,
  financialDetails,
  setupPreferences,
  review
} from "./developmentValues";
import pageDetails from "./pageDetails";
import "./devStyles.css";
import history from "../shared/history";

let hasPopulated = false;

// Adds navigation to dev branches
const NavHelper = ({ wizardContext, handleSubmit }) => {
  const {
    onPrevious,
    onNext,
    navigateTo,
    isLastPage,
    currentPage
  } = wizardContext;

  const [menuOpen, setMenu] = useState("closed");

  useEffect(() => {
    var page = document.getElementById("root");
    page.classList.add("navtools");

    page.classList.toggle("open", menuOpen === "open");
  });

  return (
    <Fragment>
      <div
        id="nav-icon"
        className={menuOpen}
        onClick={() => setMenu(menuOpen === "open" ? "closed" : "open")}
      >
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
      <div
        id="toolContainer"
        className={`toolContainer toolContainer--${menuOpen}`}
      >
        <Hint name="navButtons">
          Development only, will not be available in Production
        </Hint>
        <div>
          <div className="directAccess">
            <div className="page-menu">
              <Heading level={3}>Pages</Heading>
              {/* Previous Next panel */}
              <div>
                <div className="button--previous">
                  <Button id="prevButton" onClick={() => onPrevious()}>
                    <IconChevronLeft inline />
                    Previous
                    <VisuallyHidden> page </VisuallyHidden>
                  </Button>
                </div>
                <div className="button--next">
                  <Button
                    id="nextButton"
                    disabled={isLastPage}
                    onClick={() => onNext()}
                  >
                    Next <VisuallyHidden> page </VisuallyHidden>
                    <IconChevronRight inline />
                  </Button>
                </div>
              </div>
              {pageDetails.map(function(page) {
                return (
                  <Button
                    key={`${page.index}-directaccess`}
                    onClick={() => {
                      navigateTo(page.index, page.loadData);
                    }}
                  >
                    {page.label}
                  </Button>
                );
              })}
              <Button
                id="errorButton"
                onClick={() => {
                  setMenu(menuOpen === "open" ? "closed" : "open");
                  setTimeout(
                    () =>
                      history.push("/error", {
                        redirection: true,
                        page: "error"
                      }),
                    250
                  );
                }}
              >
                Error
              </Button>
              <Button
                id="acceptButton"
                onClick={() => {
                  setMenu(menuOpen === "open" ? "closed" : "open");
                  history.push("/accept", {
                    redirection: true,
                    page: "accept"
                  });
                }}
              >
                Accept
              </Button>
              <Button
                id="button--timeout"
                onClick={() => {
                  setMenu(menuOpen === "open" ? "closed" : "open");
                  setTimeout(
                    () =>
                      history.push("/timeout", {
                        redirection: true,
                        page: "timeout"
                      }),
                    250
                  );
                }}
              >
                Timeout
              </Button>
              <Button
                id="button--refer"
                onClick={() => {
                  setMenu(menuOpen === "open" ? "closed" : "open");
                  setTimeout(
                    () =>
                      history.push("/refer", {
                        redirection: true,
                        page: "refer"
                      }),
                    250
                  );
                }}
              >
                Refer
              </Button>
              <Button
                id="button--complete"
                onClick={() => {
                  setMenu(menuOpen === "open" ? "closed" : "open");
                  setTimeout(
                    () =>
                      history.push("/complete", {
                        redirection: true,
                        page: "applicationComplete"
                      }),
                    250
                  );
                }}
              >
                Application Complete
              </Button>
              <Button
                id="declineButton"
                onClick={() => {
                  setMenu(menuOpen === "open" ? "closed" : "open");
                  setTimeout(
                    () =>
                      history.push("/unsuccessful", {
                        redirection: true,
                        page: "decline"
                      }),
                    250
                  );
                }}
              >
                Decline
              </Button>
              <Button
                id="button--submit"
                onClick={() => {
                  handleSubmit({
                    ...personalDetails,
                    ...contactDetails,
                    ...employmentDetails,
                    ...financialDetails,
                    ...setupPreferences,
                    ...review
                  });
                }}
              >
                Submit
              </Button>
            </div>
          </div>

          {/* Debug and Populate */}
          <div>
            <Heading level={3}>Debug</Heading>
            <span className="vert-debug">
              <label>
                <input
                  name="debug-mode"
                  type="checkbox"
                  onChange={() => {
                    var page = document.getElementById("root");
                    if (page.classList.contains("debug")) {
                      page.classList.remove("debug");
                    } else {
                      page.classList.add("debug");
                    }
                  }}
                />
                show margins
              </label>
            </span>
            {!hasPopulated && (
              <Button
                id="populateButton"
                onClick={() => {
                  navigateTo(!isLastPage ? currentPage + 1 : 0, {
                    ...urlDetails,
                    ...personalDetails,
                    ...contactDetails,
                    ...employmentDetails,
                    ...financialDetails,
                    ...setupPreferences,
                    ...review
                  });
                  hasPopulated = true;
                }}
              >
                Populate
              </Button>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// grab wizard context and pass it to component
export default withContext(WizardContext, wizardContext => ({ wizardContext }))(
  NavHelper
);
