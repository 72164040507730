export default {
  pageTitle: "Review credit card offer",
  masthead: {
    heading: "Good news, we can offer you this credit card",
    subHeading: "Just a few more steps to complete your application"
  },
  offerSubHeading: "Review credit card offer",
  offerType: "offer",
  shoppingOffer: {
    label: "Shopping offer",
    copy1: "% for ",
    copy2: " months"
  },
  creditLimit: {
    label: "Credit limit*",
    copy: "£",
    asterisk: value => value ? "*This is the credit limit you can expect - we'll confirm your limit once we've opened your account" : ""
  },
  balanceTransfer: {
    label: "Balance Transfer Offer",
    copy1: "% for ",
    copy2: " months, available for ",
    copy3: " days from account opening (",
    copy4: "% fee applies, minimum £",
    copy5: ")"
  },
  apr: {
    label: "Annual Percentage Rate (APR)",
    copy: "% APR"
  },
  pageSubHeading: "Review and sign your documents",
  pageCopy1:
    "Select continue to review and sign the following credit card documents",
  pageCopy1ListItems: [
    "Pre-contract credit information",
    "Key points",
    "Credit card agreement and Additional terms"
  ],
  buttonLabel: "Continue"
};
