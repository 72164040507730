import React from "react";
import {
  Paragraph,
  UnorderedList,
  ListItem,
  Heading,
  HorizontalRule
} from "@piggybank/core";
import content from "../../i18n/content/errorPdf.js";
import GenericErrorPage from "../../pages/GenericErrorPage";

const ErrorPdf = () => {
  return (
    <GenericErrorPage>
      <Paragraph lead data-bdd="page-intro">
        {content.pageIntro}
      </Paragraph>

      <HorizontalRule marginBottom={7} />
      <Heading level={2} data-bdd="sub-heading">
        {content.subHeading}
      </Heading>
      <Paragraph>{content.paragraphOne}</Paragraph>
      <UnorderedList data-bdd="accept-list">
        <ListItem>{content.acceptListItemOne}</ListItem>
        <ListItem>{content.acceptListItemTwo}</ListItem>
      </UnorderedList>
      <Paragraph>{content.paragraphTwo}</Paragraph>
      <UnorderedList data-bdd="decline-list">
        <ListItem>{content.declineListItemOne}</ListItem>
        <ListItem>{content.declineListItemTwo}</ListItem>
      </UnorderedList>
      <Heading level={3} data-bdd="page-sub-heading">
        {content.pageSubHeading}
      </Heading>
      <Paragraph data-bdd="time-mon-fri">{content.timeMonFri}</Paragraph>
      <Paragraph data-bdd="time-sat">{content.timeSat}</Paragraph>
      <Paragraph data-bdd="time-sun">{content.timeSun}</Paragraph>
    </GenericErrorPage>
  );
};

export default ErrorPdf;
