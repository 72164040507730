import React, { Component } from "react";
import { Row, Cell } from "@piggybank/core";
import {
  Field,
  Label,
  Select,
  FieldFeedback,
  DateInput,
  Legend,
  Fieldset,
  TextInput,
  Hint
} from "@piggybank/form";
import occupation from "../../i18n/lookups/occupation";
import "./Employed.css";

class Employed extends Component {
  render() {
    const { values, content } = this.props;

    return (
      <>
        <Field name="occupation" data-bdd="occupation">
          <Label>{content.occupation.label}</Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <Select
                options={occupation}
                placeholder="Please select"
                fullWidth
              />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>

        <Field
          name="company-name"
          data-bdd="company-name"
          onBlur={(next, rest) => {
            if (values["company-name"] != null) {
              values["company-name"] = values["company-name"].trim();
            }
            next(rest);
          }}
        >
          <Label>{content.companyName.label}</Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <TextInput fullWidth maxLength={25} />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>

        <Fieldset name="employment-start-date" data-bdd="employment-start-date">
          <Legend marginBottom={1}>{content.employmentStartDate.label}</Legend>
          <Hint marginBottom={1}>{content.employmentStartDate.hint}</Hint>
          <DateInput format="YYYY-MM" />
          <FieldFeedback />
        </Fieldset>

      </>
    );
  }
}

export default Employed;
