import React, { Component } from "react";
import ReactDOM from "react-dom";
import Loading from "./components/Loading";
import { Wizard, WizardPage } from "@piggybank/form";
import { view } from "./shared/analytics/tealium/events";
import { utagGlobal } from "./shared/analytics/tealium";
import { TRACK_PROCESSING_PAGE } from "./shared/analytics/pageData";

/* eslint-env browser */
import GenericPage from "./pages/GenericPage";
import PersonalDetails from "./templates/PersonalDetails";
import ContactDetails from "./templates/ContactDetails";
import EmploymentDetails from "./templates/EmploymentDetails";
import FinancialDetails from "./templates/FinancialDetails";
import SetUpPreferences from "./templates/SetUpPreferences";
import ApplicationComplete from "./templates/ApplicationComplete";
import AcceptComplete from "../src/i18n/AcceptComplete";
import Review from "./templates/Review";
import Error from "./templates/Error";
import CardMastHead from "./organisms/CardMasthead";
import cardMastHeadContent from "./i18n/content/cardMasthead.js";

import ApplicationTimeout from "./templates/ApplicationTimeout";
import Refer from "./templates/Refer";
import Decline from "./templates/Decline";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import history from "./shared/history";
import { NavHelper } from "./devTools";
import { AppConsumer } from "./shared/appContext";
import * as submitApplication from "./shared/submitApplication";
import { handleBeforeunloadEvent } from "./shared/browser";
import AcceptJourney from "./templates/AcceptJourney";
import ErrorAccept from "./templates/ErrorAccept/ErrorAccept";
import ErrorPdf from "./templates/ErrorPdf/ErrorPdf";

import DivaApp from "./organisms/Diva";
import handleBrowserHistoryWithDiva from "./organisms/Diva/divaRouting";

// Create a wrapper for the <App /> passing in context as props

if (process.env.NODE_ENV !== "production") {
  var axe = require("react-axe");
  axe(React, ReactDOM, 1000);
}
const AppContextWrapper = () => (
  <AppConsumer.Consumer>
    {context => <App context={context} />}
  </AppConsumer.Consumer>
);

export class App extends Component {
  state = {
    loading: false,
    timerFinished: false,
    responded: false,
    devMode: false,
    submitResponse: ""
  };

  setCloseWindowNotification = () => {
    const excludePages = [
      "/error",
      "/refer",
      "/timeout",
      "/error-accept",
      "/unsuccessful",
      "/acceptComplete"
    ];

    const shouldNotify = !excludePages.includes(window.location.pathname);

    if (shouldNotify && process.env.NODE_ENV !== "development") {
      window.addEventListener("beforeunload", handleBeforeunloadEvent, false);
    } else {
      window.removeEventListener(
        "beforeunload",
        handleBeforeunloadEvent,
        false
      );
    }
  };

  componentDidMount() {
    handleBrowserHistoryWithDiva(this.context);

    process.env.REACT_APP_DEV_MODE === "true"
      ? this.setState({
          devMode: true
        })
      : this.setState({
          devMode: false
        });
  }

  componentDidUpdate() {
    if (this.state.responded && this.state.timerFinished) {
      this.setState({ loading: !this.state.loading });
      this.setState({ responded: !this.state.responded });
      this.setState({ timerFinished: !this.state.timerFinished });
    }
    this.setCloseWindowNotification();
  }

  requiresCardMastHead() {
    const pages = [
      "/error",
      "/complete",
      "/timeout",
      "/unsuccessful",
      "/refer",
      "/accept",
      "/acceptComplete",
      "/error-accept"
    ];
    return !pages.includes(window.location.pathname);
  }
  handleSubmit = async values => {
    view(Object.assign(utagGlobal, TRACK_PROCESSING_PAGE));

    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ timerFinished: true });
    }, 2000);

    const response = await submitApplication.submit(values, this.props.context);
    this.xState = response.xState;

    this.setState({
      responded: true,
      submitResponse: response.result.status.toString()
    });

    if (response.success) {
      const decision = this.handleDecision(response.result, history);
      history.push(decision.url, decision.options);
    } else {
      response.referPageType && (this.referPageType = response.referPageType); // if Global Check Error
      history.push(response.history.url, response.history.options);
    }
  };
  requiresAcceptMasthead() {
    const pages = ["/accept", "/acceptComplete", "/error-accept"];
    return pages.includes(window.location.pathname);
  }

  extractAcceptFields = values => {
    const acceptFields = {
      indicativeCreditLimit:
        values.indicativeCreditLimit !== undefined
          ? values.indicativeCreditLimit.amount
          : undefined,
      initialBtApr: values.initial.btApr,
      initialBtEligiblePeriod: values.initial.btEligiblePeriod,
      initialBtFee: values.initial.btFee,
      initialBtMinFee:
        values.initial.btMinFee !== undefined
          ? values.initial.btMinFee.amount
          : undefined,
      initialBtTerm: values.initial.btTerm,
      initialRetailApr: values.initial.retailApr,
      initialRetailTerm: values.initial.retailTerm,
      standardRetailApr: values.standard.retailApr
    };
    return acceptFields;
  };

  handleDecision = response => {
    if (response.data.eqaNumber) {
      this.wip = response.data.eqaNumber.toString();
    } else {
      this.wip = response.data.code.toString();
    }

    const decisionArray =
      response.data.decisions && response.data.decisions.decision
        ? response.data.decisions.decision
        : [];
    const decisionCode = decisionArray.length > 0 ? decisionArray[0].code : "";
    const decisionReasonCode =
      decisionArray.length > 0 ? decisionArray[0].reasonCode : "";
    const cardValues =
      response.data.card &&
      response.data.card.indicativeCreditLimit &&
      response.data.card.initial &&
      response.data.card.standard
        ? response.data.card
        : "";
    const showAgreement = response.data.showAgreement;
    let url;
    let pageName;

    switch (true) {
      case (typeof response.data.exceptionCode !== "undefined" &&
        response.data.exceptionCode.startsWith("X")) ||
        (typeof response.data.code !== "undefined" &&
          response.data.code.startsWith("ERR")):
        this.referPageType = "exception";
        url = "/complete";
        pageName = "referException";
        break;

      case decisionCode === "A" && showAgreement === false:
        this.referPageType = "exception";
        url = "/complete";
        pageName = "referException";
        break;

      case decisionCode === "A" && showAgreement === true:
        if (cardValues !== "") {
          this.acceptFields = this.extractAcceptFields(cardValues);
        }
        url = "/accept";
        pageName = "accept";
        break;

      case decisionCode === "D":
        url = "/unsuccessful";
        pageName = "decline";
        break;

      case decisionArray.length === 1 &&
        decisionCode === "R" &&
        decisionReasonCode === "03C" &&
        this.props.context.divaCompleted === false:
        url = "/refer";
        pageName = "referIdv";
        break;

      case decisionCode === "R":
        this.referPageType = "general";
        url = "/complete";
        pageName = "refer";
        break;

      default:
        url = "/error";
        pageName = "error";
        break;
    }

    return {
      url,
      options: {
        redirection: true,
        page: pageName
      }
    };
  };

  checkErrorPage = () => {
    const str = window.location.hash;
    return str.split("-")[1];
  };

  render() {
    const context = this.props.context;
    document.head.querySelector("meta[name='bcsid']").content =
      context.sessionId;
    document.head.querySelector("meta[name='session_id']").content =
      context.sessionId;

    if (context.paramsInvalid === true) {
      return (
        <GenericPage requiresMastHead={false}>
          <Route
            render={props => {
              if (this.checkErrorPage() === "pdf") {
                return <ErrorPdf />;
              } else {
                return (
                  <Error
                    paramsInvalid={context.paramsInvalid}
                    errorCode={this.state.submitResponse}
                  />
                );
              }
            }}
          />
        </GenericPage>
      );
    } else {
      return (
        <GenericPage
          requiresMastHead={
            this.requiresCardMastHead() || this.requiresAcceptMasthead()
          }
        >
          {this.requiresCardMastHead() && (
            <CardMastHead
              cardTypeName={context.cardTypeName}
              content={cardMastHeadContent}
              cardImage={context.cardImage}
            />
          )}
          <Switch>
            <Route exact path="/">
              <Wizard history={history} onSubmit={this.handleSubmit}>
                {this.state.devMode && (
                  <NavHelper handleSubmit={this.handleSubmit} />
                )}

                <WizardPage>
                  <PersonalDetails cardType={context.cardTypeName} />
                </WizardPage>
                {this.context.divaInScope && (
                  <WizardPage>
                    {({ values }) => <DivaApp values={values} />}
                  </WizardPage>
                )}
                <WizardPage component={ContactDetails} />
                <WizardPage component={EmploymentDetails} />
                <WizardPage component={FinancialDetails} />
                <WizardPage component={SetUpPreferences} />
                <WizardPage component={Review} />
              </Wizard>
            </Route>
            <Route path="/timeout" component={ApplicationTimeout} />
            <Route
              path="/accept"
              render={props => (
                <AcceptJourney
                  cardType={context.cardTypeName}
                  cardImage={context.cardImage}
                  acceptFields={this.acceptFields}
                  applicationId={this.wip}
                  xState={this.xState}
                />
              )}
            />
            <Route
              path="/complete"
              render={props => (
                <ApplicationComplete
                  applicationId={this.wip}
                  pageType={this.referPageType}
                />
              )}
            />
            <Route
              path="/unsuccessful"
              render={props => <Decline applicationId={this.wip} />}
            />
            <Route
              path="/refer"
              render={props => <Refer applicationId={this.wip} />}
            />
            <Route
              path="/error"
              render={props => (
                <Error
                  paramsInvalid={context.paramsInvalid}
                  errorCode={this.state.submitResponse}
                />
              )}
            />
            <Route
              path="/acceptComplete"
              render={props => (
                <AcceptComplete cardImage={context.cardImage} applicationId={this.wip} {...props} />
              )}
            />
            <Route
              path="/error-accept"
              render={props => <ErrorAccept {...props} />}
            />
          </Switch>
          <Loading show={this.state.loading} />
        </GenericPage>
      );
    }
  }
}

App.displayName = "APP";
App.contextType = AppConsumer;
export default withRouter(AppContextWrapper);
