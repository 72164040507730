import greenCard from "../../shared/images/greenCardImage.svg";

const mccCardImageConfig = {
  1: greenCard, // MCC 1XXX
  2: greenCard, // MCC 2XXX
  3: greenCard, // MCC 3XXX
};

export default mcc => mcc
  ? mccCardImageConfig[mcc.slice(0, 1)]
  : undefined;
