import { sanitiseField } from "./utils";
import {
  address,
  trimmedCountryName,
  yesNo,
  courseEndDate,
  timeAt,
  repaymentMethods
} from "./transformations";
import {
  previousNames,
  additionalNationalities,
  previousAddresses,
  financialDetails,
  gender,
  isEmployed,
} from "./helpers";

export const productDecisionRequest = values => {
  return {
    productType: "510",
    sourceCode: values.sourceCode.toUpperCase(),
    mccCode: values.mcc,
    personalDetails: {
      name: {
        title: values.title.toUpperCase(),
        forename: sanitiseField(values.forename),
        middleName: values.middlenames
          ? sanitiseField(values.middlenames)
          : undefined,
        surname: sanitiseField(values.surname)
      },
      previousNameExists: yesNo(values["more-names"]),
      previousNames: previousNames(values.previousNames),
      dateOfBirth: values["date-of-birth"],
      gender: gender(values.title),
      mothersMaidenName: sanitiseField(values["maiden-name"]),
      maritalStatus: values["marital-status"],
      numberOfChildren: values["number-of-dependants"],
      birthCountryCode: values["country-of-birth"],
      birthCountry: trimmedCountryName(values["country-of-birth"]),
      residenceCountryCode: values["country-of-residence"],
      residenceCountry: trimmedCountryName(values["country-of-residence"]),
      nationalityCode: values.nationality,
      nationality: trimmedCountryName(values.nationality),
      additionalNationalities: additionalNationalities(
        values["additional-nationalities"]
      )
    },
    contactDetails: {
      emailAddress: sanitiseField(values.email),
      mobilePhone: sanitiseField(values["mobile-number"].value.replace("+44", "0")),
      homeTelephoneNumber: values["home-number"]
        ? sanitiseField(values["home-number"].value.replace("+44", "0"))
        : undefined,
      workTelephoneNumber: values["work-number"]
        ? sanitiseField(values["work-number"].value.replace("+44", "0"))
        : undefined,
      currentAddress: address(values),
      residentialStatus: values["residential-status"],
      previousAddresses: previousAddresses(
        values.previousAddresses,
        values["date-moved"]
      )
    },
    employmentDetails: {
      employmentStatus: values["employment-status"],
      occupation: values.occupation,
      employerName: sanitiseField(values["company-name"]),
      timeWithEmployer: values["employment-start-date"]
        ? timeAt(values["employment-start-date"])
        : undefined,
      unemploymentBenefits: yesNo(values["unemployment-benefit"]),
      atHomeBenefits: yesNo(values["income-from-benefits"]),
      courseEndDay: courseEndDate(values["course-end-date"], "day"),
      courseEndMonth: courseEndDate(values["course-end-date"], "month"),
      courseEndYear: courseEndDate(values["course-end-date"], "year"),
      employerAddressLine1: isEmployed(values["employment-status"]) ? "UNITED KINGDOM" : undefined,
      employerAddressLine2: isEmployed(values["employment-status"]) ? " " : undefined,
    },
    financialDetails: financialDetails(values),
    preferences: {
      methodOfRepayment:
        values.repaymentMethod === "" || values["repayment-account"] === "no"
          ? "Bank Giro Credit"
          : repaymentMethods(values.repaymentMethod),
      methodOfRepaymentCode:
        values.repaymentMethod === "" || values["repayment-account"] === "no"
          ? "G"
          : values.repaymentMethod,
      accountHoldersName: sanitiseField(values["account-holders-name"]),
      bankSortCode: sanitiseField(values.sortCode) ? values.sortCode : "",
      bankAccountNumber: sanitiseField(values.accountNumber)
        ? values.accountNumber
        : "",
      bankDetailsCorrect: values.bankDetailsCorrect,
      bankName: values.bankName,
      uCLIOptions: values["credit-limit"],
      marketingPreferences: yesNo(values["marketing-preferences"])
    }
  };
};
