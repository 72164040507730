import React, { Component } from "react";
import { Fieldset, FieldFeedback, Legend, YesNoRadio } from "@piggybank/form";

class RadioButton extends Component {
  render() {
    const content = this.props.content;
    const name = this.props.name;
    return (
      <>
        <Fieldset name={name} id={name} marginBottom={3}>
          <Legend data-bdd={name + "-label"} marginBottom={1}>
            {content.label}
          </Legend>
          <YesNoRadio data-bdd={name + "-radio"} />
          <FieldFeedback marginTop={1} />
        </Fieldset>
      </>
    );
  }
}

export default RadioButton;
