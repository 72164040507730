import { titles } from "../../i18n/lookups/titles";
import key2Label from "../transformations/key2Label";

export default values => {
  return (
    key2Label(values.title, titles) +
    " " +
    values.forename +
    " " +
    isNotBlank(values.middlenames) +
    values.surname
  );
};

const isNotBlank = name => {
  return name !== undefined ? name + " " : "";
};
