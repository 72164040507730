/**
 * Extends the browser window object
 * to add a new .onpushstate listener
 * that fires every time a new item is .push() into the history
 * @param history - the window history object
 */

const bindHistoryOnPushState = history => {
  var pushState = history.pushState;

  // This cannot be an arrow function
  // because arrow functions don't have the local variable arguments
  history.pushState = function(state) {
    if (typeof history.onpushstate == "function") {
      history.onpushstate({ state: state });
    }
    return pushState.apply(history, arguments);
  };
};

/**
 * Starts tracking the browser history
 * so that it can handle DIVA page items in the history
 * when they cannot be accessed (once DIVA has been skipped or completed)
 * @param context - the app context object used to check if diva has been completed/skipped
 */

const handleBrowserHistoryWithDiva = context => {
  let divaPagesCount = 0;

  /**
   * Add a onpushstate listener to the window object
   * so that we can track every time a new item is pushed to the window history
   * in order to have the ability to see when a DIVA releted item is added
   */

  bindHistoryOnPushState(window.history);

  /**
   * When a new item is added to the browser history using .push()
   * if the new item is a DIVA one, increment the counter
   * which tracks how many DIVA specific steps have been added to the history
   */

  window.history.onpushstate = event => {
    if (event.state && event.state.state && event.state.state.step >= 0) {
      divaPagesCount = divaPagesCount + 1;
    }
  };

  /**
   * When using the browser back button
   * if the previous step we are going to is a DIVa one { state { step: # } }
   * and DIVA has been completed
   * and there have been DIVA pages pushed into history (i.e. it hasn't been skipped)
   * force the browser to skip all those pages and move to the one before
   */

  window.onpopstate = event => {
    if (
      event.state &&
      event.state.state &&
      event.state.state.step &&
      context.divaCompleted &&
      divaPagesCount !== 0
    ) {
      window.history.go(-divaPagesCount);

      // Once skip the DIVA steps, reset the counter
      // becuase the history will updated with new steps from hereon
      divaPagesCount = 0;
    }
  };
};

export default handleBrowserHistoryWithDiva;
