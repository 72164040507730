import React, { Component } from "react";
import {
  Section,
  Heading,
  Paragraph,
  Button,
  IconStatusInformationOnlight,
  Link,
  FormLayout
} from "@piggybank/core";
import content from "../../i18n/content/applicationComplete.js";
import { TRACK_APPLICATION_COMPLETE } from "../../shared/analytics/pageData.js";
import { view } from "../../shared/analytics/tealium/events";
import { utagGlobal } from "../../shared/analytics/tealium";
import formatTitle from "../../shared/transformations/formatTitle";

class ApplicationComplete extends Component {
  componentDidMount() {
    TRACK_APPLICATION_COMPLETE.application_id = this.props.applicationId;
    TRACK_APPLICATION_COMPLETE.funnel_step_name = `refer - ${this.props.pageType}`;
    TRACK_APPLICATION_COMPLETE.page_name = `pws:application:credit cards:refer:${this.props.pageType}`;
    TRACK_APPLICATION_COMPLETE.page_url = `/application/credit-cards/refer/${this.props.pageType}`;
    view(Object.assign(utagGlobal, TRACK_APPLICATION_COMPLETE));
    document.title = formatTitle(content.pageTitle);
  }

  handleRedirect() {
    window.location = "https://bank.marksandspencer.com/";
  }

  render() {
    return (
      <>
        <FormLayout>
          <Section data-bdd="complete">
            <div style={{ margin: "3px 0px 3px -2px", padding: "3px" }}>
              <IconStatusInformationOnlight
                width="36"
                data-bdd="confirm-icon"
              />
            </div>

            <Heading level={1} data-bdd="page-title" marginBottom={5}>
              {content.pageTitle}
            </Heading>
            {this.props.pageType === "general" && (
              <Paragraph data-bdd="general-page-copy-1" marginBottom={3}>
                {content.generalPageCopy1}
              </Paragraph>
            )}
            {this.props.pageType === "exception" && (
              <Paragraph data-bdd="exception-page-copy-1" marginBottom={3}>
                {content.exceptionPageCopy1}
              </Paragraph>
            )}
            <Paragraph data-bdd="page-copy-2" marginBottom={6}>
              {content.pageCopy2}
              <Link
                external
                href={content.pageLinkUrl}
                data-bdd="contact-us-link"
              >
                {content.pageLink}
              </Link>
            </Paragraph>
            <Button
              data-bdd="home-page-button"
              type="button"
              onClick={e => {
                e.stopPropagation();
                this.handleRedirect();
              }}
            >
              {content.buttonLabel}
            </Button>
          </Section>
        </FormLayout>
      </>
    );
  }
}

export default ApplicationComplete;
