import creditLimitIncrease from "../../i18n/lookups/creditLimitIncrease";

export default code => {
  var status;
  for (var i = creditLimitIncrease.length; i--; ) {
    if (creditLimitIncrease[i].value === code) {
      status = creditLimitIncrease[i].label;
      break;
    }
  }
  return status;
};
