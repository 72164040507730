export default [

  {
    value: "E",
    label: "Minimum amount",
    hint:
      "If you only make the minimum payment each month, it will take longer and cost you more to clear your balance.",
    tealiumLabel: "minimum"
  },
  {
    value: "F",
    label: "Full amount",
    hint: "Pay the entire balance of your bill each month.",
    tealiumLabel: "full"
  }
];
