import React, { Component } from "react";
import {
  Heading,
  Section,
  Callout,
  UnorderedList,
  OrderedList,
  ListItem,
  Link,
  Paragraph,
  FormLayout
} from "@piggybank/core";
import { Form, FormFeedback } from "@piggybank/form";
import reactStringReplace from "react-string-replace";
import CardMastHead from "../../organisms/CardMasthead";
import DocumentLink from "../../components/DocumentLink";
import legalContent from "../content/acceptLegal.js";
import AcceptFooter from "../../organisms/AcceptFooter";
import { ariaTextBloat } from "../../shared/helpers";
import { removeTrailingZero } from "../../shared/utils";
import { acceptSchema } from "../../shared/validation-schema";
import "./AcceptLegal.css";
import { view } from "../../shared/analytics/tealium/events";
import { utagGlobal } from "../../shared/analytics/tealium";
import { TRACK_IMPORTANT_INFORMATION_PAGE } from "../../shared/analytics/pageData.js";
import Timer from "../../components/Timer";
import { isIE, isiOS } from "../../shared/browser";

const eventTaggingConstants = {
  page_url: TRACK_IMPORTANT_INFORMATION_PAGE.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_IMPORTANT_INFORMATION_PAGE.funnel_step_name
};

const initialValues = {
  agree: false
};

class AcceptLegal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitVisible: false,
      submitStickyIE: true
    };
  }

  handleScroll = e => {
    const stickyFooter = document.getElementById("show-footer");
    if (
      window.pageYOffset > stickyFooter.offsetTop + stickyFooter.offsetHeight &&
      this.state.submitVisible === false
    ) {
      this.setState({ submitVisible: true });
    }

    // Unstick the submition sticky footer on IE (ONLY)
    // when the browser scroll reaches the bottom of the page
    // because otherwise the position fixed overlaps
    // the actual page footer and it is not visible
    const footer = document.getElementsByTagName("footer")[0];
    if (
      isIE &&
      window.pageYOffset >
        footer.offsetTop -
          footer.offsetHeight -
          document.documentElement.clientHeight
    ) {
      this.setState({
        submitStickyIE: false
      });
    }
  };

  componentDidMount() {
    TRACK_IMPORTANT_INFORMATION_PAGE.application_id = this.props.applicationId;
    view(Object.assign(utagGlobal, TRACK_IMPORTANT_INFORMATION_PAGE));
    document.title = `${legalContent.pageTitle}`;
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  renderList = (list, type) =>
    list.map((item, index) => {
      if (Array.isArray(item)) {
        return (
          <UnorderedList className="child-list" key={index + item}>
            {this.renderList(item)}
          </UnorderedList>
        );
      } else {
        return <ListItem key={index + item}>{item}</ListItem>;
      }
    });

  renderOrderedList = list =>
    list.map((item, index) => {
      return <ListItem key={index + item}>{item}</ListItem>;
    });

  renderBackToTopLink = () => (
    <Link href="#back-to-top-link">{legalContent.backToTop}</Link>
  );

  linkInText = (text, match, link, linkText) => {
    let fullLink;
    let externalLink;

    switch (true) {
      // Email links
      case link.includes("@"):
        fullLink = "mailto:" + link;
        externalLink = false;
        break;
      // wwww. links convert to https:// external
      case !link.includes("http") && link.charAt(0) !== "#":
        fullLink = "https://" + link;
        externalLink = true;
        break;
      // Internal links
      case link.charAt(0) === "#":
        fullLink = link;
        externalLink = false;
        break;
      // Default, correct https:// link passed
      default:
        fullLink = link;
        externalLink = true;
        break;
    }

    return reactStringReplace(text, match, () => (
      <Link external={externalLink} href={fullLink} key={fullLink}>
        {linkText ? linkText : link}
      </Link>
    ));
  };

  htmlInText = (text, match, replacement) => {
    return reactStringReplace(text, match, () => replacement);
  };

  iterate = obj => {
    Object.keys(obj).forEach(key => {
      // It's a child object or an array
      // so keep drilling
      if (typeof obj[key] === "object") {
        obj[key] = this.iterate(obj[key]);

        // It's a string, so replace any variables
      } else {
        obj[key] = this.replacer(obj[key]);
      }
    });
    return obj;
  };

  replacer = str => {
    const { agreementInfo } = this.props;
    const data = [
      {
        key: "<OFFER APR BT>",
        value: removeTrailingZero(
          agreementInfo.card.initialConditions.balanceTransferAPR
        )
      },
      {
        key: "<OFFER BT TERM>",
        value: agreementInfo.card.initialConditions.balanceTransferTerm
      },
      {
        key: "<OFFER BT FEE>",
        value: agreementInfo.card.initialConditions.balanceTransferFee
      },
      {
        key: "<OFFER BT MINIMUM FEE>",
        value: agreementInfo.card.initialConditions.balanceTransferMinFee
      },
      {
        key: "<STANDARD APR BT>",
        value: removeTrailingZero(
          agreementInfo.card.standardConditions.balanceTransferAPR
        )
      },
      {
        key: "<STANDARD APR CASH>",
        value: removeTrailingZero(agreementInfo.card.standardConditions.cashAPR)
      },
      {
        key: "<BT OFFER ELIGIBILITY PERIOD>",
        value:
          agreementInfo.card.initialConditions.balanceTransferEligiblePeriod
      },
      {
        key: "<STANDARD APR RETAIL>",
        value: removeTrailingZero(
          agreementInfo.card.standardConditions.retailAPR
        )
      },
      { key: "<TOTAL AMOUNT>", value: agreementInfo.card.totalAmount },
      {
        key: "<OFFER APR RETAIL>",
        value: removeTrailingZero(
          agreementInfo.card.initialConditions.retailAPR
        )
      },
      {
        key: "<OFFER RETAIL TERM>",
        value: agreementInfo.card.initialConditions.retailTerm
      },
      { key: "<CASH ADVANCE FEE>", value: agreementInfo.card.cash.advanceFee },
      {
        key: "<CASH ADVANCE MINIMUM FEE>",
        value: agreementInfo.card.cash.advanceMinimumFee
      },
      {
        key: "<CASH ADVANCE FEE NON STERLING>",
        value: agreementInfo.card.cash.advanceFeeNonSterling
      },
      {
        key: "<CASH ADVANCE MINIMUM FEE NON STERLING>",
        value: agreementInfo.card.cash.advanceMinimumFeeNonSterling
      },
      { key: "<OVERLIMIT FEE>", value: agreementInfo.card.overLimitFee },
      {
        key: "<UNPAID PAYMENT FEE>",
        value: agreementInfo.card.unpaidPaymentFee
      },
      { key: "<LATE PAYMENT FEE>", value: agreementInfo.card.latePaymentFee },
      {
        key: "<CREDIT CARD NUMBER>",
        value: agreementInfo.card.creditCardNumber
      },
      { key: "<CUSTOMER NAME>", value: agreementInfo.customerName },
      { key: "<CURRENT ADDRESS>", value: agreementInfo.currentAddress },
      {
        key: "<PURCHASES EFFECTIVE APR>",
        value: removeTrailingZero(agreementInfo.card.purchaseEffectiveAPR)
      },
      {
        key: "<STANDARD RETAIL FEE>",
        value: agreementInfo.card.standardConditions.retailFee
      },
      {
        key: "<CASH EFFECTIVE APR>",
        value: removeTrailingZero(agreementInfo.card.cash.effectiveAPR)
      },
      {
        key: "<APR>",
        value: removeTrailingZero(agreementInfo.card.annualPercentageRate)
      }
    ];

    data.forEach(variable => {
      str = str.replace(variable.key, variable.value);
    });

    return str;
  };

  populatedLegalContent = this.iterate(legalContent);

  render() {
    const content = this.populatedLegalContent;
    return (
      <>
        <CardMastHead
          cardTypeName={this.props.cardType}
          content={content.masthead}
          cardImage={this.props.cardImage}
        />
        <FormLayout>
          <Form initialValues={initialValues} validationSchema={acceptSchema}>
            {({ values }) => (
              <>
                <FormFeedback />
                <span id="back-to-top-link"></span>

                <Section marginBottom={7}>
                  <Heading
                    level={1}
                    data-bdd="offer-sub-heading"
                    marginBottom={4}
                  >
                    {content.pageHeading}
                  </Heading>
                  <Callout type="warning">
                    <Heading level={4} marginBottom={3}>
                      {content.warningBox.title}
                    </Heading>
                    <UnorderedList>
                      <ListItem>
                        <Link href="#pre-contract">
                          {content.warningBox.sectionOneLinkText}
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#key-points">
                          {content.warningBox.sectionTwoLinkText}
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#card-agreement">
                          {content.warningBox.sectionThreeLinkText}
                        </Link>
                      </ListItem>
                    </UnorderedList>
                  </Callout>
                  <DocumentLink pdf={this.props.pdf} />
                </Section>

                <Section id="pre-contract" marginBottom={7}>
                  <Heading level={2} marginBottom={4} id="show-footer">
                    {content.preContract.title}
                  </Heading>
                  <Paragraph>{this.renderBackToTopLink()}</Paragraph>
                  <OrderedList>
                    <ListItem>
                      <Heading level={4} marginBottom={3}>
                        {content.preContract.pointOne.title}
                      </Heading>
                      <table className="twoColumnTable">
                        <tbody>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointOne.table[0]
                                    .columnOne.paragraphOne
                                }
                                <br />
                                {
                                  content.preContract.pointOne.table[0]
                                    .columnOne.paragraphTwo
                                }
                                <br />
                                {
                                  content.preContract.pointOne.table[0]
                                    .columnOne.paragraphThree
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointOne.table[0]
                                    .columnTwo.paragraphOne
                                }
                                <br />
                                {
                                  content.preContract.pointOne.table[0]
                                    .columnTwo.paragraphTwo
                                }
                                <br />
                                {
                                  content.preContract.pointOne.table[0]
                                    .columnTwo.paragraphThree
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointOne.table[1]
                                    .columnOne.paragraphOne
                                }
                                <br />
                                {
                                  content.preContract.pointOne.table[1]
                                    .columnOne.paragraphTwo
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointOne.table[1]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                              <div className="table-list">
                                <UnorderedList marginBottom={0}>
                                  {this.renderList(
                                    content.preContract.pointOne.table[1]
                                      .columnTwo.list
                                  )}
                                </UnorderedList>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ListItem>
                    <ListItem>
                      <Heading level={4} marginBottom={3}>
                        {content.preContract.pointTwo.title}
                      </Heading>
                      <table className="twoColumnTable">
                        <thead>
                          <tr>
                            <th>
                              {
                                content.preContract.pointTwo.table[0].columnOne
                                  .paragraphOne
                              }
                            </th>
                            <th>
                              {
                                content.preContract.pointTwo.table[0].columnTwo
                                  .paragraphOne
                              }
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[1]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[1]
                                    .columnOne.paragraphTwo
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointTwo.table[1]
                                    .columnTwo.paragraphOne
                                }
                                <br />
                                {
                                  content.preContract.pointTwo.table[1]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[2]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[2]
                                    .columnTwo.paragraphOne
                                }
                                <br />
                                {
                                  content.preContract.pointTwo.table[2]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                              <div className="table-list">
                                <UnorderedList marginBottom={0}>
                                  {this.renderList(
                                    content.preContract.pointTwo.table[2]
                                      .columnTwo.list
                                  )}
                                </UnorderedList>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointTwo.table[3]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[3]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointTwo.table[3]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td className="noBottomBorder">
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td className="noBottomBorder">
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                              <div className="table-list">
                                <UnorderedList>
                                  {this.renderList(
                                    content.preContract.pointTwo.table[4]
                                      .columnTwo.list
                                  )}
                                </UnorderedList>
                              </div>
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphThree
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td className="noTopBorder">
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnOne.paragraphTwo
                                }
                              </Paragraph>
                            </td>
                            <td className="noTopBorder">
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphFour
                                }
                              </Paragraph>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphFive
                                }
                                <br />
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphSix
                                }
                                <br />
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphSeven
                                }
                                <br />
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphEight
                                }
                                <br />
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphNine
                                }
                                <br />
                                {
                                  content.preContract.pointTwo.table[4]
                                    .columnTwo.paragraphTen
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[5]
                                    .columnOne.paragraphOne
                                }
                                <br />
                                {
                                  content.preContract.pointTwo.table[5]
                                    .columnOne.paragraphTwo
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph data-bdd="total-amount">
                                <strong>
                                  {
                                    content.preContract.pointTwo.table[5]
                                      .columnTwo.paragraphOne
                                  }
                                </strong>
                              </Paragraph>
                              <Paragraph>
                                {
                                  content.preContract.pointTwo.table[5]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointTwo.table[5]
                                    .columnTwo.paragraphThree
                                }
                              </Paragraph>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ListItem>
                    <ListItem>
                      <Heading level={4} marginBottom={3}>
                        {content.preContract.pointThree.title}
                      </Heading>
                      <table className="twoColumnTable">
                        <tbody>
                          <tr>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[0]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                <b>
                                  {
                                    content.preContract.pointThree.table[0]
                                      .columnTwo.subTitleOne
                                  }
                                </b>
                                <br />
                                {
                                  content.preContract.pointThree.table[0]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                              <div className="table-list">
                                <UnorderedList>
                                  {this.renderList(
                                    content.preContract.pointThree.table[0]
                                      .columnTwo.listOne
                                  )}
                                </UnorderedList>
                              </div>
                              <Paragraph>
                                <b>
                                  {
                                    content.preContract.pointThree.table[0]
                                      .columnTwo.subTitleTwo
                                  }
                                </b>
                                <br />
                                {
                                  content.preContract.pointThree.table[0]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                              <div className="table-list">
                                <UnorderedList>
                                  {this.renderList(
                                    content.preContract.pointThree.table[0]
                                      .columnTwo.listTwo
                                  )}
                                </UnorderedList>
                              </div>
                              <Paragraph marginBottom={0}>
                                <b>
                                  {
                                    content.preContract.pointThree.table[0]
                                      .columnTwo.subTitleThree
                                  }
                                </b>
                                <br />
                                {
                                  content.preContract.pointThree.table[0]
                                    .columnTwo.paragraphThree
                                }
                                <br />
                                {
                                  content.preContract.pointThree.table[0]
                                    .columnTwo.paragraphFour
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[1]
                                    .columnOne.paragraphOne
                                }
                                <br />
                                {
                                  content.preContract.pointThree.table[1]
                                    .columnOne.paragraphTwo
                                }
                              </Paragraph>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointThree.table[1]
                                    .columnOne.paragraphThree
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[1]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[1]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointThree.table[1]
                                    .columnTwo.paragraphThree
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointThree.table[2]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td className="noBottomBorder">
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td className="noBottomBorder">
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                              <Paragraph>
                                <b>
                                  {
                                    content.preContract.pointThree.table[3]
                                      .columnTwo.subTitleOne
                                  }
                                </b>
                                <br />
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                              <Paragraph>
                                <b>
                                  {
                                    content.preContract.pointThree.table[3]
                                      .columnTwo.subTitleTwo
                                  }
                                </b>
                                <br />
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnTwo.paragraphThree
                                }
                                <br />
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnTwo.paragraphFour
                                }
                                <br />
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnTwo.paragraphFive
                                }
                              </Paragraph>
                              <Paragraph>
                                <b>
                                  {
                                    content.preContract.pointThree.table[3]
                                      .columnTwo.subTitleThree
                                  }
                                </b>
                                <br />
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnTwo.paragraphSix
                                }
                                <br />
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnTwo.paragraphSeven
                                }
                                <br />
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnTwo.paragraphEight
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td className="noTopBorder">
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnOne.paragraphTwo
                                }
                              </Paragraph>
                            </td>
                            <td className="noTopBorder">
                              <Paragraph marginBottom={0}>
                                <b>
                                  {
                                    content.preContract.pointThree.table[3]
                                      .columnTwo.subTitleFour
                                  }
                                </b>
                                <br />
                                {
                                  content.preContract.pointThree.table[3]
                                    .columnTwo.paragraphNine
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[4]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[4]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[4]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointThree.table[4]
                                    .columnTwo.paragraphThree
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[5]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointThree.table[5]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                              <div className="table-list">
                                <UnorderedList>
                                  {this.renderList(
                                    content.preContract.pointThree.table[5]
                                      .columnTwo.list
                                  )}
                                </UnorderedList>
                              </div>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointThree.table[5]
                                    .columnTwo.paragraphTwo
                                }
                              </Paragraph>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ListItem>
                    <ListItem>
                      <Heading level={4} marginBottom={3}>
                        {content.preContract.pointFour.title}
                      </Heading>
                      <table className="twoColumnTable">
                        <tbody>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[0]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[0]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[1]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[1]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[2]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[2]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[3]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[3]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[4]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFour.table[4]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ListItem>
                    <ListItem>
                      <Heading level={4} marginBottom={3}>
                        {content.preContract.pointFive.title}
                      </Heading>
                      <table className="twoColumnTable">
                        <tbody>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[0]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[1]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                <span
                                  aria-label={
                                    content.preContract.pointFive.table[1]
                                      .columnTwo.paragraphOne +
                                    ariaTextBloat(
                                      content.preContract.pointFive.table[1]
                                        .columnTwo.paragraphOnePartTwo
                                    )
                                  }
                                >
                                  <span aria-hidden={isiOS ? false : true}>
                                    {content.preContract.pointFive.table[1]
                                      .columnTwo.paragraphOne +
                                      content.preContract.pointFive.table[1]
                                        .columnTwo.paragraphOnePartTwo}
                                  </span>
                                </span>
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[2]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[2]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[3]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[4]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointFive.table[4]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointFive.table[5]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph>
                                {
                                  content.preContract.pointFive.table[5]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                              <div className="table-list">
                                <UnorderedList marginBottom={0}>
                                  {this.renderList(
                                    content.preContract.pointFive.table[5]
                                      .columnTwo.list
                                  )}
                                </UnorderedList>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[6]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[6]
                                    .columnTwo.paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[7]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.preContract.pointFive.table[8]
                                    .columnOne.paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td className="breakWord">
                              <Paragraph>
                                {this.linkInText(
                                  content.preContract.pointFive.table[8]
                                    .columnTwo.paragraphOne.text,
                                  "[MAIL-LINK]",
                                  content.preContract.pointFive.table[8]
                                    .columnTwo.paragraphOne.mailLink
                                )}
                              </Paragraph>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ListItem>
                  </OrderedList>
                </Section>

                <Section id="key-points" marginBottom={7}>
                  <Heading level={1} marginBottom={4}>
                    {content.keyPoints.title}
                  </Heading>
                  <Paragraph>{this.renderBackToTopLink()}</Paragraph>

                  <Heading level={4} marginBottom={3}>
                    {content.keyPoints.pointOne.title}
                  </Heading>
                  <UnorderedList>
                    {this.renderList(content.keyPoints.pointOne.list)}
                  </UnorderedList>

                  <Heading level={4} marginBottom={3}>
                    {content.keyPoints.pointTwo.title}
                  </Heading>
                  <UnorderedList marginBottom={0}>
                    <ListItem>
                      {content.keyPoints.pointTwo.list.itemOne}
                    </ListItem>
                    <ListItem>
                      {content.keyPoints.pointTwo.list.itemTwo}
                    </ListItem>
                    <ListItem>
                      {content.keyPoints.pointTwo.list.itemThree}
                    </ListItem>
                    <ListItem>
                      {content.keyPoints.pointTwo.list.itemFour}
                    </ListItem>
                    <ListItem>
                      {content.keyPoints.pointTwo.list.itemFive}
                    </ListItem>
                    <ListItem marginBottom={0}>
                      {content.keyPoints.pointTwo.list.itemSix.item}
                      <UnorderedList marginBottom={0}>
                        {" "}
                        {this.renderList(
                          content.keyPoints.pointTwo.list.itemSix.list
                        )}{" "}
                      </UnorderedList>
                    </ListItem>
                  </UnorderedList>
                  <span className="insetContent">
                    <Paragraph marginBottom={3}>
                      {content.keyPoints.pointTwo.list.itemSix.paragraph}
                    </Paragraph>
                  </span>
                  <UnorderedList marginBottom={4}>
                    <ListItem marginBottom={0}>
                      {content.keyPoints.pointTwo.list.itemSeven.item}
                      <UnorderedList>
                        {this.renderList(
                          content.keyPoints.pointTwo.list.itemSeven.list
                        )}
                      </UnorderedList>
                    </ListItem>
                    <ListItem>
                      {content.keyPoints.pointTwo.list.itemEight.item}
                      <UnorderedList marginBottom={0}>
                        {" "}
                        {this.renderList(
                          content.keyPoints.pointTwo.list.itemEight.list
                        )}{" "}
                      </UnorderedList>
                    </ListItem>
                    <ListItem>
                      {content.keyPoints.pointTwo.list.itemNine}
                    </ListItem>
                    <ListItem>
                      {content.keyPoints.pointTwo.list.itemTen}
                    </ListItem>
                  </UnorderedList>

                  <Heading level={4} marginBottom={3}>
                    {content.keyPoints.pointThree.title}
                  </Heading>
                  <UnorderedList>
                    {this.renderList(content.keyPoints.pointThree.list)}
                  </UnorderedList>
                </Section>

                <Section id="card-agreement" marginBottom={7}>
                  <Heading level={1} marginBottom={4}>
                    {content.cardAgreement.title}
                  </Heading>
                  <Paragraph>{this.renderBackToTopLink()}</Paragraph>

                  <Section marginBottom={7}>
                    <Heading
                      level={3}
                      marginBottom={3}
                      aria-label={
                        content.cardAgreement.sectionOne.title +
                        ariaTextBloat(
                          content.cardAgreement.sectionOne.titlePartTwo
                        )
                      }
                    >
                      {content.cardAgreement.sectionOne.title +
                        content.cardAgreement.sectionOne.titlePartTwo}
                    </Heading>
                    <Heading level={4} marginBottom={1}>
                      {content.cardAgreement.sectionOne.subTitle}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.sectionOne.paragraphOne}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionOne.paragraphTwo}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionOne.paragraphThree}
                      <br />
                      {content.cardAgreement.sectionOne.paragraphFour}
                      <br />
                      {content.cardAgreement.sectionOne.paragraphNameAndAddress}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionOne.paragraphFive}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionOne.paragraphSix}
                    </Paragraph>
                    <UnorderedList>
                        {this.renderList(
                          content.cardAgreement.sectionOne.list
                        )}
                      </UnorderedList>
                  </Section>

                  <Heading level={2} marginBottom={4}>
                    {content.cardAgreement.subTitleOne}
                  </Heading>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.sectionTwo.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.sectionTwo.paragraphOne}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.sectionThree.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.sectionThree.paragraphOne}
                    </Paragraph>
                    <Paragraph marginBottom={0}>
                      {content.cardAgreement.sectionThree.paragraphTwo}
                    </Paragraph>
                    <div className="custom-ordered-list">
                      <OrderedList type={"lower-alpha"}>
                        {this.renderOrderedList(
                          content.cardAgreement.sectionThree.list
                        )}
                      </OrderedList>
                    </div>
                    <Paragraph>
                      {content.cardAgreement.sectionThree.paragraphThree}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionThree.paragraphFour}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionThree.paragraphFive}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionThree.paragraphSix}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionThree.paragraphSeven}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.sectionFour.title}
                    </Heading>

                    <Section marginBottom={7}>
                      <Heading
                        level={4}
                        marginBottom={3}
                        className="font-regular"
                      >
                        <em>
                          {
                            content.cardAgreement.sectionFour.subSectionOne
                              .title
                          }
                        </em>
                      </Heading>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionFour.subSectionOne
                            .paragraphOne
                        }
                      </Paragraph>
                      <table className="fourColumnTable">
                        <thead>
                          <tr>
                            <th>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[0].columnOne
                                    .paragraphOne
                                }
                              </Paragraph>
                            </th>
                            <th>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[0].columnTwo
                                    .paragraphOne
                                }
                              </Paragraph>
                            </th>
                            <th>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[0].columnThree
                                    .paragraphOne
                                }
                              </Paragraph>
                            </th>
                            <th>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[0].columnFour
                                    .paragraphOne
                                }
                              </Paragraph>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[1].columnOne
                                    .paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[1].columnTwo
                                    .paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[1].columnThree
                                    .paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[1].columnFour
                                    .paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[2].columnOne
                                    .paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[2].columnTwo
                                    .paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[2].columnThree
                                    .paragraphOne
                                }
                              </Paragraph>
                            </td>
                            <td>
                              <Paragraph marginBottom={0}>
                                {
                                  content.cardAgreement.sectionFour
                                    .subSectionOne.table[2].columnFour
                                    .paragraphOne
                                }
                              </Paragraph>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionFour.subSectionOne
                            .paragraphTwo
                        }
                      </Paragraph>
                      <Paragraph style={{ fontWeight: "bold" }}>
                        {
                          content.cardAgreement.sectionFour.subSectionOne
                            .paragraphThree
                        }
                      </Paragraph>
                    </Section>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading
                      level={4}
                      marginBottom={3}
                      className="font-regular"
                    >
                      <em>
                        {content.cardAgreement.sectionFive.subSectionOne.title}
                      </em>
                    </Heading>
                    <Paragraph>
                      {
                        content.cardAgreement.sectionFive.subSectionOne
                          .paragraphOne
                      }
                    </Paragraph>
                    <table className="threeColumnTable noMarginBottomLastP">
                      <thead>
                        <tr>
                          <th>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[0].columnOne.paragraphOne
                              }
                            </Paragraph>
                          </th>
                          <th>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[0].columnTwo.paragraphOne
                              }
                            </Paragraph>
                          </th>
                          <th>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[0].columnThree.paragraphOne
                              }
                            </Paragraph>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[1].columnOne.paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[1].columnTwo.paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[1].columnThree.paragraphOne
                              }
                            </Paragraph>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[2].columnOne.paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[2].columnTwo.paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[2].columnThree.paragraphOne
                              }
                            </Paragraph>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[3].columnOne.paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[3].columnTwo.paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionOne
                                  .table[3].columnThree.paragraphOne
                              }
                            </Paragraph>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Paragraph>
                      {
                        content.cardAgreement.sectionFive.subSectionOne
                          .paragraphTwo
                      }
                    </Paragraph>
                    <Paragraph>
                      {
                        content.cardAgreement.sectionFive.subSectionOne
                          .paragraphThree
                      }
                    </Paragraph>
                    <Paragraph>
                      {
                        content.cardAgreement.sectionFive.subSectionOne
                          .paragraphFour
                      }
                    </Paragraph>
                    <Paragraph>
                      {
                        content.cardAgreement.sectionFive.subSectionOne
                          .paragraphFive
                      }
                    </Paragraph>
                    <UnorderedList>
                      {this.renderList(
                        content.cardAgreement.sectionFive.subSectionOne.list
                      )}
                    </UnorderedList>
                    <Paragraph>
                      {
                        content.cardAgreement.sectionFive.subSectionOne
                          .paragraphSix
                      }
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading
                      level={4}
                      marginBottom={3}
                      className="font-regular"
                    >
                      <em>
                        {content.cardAgreement.sectionFive.subSectionTwo.title}
                      </em>
                    </Heading>
                    <table className="threeColumnTable noMarginBottomLastP">
                      <thead>
                        <tr>
                          <th>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionTwo
                                  .table[0].columnOne.paragraphOne
                              }
                            </Paragraph>
                          </th>
                          <th>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionTwo
                                  .table[0].columnTwo.paragraphOne
                              }
                            </Paragraph>
                          </th>
                          <th>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionTwo
                                  .table[0].columnThree.paragraphOne
                              }
                            </Paragraph>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionTwo
                                  .table[1].columnOne.paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive.subSectionTwo
                                  .table[1].columnTwo.paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td className="breakWord">
                            <Paragraph>
                              {this.linkInText(
                                this.linkInText(
                                  content.cardAgreement.sectionFive
                                    .subSectionTwo.table[1].columnThree
                                    .paragraphOne.text,
                                  "[LINK-ONE]",
                                  content.cardAgreement.sectionFive
                                    .subSectionTwo.table[1].columnThree
                                    .paragraphOne.linkOne
                                ),
                                "[LINK-TWO]",
                                content.cardAgreement.sectionFive.subSectionTwo
                                  .table[1].columnThree.paragraphOne.linkTwo
                              )}
                            </Paragraph>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Paragraph>
                      {this.linkInText(
                        content.cardAgreement.sectionFive.subSectionTwo
                          .paragraph,
                        "[LINK]",
                        content.cardAgreement.sectionFive.subSectionTwo.link
                      )}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading
                      level={4}
                      marginBottom={3}
                      className="font-regular"
                    >
                      <em>
                        {
                          content.cardAgreement.sectionFive.subSectionThree
                            .title
                        }
                      </em>
                    </Heading>
                    <table className="threeColumnTable noMarginBottomLastP">
                      <thead>
                        <tr>
                          <th>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[0].columnOne
                                  .paragraphOne
                              }
                            </Paragraph>
                          </th>
                          <th>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[0].columnTwo
                                  .paragraphOne
                              }
                            </Paragraph>
                          </th>
                          <th>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[0].columnThree
                                  .paragraphOne
                              }
                            </Paragraph>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[1].columnOne
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[1].columnTwo
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[1].columnThree
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[2].columnOne
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[2].columnTwo
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[2].columnThree
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[3].columnOne
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[3].columnTwo
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[3].columnThree
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[4].columnOne
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[4].columnTwo
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                          <td>
                            <Paragraph>
                              {
                                content.cardAgreement.sectionFive
                                  .subSectionThree.table[4].columnThree
                                  .paragraphOne
                              }
                            </Paragraph>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Section>
                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.sectionSix.title}
                      {content.cardAgreement.sectionSix.titleTwo}
                    </Heading>
                    <Heading level={4} marginBottom={3}>
                      {content.cardAgreement.sectionSix.titlePartTwo}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.sectionSix.paragraphOne}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.sectionSeven.title}
                    </Heading>

                    <Section marginBottom={7}>
                      <Heading
                        level={4}
                        marginBottom={3}
                        className="font-regular"
                      >
                        <em>
                          {
                            content.cardAgreement.sectionSeven.subSectionOne
                              .title
                          }
                        </em>
                      </Heading>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionSeven.subSectionOne
                            .paragraphOne
                        }
                      </Paragraph>
                    </Section>

                    <Section marginBottom={7}>
                      <Heading
                        level={4}
                        marginBottom={3}
                        className="font-regular"
                      >
                        <em>
                          {
                            content.cardAgreement.sectionSeven.subSectionTwo
                              .title
                          }
                        </em>
                      </Heading>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionSeven.subSectionTwo
                            .paragraphOne
                        }
                      </Paragraph>
                      <UnorderedList>
                        {this.renderList(
                          content.cardAgreement.sectionSeven.subSectionTwo.list
                        )}
                      </UnorderedList>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionSeven.subSectionTwo
                            .paragraphTwo
                        }
                      </Paragraph>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionSeven.subSectionTwo
                            .paragraphThree
                        }
                      </Paragraph>
                    </Section>

                    <Section marginBottom={7}>
                      <Heading
                        level={4}
                        marginBottom={3}
                        className="font-regular"
                      >
                        <em>
                          {
                            content.cardAgreement.sectionSeven.subSectionThree
                              .title
                          }
                        </em>
                      </Heading>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionSeven.subSectionThree
                            .paragraphOne
                        }
                      </Paragraph>
                      <UnorderedList>
                        {this.renderList(
                          content.cardAgreement.sectionSeven.subSectionThree
                            .list
                        )}
                      </UnorderedList>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionSeven.subSectionThree
                            .paragraphTwo
                        }
                      </Paragraph>
                    </Section>

                    <Section marginBottom={7}>
                      <Heading
                        level={4}
                        marginBottom={3}
                        className="font-regular"
                      >
                        <em>
                          {
                            content.cardAgreement.sectionSeven.subSectionFour
                              .title
                          }
                        </em>
                      </Heading>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionSeven.subSectionFour
                            .paragraphOne
                        }
                      </Paragraph>
                    </Section>

                    <Section marginBottom={7}>
                      <Heading
                        level={4}
                        marginBottom={3}
                        className="font-regular"
                      >
                        <em>
                          {
                            content.cardAgreement.sectionSeven.subSectionFive
                              .title
                          }
                        </em>
                      </Heading>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionSeven.subSectionFive
                            .paragraphOne
                        }
                      </Paragraph>
                    </Section>

                    <Section marginBottom={7}>
                      <Heading
                        level={4}
                        marginBottom={3}
                        className="font-regular"
                      >
                        <em>
                          {
                            content.cardAgreement.sectionSeven.subSectionSix
                              .title
                          }
                        </em>
                      </Heading>
                      <Paragraph>
                        {
                          content.cardAgreement.sectionSeven.subSectionSix
                            .paragraphOne
                        }
                      </Paragraph>
                    </Section>
                  </Section>

                  <Section marginBottom={0}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.sectionEight.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.sectionEight.paragraphOne}
                    </Paragraph>
                  </Section>

                  <Section>
                    <div className="box-signature">
                      <Heading level={4} marginBottom={1}>
                        {content.cardAgreement.sectionNine.itemOne.title}
                      </Heading>

                      <Paragraph>
                        <em>
                          {
                            content.cardAgreement.sectionNine.itemOne
                              .paragraphOne
                          }
                        </em>
                      </Paragraph>

                      <Paragraph marginBottom={0}>
                        {content.cardAgreement.sectionNine.itemOne.paragraphTwo}
                      </Paragraph>

                      <Paragraph marginBottom={0}>
                        {
                          content.cardAgreement.sectionNine.itemOne
                            .paragraphThree
                        }
                      </Paragraph>
                    </div>
                    <div className="box-signature">
                      <Paragraph marginBottom={0}>
                        {content.cardAgreement.sectionNine.itemTwo.paragraphOne}
                      </Paragraph>{" "}
                      <Paragraph marginBottom={0}>
                        {content.cardAgreement.sectionNine.itemTwo.paragraphTwo}
                      </Paragraph>
                    </div>{" "}
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.sectionTen.title}
                    </Heading>
                    <Paragraph>
                      {this.linkInText(
                        content.cardAgreement.sectionTen.paragraphOne.text,
                        "[INTERNAL-LINK]",
                        content.cardAgreement.sectionTen.paragraphOne.link,
                        content.cardAgreement.sectionTen.paragraphOne.linkText
                      )}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionTen.paragraphTwo}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.sectionEleven.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.sectionEleven.paragraphOne}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.sectionEleven.paragraphTwo}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.sectionTwelve.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.sectionTwelve.paragraphOne}
                    </Paragraph>
                  </Section>
                </Section>

                <Section id="card-agreement-additional-terms" marginBottom={7}>
                  <Heading level={2} marginBottom={4}>
                    {content.cardAgreement.additionalTerms.title}
                  </Heading>
                  <Section marginBottom={7}>
                    <OrderedList type={"decimal"}>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionOne
                              .title
                          }
                        </strong>
                        <OrderedList type={"denary"}>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionOne
                                .itemOne.paragraphOne
                            }
                            <OrderedList type={"lower-alpha"}>
                              {this.renderOrderedList(
                                content.cardAgreement.additionalTerms.sectionOne
                                  .itemOne.list
                              )}
                            </OrderedList>
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionOne
                                .itemTwo
                            }
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionOne
                                .itemThree
                            }
                          </ListItem>
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionTwo
                              .title
                          }
                        </strong>
                        <OrderedList type={"denary"}>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionTwo
                                .itemOne
                            }
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionTwo
                                .itemTwo.paragraphOne
                            }
                            <OrderedList type={"lower-alpha"}>
                              {this.renderOrderedList(
                                content.cardAgreement.additionalTerms.sectionTwo
                                  .itemTwo.list
                              )}
                            </OrderedList>
                            <Paragraph>
                              {
                                content.cardAgreement.additionalTerms.sectionTwo
                                  .itemTwo.paragraphTwo
                              }
                            </Paragraph>
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionTwo
                                .itemThree
                            }
                          </ListItem>
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionThree
                              .title
                          }
                        </strong>
                        <OrderedList type={"denary"}>
                          {this.renderOrderedList(
                            content.cardAgreement.additionalTerms.sectionThree
                              .list
                          )}
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionFour
                              .title
                          }
                        </strong>
                        <OrderedList type={"denary"}>
                          {this.renderOrderedList(
                            content.cardAgreement.additionalTerms.sectionFour
                              .list
                          )}
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionFive
                              .title
                          }
                        </strong>
                        <OrderedList type={"denary"}>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionFive
                                .itemOne.paragraphOne
                            }
                            <OrderedList type={"lower-alpha"}>
                              {this.renderOrderedList(
                                content.cardAgreement.additionalTerms
                                  .sectionFive.itemOne.list
                              )}
                            </OrderedList>
                            <Paragraph>
                              {
                                content.cardAgreement.additionalTerms
                                  .sectionFive.itemOne.paragraphTwo
                              }
                            </Paragraph>
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionFive
                                .itemTwo.paragraphOne
                            }
                            <OrderedList type={"lower-alpha"}>
                              {this.renderOrderedList(
                                content.cardAgreement.additionalTerms
                                  .sectionFive.itemTwo.list
                              )}
                            </OrderedList>
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionFive
                                .itemThree
                            }
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionFive
                                .itemFour
                            }
                          </ListItem>
                        </OrderedList>
                      </ListItem>
                      <ListItem marginBottom={4}>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionSix
                              .title
                          }
                        </strong>
                        <OrderedList marginBottom={2} type={"denary"}>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionSix
                                .list.itemOne
                            }
                          </ListItem>
                          <ListItem>
                            <Paragraph marginBottom={2}>
                              {
                                content.cardAgreement.additionalTerms.sectionSix
                                  .list.itemTwo.paragraphOne
                              }
                            </Paragraph>
                            <Paragraph marginBottom={2}>
                              {
                                content.cardAgreement.additionalTerms.sectionSix
                                  .list.itemTwo.paragraphTwo
                              }
                            </Paragraph>
                            <Paragraph marginBottom={2}>
                              {
                                content.cardAgreement.additionalTerms.sectionSix
                                  .list.itemTwo.paragraphThree
                              }
                            </Paragraph>
                            <Paragraph marginBottom={0}>
                              {
                                content.cardAgreement.additionalTerms.sectionSix
                                  .list.itemTwo.paragraphFour
                              }
                            </Paragraph>
                          </ListItem>
                        </OrderedList>
                        <OrderedList
                          continuous
                          className="continuous"
                          type={"denary"}
                        >
                          {this.renderOrderedList(
                            content.cardAgreement.additionalTerms.sectionSix
                              .listTwo
                          )}
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionSeven
                              .title
                          }
                        </strong>
                        <OrderedList type={"denary"}>
                          {this.renderOrderedList(
                            content.cardAgreement.additionalTerms.sectionSeven
                              .list
                          )}
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionEight
                              .title
                          }
                        </strong>
                        <Paragraph>
                          {
                            content.cardAgreement.additionalTerms.sectionEight
                              .paragraphOne
                          }
                        </Paragraph>
                        <OrderedList type={"lower-alpha"}>
                          {this.renderOrderedList(
                            content.cardAgreement.additionalTerms.sectionEight
                              .list
                          )}
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionNine
                              .title
                          }
                        </strong>
                        <OrderedList type={"denary"}>
                          {this.renderOrderedList(
                            content.cardAgreement.additionalTerms.sectionNine
                              .list
                          )}
                        </OrderedList>
                      </ListItem>
                      <ListItem marginBottom={4}>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionTen
                              .title
                          }
                        </strong>
                        <Paragraph className="no-indentation">
                          <em>
                            {
                              content.cardAgreement.additionalTerms.sectionTen
                                .paragraphOne
                            }
                          </em>
                        </Paragraph>
                        <OrderedList type={"denary"}>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionTen
                                .itemOne
                            }
                          </ListItem>
                        </OrderedList>
                        <Paragraph className="no-indentation">
                          <em>
                            {
                              content.cardAgreement.additionalTerms.sectionTen
                                .paragraphTwo
                            }
                          </em>
                        </Paragraph>
                        <OrderedList
                          type={"denary"}
                          continuous
                          className="continuous"
                        >
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionTen
                                .itemTwo.paragraphOne
                            }
                            <OrderedList type={"lower-alpha"} marginBottom={0}>
                              <ListItem>
                                {
                                  content.cardAgreement.additionalTerms
                                    .sectionTen.itemTwo.itemOne
                                }
                              </ListItem>
                              <ListItem>
                                {
                                  content.cardAgreement.additionalTerms
                                    .sectionTen.itemTwo.itemTwo
                                }
                              </ListItem>
                              <ListItem>
                                {
                                  content.cardAgreement.additionalTerms
                                    .sectionTen.itemTwo.itemThree
                                }
                              </ListItem>
                              <ListItem>
                                {
                                  content.cardAgreement.additionalTerms
                                    .sectionTen.itemTwo.itemFour.paragraphOne
                                }
                                <br />
                                <Paragraph className="no-indentation">
                                  {
                                    content.cardAgreement.additionalTerms
                                      .sectionTen.itemTwo.itemFour.paragraphTwo
                                  }
                                </Paragraph>
                                <OrderedList type={"lower-roman"}>
                                  {this.renderOrderedList(
                                    content.cardAgreement.additionalTerms
                                      .sectionTen.itemTwo.itemFour.list
                                  )}
                                </OrderedList>
                                <Paragraph
                                  className="no-indentation"
                                  id="no-space"
                                >
                                  {
                                    content.cardAgreement.additionalTerms
                                      .sectionTen.itemTwo.itemFour
                                      .paragraphThree
                                  }
                                </Paragraph>
                              </ListItem>
                            </OrderedList>
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms.sectionTen
                                .itemThree
                            }
                          </ListItem>
                        </OrderedList>
                      </ListItem>
                      <ListItem marginBottom={4}>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionEleven
                              .title
                          }
                        </strong>
                        <Paragraph className="no-indentation" marginBottom={4}>
                          {
                            content.cardAgreement.additionalTerms.sectionEleven
                              .paragraphOne
                          }
                        </Paragraph>
                      </ListItem>
                      <ListItem marginBottom={4}>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionTwelve
                              .title
                          }
                        </strong>
                        <Paragraph className="no-indentation">
                          {
                            content.cardAgreement.additionalTerms.sectionTwelve
                              .paragraphOne
                          }
                        </Paragraph>
                        <OrderedList type={"lower-alpha"}>
                          {this.renderOrderedList(
                            content.cardAgreement.additionalTerms.sectionTwelve
                              .list
                          )}
                        </OrderedList>
                        <Paragraph className="no-indentation">
                          {
                            content.cardAgreement.additionalTerms.sectionTwelve
                              .paragraphTwo
                          }
                        </Paragraph>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms
                              .sectionThirteen.title
                          }
                        </strong>
                        <Paragraph className="no-indentation">
                          {
                            content.cardAgreement.additionalTerms
                              .sectionThirteen.paragraphOne
                          }
                        </Paragraph>
                        <OrderedList type={"lower-alpha"}>
                          {this.renderOrderedList(
                            content.cardAgreement.additionalTerms
                              .sectionThirteen.list
                          )}
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms
                              .sectionFourteen.title
                          }
                        </strong>
                        <OrderedList type={"denary"}>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms
                                .sectionFourteen.itemOne.paragraphOne
                            }
                            <OrderedList type={"lower-alpha"}>
                              {this.renderOrderedList(
                                content.cardAgreement.additionalTerms
                                  .sectionFourteen.itemOne.list
                              )}
                            </OrderedList>
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms
                                .sectionFourteen.itemTwo
                            }
                          </ListItem>
                          <ListItem>
                            {
                              content.cardAgreement.additionalTerms
                                .sectionFourteen.itemThree
                            }
                          </ListItem>
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        <strong>
                          {
                            content.cardAgreement.additionalTerms.sectionFifteen
                              .title
                          }
                        </strong>
                        <Paragraph className="no-indentation">
                          {
                            content.cardAgreement.additionalTerms.sectionFifteen
                              .paragraphOne
                          }
                        </Paragraph>
                      </ListItem>
                    </OrderedList>
                  </Section>
                </Section>

                <Section
                  id="card-agreement-important-information"
                  marginBottom={7}
                >
                  <Heading level={1} marginBottom={4}>
                    {content.cardAgreement.contactUs.title}
                  </Heading>
                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={1}>
                      {content.cardAgreement.contactUs.sectionOne.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.contactUs.sectionOne.paragraphOne}
                    </Paragraph>
                    <UnorderedList>
                      {this.renderList(
                        content.cardAgreement.contactUs.sectionOne.list
                      )}
                    </UnorderedList>
                    <Paragraph>
                      {content.cardAgreement.contactUs.sectionOne.paragraphTwo}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.contactUs.sectionTwo.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.contactUs.sectionTwo.paragraphOne}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.contactUs.sectionTwo.paragraphTwo}
                    </Paragraph>
                    <Paragraph>
                      {
                        content.cardAgreement.contactUs.sectionTwo
                          .paragraphThree
                      }
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.contactUs.sectionTwo.paragraphFour}
                    </Paragraph>
                    <Paragraph>
                      {content.cardAgreement.contactUs.sectionTwo.paragraphFive}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.contactUs.sectionThree.title}
                    </Heading>
                    <Paragraph>
                      {
                        content.cardAgreement.contactUs.sectionThree
                          .paragraphOne
                      }
                    </Paragraph>
                    <Paragraph>
                      {this.linkInText(
                        this.linkInText(
                          content.cardAgreement.contactUs.sectionThree
                            .paragraphTwo.text,
                          "[LINK]",
                          content.cardAgreement.contactUs.sectionThree
                            .paragraphTwo.link
                        ),
                        "[EMAIL-LINK]",
                        content.cardAgreement.contactUs.sectionThree
                          .paragraphTwo.emailLink
                      )}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={3}>
                      {content.cardAgreement.contactUs.sectionFour.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.contactUs.sectionFour.paragraphOne}
                    </Paragraph>
                    <UnorderedList>
                      {this.renderList(
                        content.cardAgreement.contactUs.sectionFour.list
                      )}
                    </UnorderedList>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={1}>
                      {content.cardAgreement.contactUs.sectionFive.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.contactUs.sectionFive.paragraphOne}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={1}>
                      {content.cardAgreement.contactUs.sectionSix.title}
                    </Heading>
                    <Paragraph>
                      {content.cardAgreement.contactUs.sectionSix.paragraphOne}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={1}>
                      {content.cardAgreement.contactUs.sectionSeven.title}
                    </Heading>
                    <Paragraph>
                      <span
                        aria-label={
                          content.cardAgreement.contactUs.sectionSeven
                            .paragraphOne.partOne +
                          ariaTextBloat(
                            content.cardAgreement.contactUs.sectionSeven
                              .paragraphOne.partTwo
                          ) +
                          content.cardAgreement.contactUs.sectionSeven
                            .paragraphOne.partThree
                        }
                      >
                        <span aria-hidden={isiOS ? false : true}>
                          {content.cardAgreement.contactUs.sectionSeven
                            .paragraphOne.partOne +
                            content.cardAgreement.contactUs.sectionSeven
                              .paragraphOne.partTwo +
                            content.cardAgreement.contactUs.sectionSeven
                              .paragraphOne.partThree}
                        </span>
                      </span>
                    </Paragraph>
                    <Paragraph>
                      {this.linkInText(
                        content.cardAgreement.contactUs.sectionSeven
                          .paragraphTwo.text,
                        "[LINK]",
                        content.cardAgreement.contactUs.sectionSeven
                          .paragraphTwo.link
                      )}
                    </Paragraph>
                  </Section>

                  <Section marginBottom={7}>
                    <Heading level={3} marginBottom={1}>
                      {content.cardAgreement.contactUs.sectionEight.title}
                    </Heading>
                    <Paragraph>
                      {
                        content.cardAgreement.contactUs.sectionEight
                          .paragraphOne
                      }
                    </Paragraph>
                    <Paragraph>
                      {
                        content.cardAgreement.contactUs.sectionEight
                          .paragraphTwo
                      }
                    </Paragraph>
                    <Paragraph>
                      {this.linkInText(
                        content.cardAgreement.contactUs.sectionEight
                          .paragraphThree.text,
                        "[LINK]",
                        content.cardAgreement.contactUs.sectionEight
                          .paragraphThree.link
                      )}
                    </Paragraph>
                  </Section>
                </Section>
                <AcceptFooter
                  visible={this.state.submitVisible}
                  values={values}
                />
              </>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </FormLayout>
      </>
    );
  }
}

export default AcceptLegal;
