import React, { Component } from "react";
import {
  Section,
  Heading,
  HorizontalRule,
  Link,
  IconEdit,
  PlaybackKey,
  PlaybackList,
  PlaybackValue,
  VisuallyHidden
} from "@piggybank/core";
import content from "../../i18n/content/review.js";
import employmentDetailsContent from "../../i18n/content/employmentDetails.js";
import { employmentStatus } from "../../i18n/lookups/employmentStatus";
import occupation from "../../i18n/lookups/occupation";
import {
  key2Label,
  dateTransform,
  monthYearDateTransform,
  capitalise
} from "../../shared/transformations";

const isEmployedStatus = status => ["FULL", "PART", "SELF"].includes(status);
const isAtHomeStatus = status => ["HOME"].includes(status);
const isUnemployedStatus = status => ["UNEMPLOYED"].includes(status);
const isStudentStatus = status => ["STUDENT"].includes(status);

class ReviewEmploymentDetails extends Component {
  render() {
    const { values, onClick } = this.props;
    return (
      <Section data-bdd="employment-details-review">
        <div className="heading">
          <div className="heading heading--header">
            <Heading
              marginBottom={3}
              level={2}
              data-bdd="employment-details-heading"
            >
              {employmentDetailsContent.pageTitle}
            </Heading>
          </div>
          <div className="heading heading--editlink">
            <Link
              elementType="button"
              type="button"
              buttonPadding
              data-bdd="employment-details-edit"
              id="employment-details-edit"
              onClick={onClick}
              strong
              iconSlotRight={<IconEdit inline />}
            >
              {content.editButton}
              <VisuallyHidden>
                {employmentDetailsContent.pageTitle}
              </VisuallyHidden>
            </Link>
          </div>
        </div>
        <HorizontalRule />
        <PlaybackList marginBottom={7} id="employmentDetailsSection">
          <PlaybackKey>
            {employmentDetailsContent.employmentStatus.label}
          </PlaybackKey>
          <PlaybackValue marginBottom={3} id="employment-status">
            {key2Label(values["employment-status"], employmentStatus)}
          </PlaybackValue>

          {isEmployedStatus(values["employment-status"]) && (
            <>
              <PlaybackKey>
                {employmentDetailsContent.occupation.label}
              </PlaybackKey>

              <PlaybackValue id="occupation" marginBottom={3}>
                {key2Label(values.occupation, occupation)}
              </PlaybackValue>

              <PlaybackKey>
                {employmentDetailsContent.companyName.label}
              </PlaybackKey>
              <PlaybackValue id="company-name" marginBottom={3}>
                {values["company-name"]}
              </PlaybackValue>
              <PlaybackKey>
                {employmentDetailsContent.employmentStartDate.label}
              </PlaybackKey>
              <PlaybackValue id="employment-start-date" marginBottom={3}>
                {monthYearDateTransform(values["employment-start-date"])}
              </PlaybackValue>
            </>
          )}

          {isAtHomeStatus(values["employment-status"]) && (
            <>
              <PlaybackKey>{employmentDetailsContent.atHome.label}</PlaybackKey>
              <PlaybackValue id="income-from-benefits" marginBottom={3}>
                {capitalise(values["income-from-benefits"])}
              </PlaybackValue>
            </>
          )}

          {isUnemployedStatus(values["employment-status"]) && (
            <>
              <PlaybackKey>
                {employmentDetailsContent.unemploymentBenefits.label}
              </PlaybackKey>
              <PlaybackValue id="unemployment-benefit" marginBottom={3}>
                {capitalise(values["unemployment-benefit"])}
              </PlaybackValue>
            </>
          )}

          {isStudentStatus(values["employment-status"]) && (
            <>
              <PlaybackKey>
                {employmentDetailsContent.courseEndDate.label}
              </PlaybackKey>
              <PlaybackValue id="course-end-date" marginBottom={3}>
                {dateTransform(values["course-end-date"])}
              </PlaybackValue>
            </>
          )}
        </PlaybackList>
      </Section>
    );
  }
}

export default ReviewEmploymentDetails;
