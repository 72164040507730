import React, { Component } from "react";
import PropTypes from "prop-types";
import { Paragraph, Heading } from "@piggybank/core";
import { Navigation } from "@piggybank/form";
import content from "../../../i18n/content/divaSkip.js";
import { disableBackButton } from "../../../shared/browser";
import { view } from "../../../shared/analytics/tealium/events";
import { TRACK_DIVA_SKIPPED } from "../../../shared/analytics/pageData.js";
import { utagGlobal } from "../../../shared/analytics/tealium";

class DivaSkip extends Component {
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_DIVA_SKIPPED));
    document.title = `${content.pageTitle}`;
    disableBackButton();
    this.setFocus("page-title");
  }

  setFocus = id => {
    if (document.getElementById(id) != null) {
      document.getElementById(id).focus();
    }
  };

  render() {
    return (
      <div id="diva-skipped-bio">
        <Heading level={1} data-bdd="page-title" id="page-title" tabIndex={-1}>
          {content.pageTitle}
        </Heading>

        <Paragraph data-bdd="paragraph-one">{content.paragraphOne}</Paragraph>
        <Paragraph marginBottom={7} data-bdd="paragraph-two">
          {content.paragraphTwo}
        </Paragraph>

        <Navigation
          textMap={{ submit: content.ctaText }}
          disablePrevious={true}
          isLastPage={true}
          currentPage={1}
          onPrevious={() => {}}
          id="cta-button"
          data-bdd="cta-button"
        />
      </div>
    );
  }
}

DivaSkip.propTypes = {
  values: PropTypes.object
};

export default DivaSkip;
