import React from "react";
import { FormLayout } from "@piggybank/core";
import { ProgressIndicator } from "@piggybank/form";
import { getDeviceDetails } from "diva-web-sdk/dist/main.min.js";

const { isDeviceSupported } = getDeviceDetails();

const FormWrapper = props => {
  return (
    <FormLayout>
      <ProgressIndicator
        data-bdd="progress-indicator"
        marginBottom={5}
        renderStepText={({ current, total }) => {
          return (current === 2) & (total === 7) & isDeviceSupported ? (
            <>
              <strong>Identity check</strong>
            </>
          ) : (
            <>
              <strong>Application | </strong>Step {current} of {total}
            </>
          );
        }}
      />
      {props.children}
    </FormLayout>
  );
};

export default FormWrapper;
