import { DateTime } from "luxon";
export const joinPath = (base, path) => {
  return base.charAt(base.length - 1) === "/" ? base + path : base + "/" + path;
};

export const wait = duration =>
  new Promise(resolve => setTimeout(resolve, duration));

export const isNotEmptyObject = obj => {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return true;
    }
  }
  return false;
};

export const isEmptyObject = obj => {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
};

export const sanitiseField = fieldVal => {
  if (typeof fieldVal !== "undefined") {
    return fieldVal
      .trim()
      .replace(/&nbsp;/g, " ")
      .replace(/\s\s+/g, " ")
      .replace(/[’‘]/g, "'");
  }
};

export const removeTrailingZero = val => {
  return parseFloat(val).toFixed(2) / 1;
};

export const isDivaAvailable = () => {
  const currentTime = DateTime.local().setZone("Europe/London");
  const divaStart = DateTime.fromObject({
    hour: 5,
    minute: 30,
    zone: "Europe/London"
  });

  const divaEnd = DateTime.fromObject({
    hour: 21,
    minute: 0,
    zone: "Europe/London"
  });
  return currentTime >= divaStart && currentTime <= divaEnd;
};

export const setFocus = id => {
  if (document.getElementById(id) != null) {
    document.getElementById(id).focus();
  }
};
