export default {
  pageTitle: "Application complete",
  generalPageCopy1:
    "Thanks for your application. We've referred your application to our underwriters who will contact you with a decision by email within 5 working days.",
  exceptionPageCopy1:
    "Thanks for your application. We'll get back to you with a decision by email within 5 working days.",
  pageCopy2: "If you have any questions please ",
  pageLink: "contact us.",
  pageLinkUrl: "https://bank.marksandspencer.com/explore/contact-us/",
  buttonLabel: "Navigate to M&S Bank"
};
