import React, { Component } from "react";
import PropTypes from "prop-types";
import { Section, Heading, IconError, FormLayout } from "@piggybank/core";
import content from "../../i18n/content/errorGeneric.js";
import Timer from "../../components/Timer";
import formatTitle from "../../shared/transformations/formatTitle";

class GenericErrorPage extends Component {
  componentDidMount() {
    document.title = formatTitle(content.pageTitle);
    this.setFocus("page-title");
  }

  setFocus = id => {
    if (document.getElementById(id) != null) {
      document.getElementById(id).focus();
    }
  };

  render() {
    return (
      <FormLayout>
        <Section>
          <div style={{ margin: "3px 0px 3px -2px", padding: "3px" }}>
            <IconError width="36" data-bdd="error-icon" />
          </div>
          <Heading
            level={1}
            data-bdd="page-title"
            id="page-title"
            tabIndex={-1}
          >
            {content.pageTitle}
          </Heading>

          {this.props.children}

          <Timer pageTags={this.props.eventTaggingConstants} />
        </Section>
      </FormLayout>
    );
  }
}

GenericErrorPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  errorCode: PropTypes.string
};
export default GenericErrorPage;
