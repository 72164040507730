import 'url-search-params-polyfill';

const randomNumber = max => {
  return ("0" + (Math.floor(Math.random() * Math.floor(max)) + 1)).slice(-2);
};
const sourceCode = new URLSearchParams(window.location.search).get('source') || 'KIN3';
const mcc = new URLSearchParams(window.location.search).get('mcc') || '3321';

export const urlDetails = {
  sourceCode: sourceCode,
  mcc: mcc
};
export const personalDetails = {
  title: "mr",
  forename: "Anthony",
  middlenames: "Edward",
  surname: Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 15),
  "more-names": "yes",
  previousNames: [
    {
      previousForename: "Steve",
      previousSurname: "Rogers"
    },
    {
      previousForename: "Bruce",
      previousSurname: "Banner"
    },
    {
      previousForename: "Natasha",
      previousMiddlenames: "Alianovna",
      previousSurname: "Romanov"
    }
  ],
  "date-of-birth": `19${randomNumber(90)}-${randomNumber(11)}-${randomNumber(
    29
  )}`,
  "maiden-name": "Collins",
  "marital-status": "SINGLE",
  "number-of-dependants": "0",
  "country-of-birth": "GB",
  "country-of-residence": "GB",
  nationality: "GB",
  "additional-nationalities": ["US", "FR"]
};

export const contactDetails = {
  email: "loan@noexternalmail.hsbc.com",
  "mobile-number": {
    raw: "07700900000",
    value: "+447700900000"
  },
  "home-number": {
    raw: "01144960000",
    value: "+441144960000"
  },
  "work-number": {
    raw: "01144960000",
    value: "+441144960000"
  },
  "post-code": "LS98 1FD",
  "house-number": "40",
  address1: "Wakefield Road",
  address2: "Stourton",
  address3: "Leeds",
  "date-moved": "2018-11",
  previousAddresses: [
    {
      "post-code": "RG12 9FG",
      "house-number": "12",
      address1: "Picket Post Close",
      address2: "Bracknell",
      address3: "Berkshire",
      "date-moved": "2017-09"
    },
    {
      country: "GB",
      "post-code": "NW1 6XE",
      "house-number": "221",
      "flat-number": "B",
      address1: "Baker Street",
      address2: "London",
      "date-moved": "2017-05"
    },
    {
      country: "FR",
      address1: "35 Rue du Faubourg",
      address2: "75008 Paris",
      "date-moved": "2000-03"
    }
  ],
  "residential-status": "OWNER"
};

export const employmentDetails = {
  "employment-status": "PENSION"
};

export const financialDetails = {
  "personal-income": "10001",
  "joint-income": "",
  "time-at-bank": "2012-12",
  affordability: "yes",
  "tax-residency-type": "O",
  "tax-residencies": ["DE", "FR"]
};

export const setupPreferences = {
  directDebit: "no",
  repaymentMethod: "",
  "credit-limit": "0",
  bankDetailsCorrect: "N"
};

export const review = {
  "email-confirm": true
};
