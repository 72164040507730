import React from "react";

const DirectDebitIcon = props => (
  <span>
    <svg
      data-bdd="directDebitIcon"
      aria-hidden="true"
      focusable="false"
      role="img"
      version="1.1"
      width={props.width}
      height={props.width}
      viewBox="0 0 290 133"
    >
      <path
        d="M259.52 397.81c0-26.57-15.94-38.09-34.08-42.15v-1.37c33 1.72 57.17 21.8 57.16 44.77 0 26.23-28.45 44.94-63.53 44.91s-63.36-20.17-63.35-45c0-26 24.72-44.85 66.83-44.82v32.36l-10-.21c-21.33.74-46 10-46 22.53 0 13.74 23.44 30.28 51.6 30.3 22.76.02 41.36-16.49 41.37-41.32z"
        transform="translate(-155.6 -354.03)"
      />
      <path
        d="M222.33 435.42c-12.09-.88-27.63-8.94-27.62-22.83 0-13.74 13.25-23.7 27.64-24.24zM411 373l-.63 3.57c-1.66-.55-17.44-4.25-17.67 7.49-.18 9.4 14.18 8.25 17.47 7.38l.37 3.89c-10.8 3-25.14-.27-25.43-11.36-.39-12.82 18.58-14.4 25.89-10.97z"
        transform="translate(-155.6 -354.03)"
      />
      <path d="M266.5 41.92l.01-20.14-9.7-.01v-3.84l26.07.02v3.84h-9.51l-.01 20.13h-6.86zm-58.14-24.2l19.22.01v4.02h-12.81l-.01 19.95-6.41-.01.01-23.97z" />
      <path d="M214.17 27.83h12.66v4.02h-12.66zm-.01 9.85h13.31v4.02h-13.31zm-48.82-19.94h7.18v23.87h-7.18zm-33 .18h7.18v23.87h-7.18z" />
      <path
        d="M292.71 395.83l1.45-3.87 5.38-.15c6.23 0 10-3.46 10-8 0-5.53-5-8.15-8.89-8.15l-6.53-.36-.9-3.31h7.58c8.73 0 16.28 4.69 16.27 12.05s-7.55 11.83-16.28 11.83z"
        transform="translate(-155.6 -354.03)"
      />
      <pa d="M132.33 47.92h7.18v23.87h-7.18z" />
      <path
        d="M292.7 425.83l1.45-3.87 5.38-.15c6.23 0 10-3.46 10-8 0-5.52-5-8.14-8.89-8.14l-6.53-.36-.9-3.32h7.58c8.73 0 16.28 4.68 16.27 12.05s-7.55 11.83-16.28 11.82z"
        transform="translate(-155.6 -354.03)"
      />
      <path d="M204.91 46.07h6.93v25.88h-6.93zm38.33 8.37h6.93v17.74h-6.93zm0-8.3h6.93v4.57h-6.93z" />
      <path
        d="M422.25 421.55v-17.64h7.09v16.83c0 2.27 3.87 3 6.08 2.22v3.92c-4.79 1.12-13.17.96-13.17-5.33z"
        transform="translate(-155.6 -354.03)"
      />
      <path d="M261.13 54.45h18.49v3.77h-18.49z" />
      <path
        d="M332 418.17c.35 6.89 14.22 4.44 16.07 2.48v4.17c-2.76 1.3-6.32 1.45-10.27 1.45-7.2 0-13-3.68-13-9.66 0-5.33 5.38-9.55 13-9.54 5.9 0 12.43 2.77 12 11.12zm.05-3.31h11.15c-.3-2.69-2.31-4.2-5.32-4.2-3.23-.02-5.7 1.23-5.8 4.2zm25.66-36.78c0 5.68-5.34 6.11-5.62 6.13a7.16 7.16 0 011.65 1.71l5.5 9.8h-7.49l-3.75-7.14a4.07 4.07 0 00-3.92-2.42h-2.65v9.55h-7.12v-24h12.87c3.57.04 10.54.94 10.53 6.37zm-16.37 4H346c1.86 0 4.52-1.21 4.52-3.19 0-2.19-2.2-3.25-4.42-3.25h-4.77zm24 34.99c0-6.61 4.37-9.28 10.62-9.27 5.84 0 10.53 3.12 10.52 9.28s-4.73 9.57-10.57 9.57c-6.76-.01-10.58-2.87-10.57-9.58zm2.06.2c0 3 2.71 5.45 6.07 5.46s6.08-2.44 6.08-5.45-2.72-5.46-6.07-5.46-6.08 2.44-6.08 5.45z"
        transform="translate(-155.6 -354.03)"
      />
    </svg>
  </span>
);

export default DirectDebitIcon;
