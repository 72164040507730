import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Accept from "../../i18n/Accept";
import AcceptLegal from "../../i18n/AcceptLegal";
import Loading from "../../components/Loading";
import { Wizard, WizardPage } from "@piggybank/form";
import Headers from "../../shared/headers";
import axios from "axios";
import history from "../../shared/history";

class AcceptJourney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      pdfError: false,
      pdfInfoPending: false,
      storeAgreementFailed: false,
      agreementInfoPending: false,
      agreementInfo: {},
      loading: false,
      timerFinished: false,
      responded: false,
    };
  }

  componentDidMount() {
    this.makeRequests();
  }

  componentDidUpdate() {
    if (this.state.responded && this.state.timerFinished) {
      this.setState({ loading: !this.state.loading });
      this.setState({ responded: !this.state.responded });
      this.setState({ timerFinished: !this.state.timerFinished });
    }
  }

  makeRequests = () => {
    const promises = this.generateRequests();
    const promisesResolved = promises.map((promise) =>
      promise.catch((error) => error.response)
    );

    // The order of the way the responses is returned
    // depends on the order in which they are made in generateRequest
    const resolveSuccessAndFail = (then) => (responses) =>
      then({
        pdf: responses[0],
        agreement: responses[1],
      });

    return axios.all(promisesResolved).then(
      resolveSuccessAndFail(({ pdf, agreement }) => {
        agreement.status !== 200
          ? this.handleAgreementFail(agreement)
          : this.handleAgreementSuccess(agreement);
        pdf.status !== 200
          ? this.handleDocFail(pdf)
          : this.handleDocSuccess(pdf);
      })
    );
  };

  generateRequests = () => {
    Headers["x-hsbc-state"] = this.props.xState;

    const agreementHeaders = { ...Headers };
    agreementHeaders.accept = "application/json";

    const pdfHeaders = { ...Headers };
    pdfHeaders.accept = "application/pdf";

    const pdfDownloadUrl = {
      url: process.env.REACT_APP_AGREEMENT_DOWNLOAD,
      method: "get",
      responseType: "blob", // important
      headers: pdfHeaders,
    };

    const agreementInfo = {
      url: process.env.REACT_APP_AGREEMENT_INFO,
      method: "get",
      headers: agreementHeaders,
    };

    this.setState({
      agreementInfoPending: true,
      pdfInfoPending: true,
    });

    return [axios.request(pdfDownloadUrl), axios.request(agreementInfo)];
  };

  handleDocSuccess = (pdf) => {
    this.setState({
      url: pdf.data,
      pdfInfoPending: false,
    });
  };

  handleDocFail = (pdf) => {
    this.setState({
      pdfInfoPending: false,
    });
  };

  handleAgreementSuccess = (agreementInfo) => {
    this.setState({
      agreementInfo: agreementInfo.data,
      agreementInfoPending: false,
    });
  };

  handleAgreementFail = (error) => {
    if (error.data.workItemRaised) {
      this.setState({
        errorState: "callYou",
        errorCode: error.status,
        agreementInfoPending: false,
      });
    } else {
      this.setState({
        errorState: "callUs",
        errorCode: error.status,
        agreementInfoPending: false,
      });
    }
  };

  generateSubmission = () => {
    Headers["x-hsbc-state"] = this.props.xState;
    const storeAgreement = {
      url: process.env.REACT_APP_STORE_AGREEMENT,
      method: "post",
      headers: Headers,
    };
    const sendAgreement = {
      url: process.env.REACT_APP_SEND_AGREEMENT,
      method: "post",
      headers: Headers,
    };

    // The order in which these calls are made should be reflected
    // in the order in which they are returned in resolveSuccessAndFail
    return [axios.request(storeAgreement), axios.request(sendAgreement)];
  };

  handleSubmit = (values) => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ timerFinished: true });
    }, 2000);

    const promises = this.generateSubmission();
    const promisesResolved = promises.map((promise) =>
      promise.catch((error) => error.response)
    );

    // The order of the way the responses is returned
    // depends on the order in which they are made in generateSubmission
    const resolveSuccessAndFail = (then) => (responses) =>
      then({
        store: responses[0],
        send: responses[1],
      });

    return axios.all(promisesResolved).then(
      resolveSuccessAndFail(({ store, send }) => {
        if (Number(store.status) < 300) {
          this.handleSubmitSuccess(store, history);
        } else {
          this.handleSubmitError(store, history);
        }
      })
    );
  };

  handleSubmitSuccess = (response) => {
    this.setState({ responded: true });
    if (response.data.outcome === "PENDING") {
      this.setState({
        errorState: "callYou",
        errorCode: "PENDING",
        storeAgreementFailed: true,
      });
    } else {
      history.push("/acceptComplete", {
        redirection: true,
        page: "accept",
      });
    }
  };

  handleSubmitError = (error) => {
    this.setState({
      responded: true,
      errorState: "callUs",
      errorCode: error.status,
      storeAgreementFailed: true,
    });
  };

  render() {
    const { props } = this;
    const { errorState, errorCode, storeAgreementFailed } = this.state;
    if (storeAgreementFailed) {
      return (
        <Redirect
          to={{
            pathname: "/error-accept",
            state: {
              eqaNumber: props.applicationId,
              errorState: errorState,
              errorCode: errorCode,
            },
          }}
        />
      );
    }
    return (
      <>
        <Wizard onSubmit={this.handleSubmit}>
          <WizardPage>
            <Accept
              cardType={props.cardType}
              cardImage={props.cardImage}
              acceptFields={props.acceptFields}
              applicationId={props.applicationId}
              errorState={errorState}
              errorCode={errorCode}
              {...props}
            />
          </WizardPage>
          <WizardPage>
            {/*
            This loading is necessary because if the user clicks Continue
            before the API call has finished, they will see a black page
            */}
            {!this.state.agreementInfoPending && !this.state.pdfInfoPending ? (
              <AcceptLegal
                pdf={this.state.url}
                cardType={props.cardType}
                cardImage={props.cardImage}
                agreementInfo={this.state.agreementInfo}
              />
            ) : (
              <Loading />
            )}
          </WizardPage>
        </Wizard>
        <Loading show={this.state.loading} />
      </>
    );
  }
}
export default AcceptJourney;
