export default {
  pageTitle: "Financial details",
  pageIntro: "Next, we need to know your income and tax details.",
  sectionTitle: "Income",
  personalIncome: {
    label: "Personal annual income before tax",
    doorhangerTitle: "What should be included in annual income",
    doorhangerCopy: {
      paragraph1: "You should include the annual income you receive before tax, National Insurance and any other deductions. This includes self-employed income and any pensions.",
      paragraph2: "If you receive income from a zero hours' contract, please include your average earnings for the last 12 months with the same employer.",
      paragraph3: "Please include any benefit income when guaranteed for the duration of the lending. These include, but are not limited to:",
      list: [
        "Universal Credit (not including Jobseeker's Allowance)",
        "Working Tax Credit or Child Tax Credit",
        "any maintenance or child support payments",
        "benefits for those with a disability or caring for a dependant",
      ],
      paragraph4: "We may need to see evidence of these payments.",
    },
    errorBlank: "Enter your annual personal income",
    errorMax: "The maximum personal income you can enter is £999,999"
  },
  jointIncome: {
    label: "Joint annual income before tax",
    doorhangerCopy:
      "Please also provide details of your total annual joint income. Your total annual income is money you or your partner receive on a regular basis, for example salary, pension, investments or benefit payments, before tax and other contributions are deducted.",
    errorBlank: "Enter your annual joint income",
    errorMin:
      "Your annual joint income must be higher than your annual personal income",
    errorMax: "The maximum joint income you can enter is £999,999"
  },
  timeAtBank: {
    label: "When did you open your bank account?",
    hint: " We need this information for our credit checks.",
    errorBlank: "Tell us when you opened your bank account",
    errorInvalid:
      "Enter the date you opened your bank account in the format MM YYYY",
    errorMin:
      "You've entered a date that's before your birth date.  Check and re-enter",
    errorMax: "The date you entered is in the future"
  },
  affordability: {
    labelRadio:
      "Do you foresee any changes in your circumstances that may increase your outgoings or reduce your disposable income that would affect your ability to repay the borrowing you are applying for?",
    errorBlank: "Select whether you foresee any changes in your circumstances",
    doorhangerTitle: "Examples of change in circumstances",
    doorhangerExample1:
      "An increase in living costs such as a rent or mortgage repayments.",
    doorhangerExample2:
      "Changes in employment situation such as imminent retirement, maternity, paternity or extended leave.",
    doorhangerExample3:
      "A reduction in working hours, such as end of contract or zero hours contract."
  },
  taxResidency: {
    sectionTitle: "Tax residency",
    expanderTitle: "Why do we need tax resident info?",
    expanderContentPara1:
      'National laws based on the requirements of the OECD Common Reporting Standard ("CRS") require Financial Institutions to collect information about an account holder\'s tax residence. This may be reported to the national tax authority if the account holder is tax resident outside the jurisdiction where they hold their account.',
    expanderContentPara2:
      "Under international agreements to exchange account information, the national tax authority may transfer this information to the tax authorities of other jurisdictions in which the account holder may be tax resident.",
    expanderContentPara3:
      "Each jurisdiction has its own rules for defining tax residence, but in general, tax residence is the jurisdiction in which you live. Special circumstances (such as studying abroad, working overseas, or extended travel) may cause you to be resident elsewhere, or resident in more than one jurisdiction at the same time (dual residency).",
    expanderContentPara4:
      "The jurisdiction/jurisdictions in which you pay income tax are therefore likely to be your jurisdiction/jurisdictions of tax residence. For more information about tax residency, please ask your tax adviser or visit the ",
    expanderLinkTitle: "OECD Automatic Exchange portal",
    expanderLinkHref: "https://www.oecd.org/tax/automatic-exchange",
    label: "Where are you a resident for tax purposes?",
    hint: "Your jurisdiction of tax residence.",
    ukAndOtherCountriesCopy:
      "Please select any other countries where you are tax resident",
    nonUkCopy: "Please select any countries where you are tax resident",
    labelAdditional: "Country",
    placeholder: "Please select",
    errorBlank: "Tell us where you are resident for tax purposes",
    errorSelection: "Select any countries where you are tax resident"
  }
};
