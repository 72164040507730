import {
  yesNo,
  timeAt,
  trimmedCountryName,
  additionalTaxCountries
} from "../transformations";
export default values => {
  const jointIncome = values["joint-income"];
  const soleIncome = values["personal-income"];
  const ukResident =
    values["tax-residency-type"] === "U" ||
    values["tax-residency-type"] === "O";
  const primaryTax = ukResident ? "GB" : values["tax-residencies"][0];
  const additional = values["tax-residencies"];
  if (!ukResident) {
    additional.shift();
  }

  return {
    income: jointIncome ? jointIncome : soleIncome,
    applicantSalary: soleIncome,
    sustainabilityYN: yesNo(values.affordability),
    whenBankAccountOpened: timeAt(values["time-at-bank"]),
    resCountryForTax: trimmedCountryName(primaryTax),
    resCntryCodeForTax: primaryTax,
    additionalTaxResidencies: additionalTaxCountries(additional)
  };
};
