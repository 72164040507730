export const TRACK_PERSONAL_DETAILS = {
  funnel_step: 1,
  funnel_step_name: "personal details",
  page_name: "pws:application:credit cards:personal details",
  page_url: "/application/credit-cards/personal-details",
  event_category: "application",
  event_action: "start"
};
export const TRACK_DIVA = {
  funnel_step: "",
  funnel_step_name: "verify identity",
  page_name: "pws:application:credit cards:verify your identity",
  page_url: "/application/credit-cards/verify-your-identity",
  page_type: "verification",
  event_category: "",
  event_action: "",
  event_content: "",
  error_type: ""
};

export const TRACK_DIVA_SKIPPED = {
  funnel_step: "",
  funnel_step_name: "unable to verify id",
  page_name: "pws:application:credit cards:unable to verify id",
  page_url: "/application/credit-cards/unable-to-verify-id",
  page_type: "verification",
  event_category: "",
  event_action: "",
  event_content: "",
  error_type: ""
};

export const TRACK_DIVA_ERROR = {
  funnel_step: "",
  funnel_step_name: "verification error",
  page_name: "pws:application:credit cards:verification error",
  page_url: "/application/credit-cards/verification-error",
  page_type: "verification",
  event_category: "error",
  event_action: "verification",
  event_content: "verification error",
  error_type: "verification"
};
export const TRACK_CAMERA_ACCESS_DENIED = {
  funnel_step: "",
  funnel_step_name: "camera access denied",
  page_name: "pws:application:credit cards:camera access denied",
  page_url: "/application/credit-cards/camera-access-denied",
  page_type: "verification",
  event_category: "error",
  event_action: "system",
  event_content: "camera access denied",
  error_type: "system "
};

export const TRACK_CONTACT_DETAILS = {
  funnel_step: 2,
  funnel_step_name: "contact details",
  page_name: "pws:application:credit cards:contact details",
  page_url: "/application/credit-cards/contact-details",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_EMPLOYMENT_DETAILS = {
  funnel_step: 3,
  funnel_step_name: "employment details",
  page_name: "pws:application:credit cards:employment details",
  page_url: "/application/credit-cards/employment-details",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_FINANCIAL_DETAILS = {
  funnel_step: 4,
  funnel_step_name: "financial details",
  page_name: "pws:application:credit cards:financial details",
  page_url: "/application/credit-cards/financial-details",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_CARD_SETUP = {
  funnel_step: 5,
  funnel_step_name: "card setup",
  page_name: "pws:application:credit cards:card setup",
  page_url: "/application/credit-cards/card-setup",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_REVIEW_PAGE = {
  funnel_step: 6,
  funnel_step_name: "review",
  page_name: "pws:application:credit cards:review",
  page_url: "/application/credit-cards/review",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_PROCESSING_PAGE = {
  funnel_step: 7,
  funnel_step_name: "processing",
  page_name: "pws:application:credit cards:processing",
  page_url: "/application/credit-cards/processing",
  event_category: "application",
  event_action: "submit"
};

export const TRACK_ACCEPT_PAGE = {
  funnel_step: 8,
  funnel_step_name: "accept offer",
  page_name: "pws:application:credit cards:accept offer",
  page_url: "/application/credit-cards/accept-offer",
  event_category: "application",
  event_action: "submit",
  event_content: "accepted"
};

export const TRACK_IMPORTANT_INFORMATION_PAGE = {
  funnel_step: 9,
  funnel_step_name: "important information",
  page_name: "pws:application:credit cards:important information",
  page_url: "/application/credit-cards/important-information",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_COMPLETE_PAGE = {
  funnel_step: 10,
  funnel_step_name: "complete",
  page_name: "pws:application:credit cards:complete",
  page_url: "/application/credit-cards/complete",
  event_category: "application",
  event_action: "submit",
  event_content: "completed"
};

export const TRACK_DECLINE_PAGE = {
  funnel_step: 8,
  funnel_step_name: "decline",
  page_name: "pws:application:credit cards:decline",
  page_url: "/application/credit-cards/decline",
  event_category: "application",
  event_action: "submit",
  event_content: "declined"
};

export const TRACK_REFER_PAGE = {
  funnel_step: 8,
  funnel_step_name: "refer - id&v",
  page_name: "pws:application:credit cards:refer:id&v",
  page_url: "/application/credit-cards/refer/id&v",
  event_category: "application",
  event_action: "submit",
  event_content: "referred"
};

export const TRACK_APPLICATION_COMPLETE = {
  funnel_step: 8,
  event_category: "application",
  event_action: "submit",
  event_content: "referred"
};

export const TRACK_APPLICATION_TIMEOUT = {
  funnel_step: "",
  funnel_step_name: "application timeout",
  page_name: "pws:application:credit cards:application timeout",
  page_url: "/application/credit-cards/application-timeout",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_TECHNICAL_ERROR = {
  funnel_step: "",
  funnel_step_name: "technical error",
  page_name: "pws:application:credit cards:technical error",
  page_url: "/application/credit-cards/technical-error",
  event_category: "error",
  event_subcategory: "error_code",
  event_action: "system",
  event_content: "technical error"
};

export const TRACK_CALLUS_ERROR = {
  funnel_step: "",
  funnel_step_name: "agreement error - call us",
  page_name: "pws:application:credit cards:agreement error:call us",
  page_url: "/application/credit-cards/agreement-error/call-us",
  event_category: "error",
  event_subcategory: "error_code",
  event_action: "application",
  event_content: "agreement error - call us"
};

export const TRACK_CALLBACK_ERROR = {
  funnel_step: "",
  funnel_step_name: "agreement error - callback",
  page_name: "pws:application:credit cards:agreement error:callback",
  page_url: "/application/credit-cards/agreement-error/callback",
  event_category: "error",
  event_subcategory: "error_code",
  event_action: "application",
  event_content: "agreement error - callback"
};
