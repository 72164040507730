export default {
  descriptiveText: "Please verify your account details",
  accountHolderName: {
    label: "Account holder name(s)"
  },
  directDebit: {
    sectionTitle: "Direct Debit repayments",
    labelRadio: "Would you like to set up Direct Debit repayments?",
    errorBlank: "Tell us if you would like to set up a Direct Debit",
    sortCode: {
      label: "Sort code",
      errorBlank: "Enter a valid sort code",
      errorInvalid: "Enter a valid sort code"
    },
    accountNumber: {
      label: "Account number",
      errorBlank: "Enter a valid account number"
    },
    errorFailed:
      "We're sorry, we are unable to verify your account details. You can set up a Direct Debit via Internet Banking once your account is open.",

    BDVCallError: {
      errorInvalid:
        "We're sorry, we are unable to verify your account details. Please try again.",
      errorFailed:
        "We're sorry, we are unable to verify your account details. You can set up a Direct Debit via Internet Banking once your account is open."
    }
  },
  detailsVerified: "Account details verified",
  detailsNotVerified: "Verify your bank details before continuing",
  detailsInvalid: "Enter a valid sort code and account number",
  directDebitGuarantee: {
    sectionTitle: "Direct Debit Guarantee",
    sectionIntro: "The following Direct Debit guarantee applies to customers opting to make credit card repayments by Direct Debit.",
    criteria1: "The Guarantee is offered by all banks and building societies that accept instruction to pay Direct Debits.",
    criteria2: "If there are any changes to the amount, date or frequency of your Direct Debit M&S Bank will notify you (normally 10 working days) in advance of your account being debited or as otherwise agreed. If you request M&S Bank to collect a payment, confirmation of the amount and date will be given to you at the time of the request.",
    criteria3: "If an error is made in the payment of your Direct Debit, by M&S Bank or your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society. If you receive a refund you are not entitled to, you must pay it back when M&S Bank asks you to.",
    criteria4: "You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify M&S Bank.",
  }

};
