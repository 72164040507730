import React from "react";
import { Navigation, Field, Checkbox } from "@piggybank/form";
import content from "../../i18n/content/acceptFooter";
import "./AcceptFooter.css";
import { isIE } from "../../shared/browser";

const AcceptFooter = props => {
  const { visible, values } = props;

  return (
    visible && (
      <Navigation
        sticky
        textMap={{ submit: content.cta }}
        disablePrevious={true}
        isLastPage={true}
        currentPage={1}
        onPrevious={() => {}}
        id={!isIE || props.stickyIE ? null : "unstick"}
        data-bdd="agreement-accept"
      >
        <Field name="agree" marginBottom={0}>
          <Checkbox checked={values.agree}>{content.label}</Checkbox>
        </Field>
      </Navigation>
    )
  );
};

export default AcceptFooter;
