import React from "react";
import { Grid, Row, Cell, Heading, Image, Paragraph } from "@piggybank/core";
import "./CardsMastHead.css";

const CardMastHead = props => {
  return (
    <div
      id="cardMastHead"
      className="cardMastHead"
      style={{ display: "block" }}
    >
      <Grid>
        <Row>
          <Cell offsetMedium={1} sizeMedium={10}>
            <div className="cardMastHead__inner">
              <div className="cardMastHead__image">
                <Image src={props.cardImage} alt="" role="presentation" marginBottom={0} />
              </div>

              <div className="cardMastHead__description">
                <Paragraph id="cardMastHead__intro" marginBottom={0}>
                  {props.content.pageIntro}
                </Paragraph>
                <Heading id="cardMastHead__heading" level={3} marginBottom={0}>
                  {props.content.pageSubheading} {props.cardTypeName}{" "}
                  {props.content.pageSubheadingCardOffer}
                </Heading>
              </div>
            </div>
          </Cell>
        </Row>
      </Grid>
    </div>
  );
};

export default CardMastHead;
