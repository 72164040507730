export default {
  pageTitle: "Review",
  personalDetails: {
    heading: "Personal details",
    subSectionOne: "About you",
    subSectionTwo: "Nationality and residency",
    nameLabel: "Name",
    previousNamesLabel: "Previous name(s)"
  },
  contactDetails: {
    heading: "Contact details",
    phoneNumbers: "Phone number",
    currentAddress: "Current residential address",
    previousAddress: "Previous address",
    previousAddresses: "Previous addresses",
    addressLabel: "Address"
  },
  emailConfirmation: {
    title: "Email confirmation",
    label: "I confirm my email address is: ",
    hint:
      "We need a correct email address to make sure that you receive information about your application.",
    errorBlank: "Confirm your email address is correct",
    hiddenCopy: "Email address"
  },
  financialDetails: {
    heading: "Financial Details",
    button: "Edit",
    subSectionOne: "Income",
    subSectionTwo: "Tax residency"
  },
  craFraudHeading: "What happens next",
  cra: {
    heading: "Credit Reference Agencies (CRAs) Information",
    paragraph1:
      "We will search and share your information with CRAs. This will be used together with existing and new information about you to process your application, verify your identity and assess your suitability for an account. A record of our search will be held with the CRAs. If you make several applications for credit within a short period of time, this may temporarily affect your ability to obtain credit. We will continue to share information with the CRAs on the conduct of your account.",
    paragraph2:
      "We make decisions by automated means, including for fraud prevention. We use credit scoring to assess applications and may decline it if you don’t meet our criteria.  You have a right to ask us for a review e.g. if we decline your application. To ensure that our decisions are fair, informed and unbiased we regularly test our methods.",
    paragraph3:
      "We will link your record with any previous names and any joint applicant. We may also search their credit file.  You should tell them about this before you apply."
  },
  fraud: {
    heading: "Fraud Prevention Information",
    paragraph1:
      "The personal information we have collected from you will be shared with fraud prevention agencies who will use it to prevent fraud and money-laundering and to verify your identity. If fraud is detected, you could be refused certain services, finance or employment.",
    paragraph2Pre:
      "For more information on credit scoring, and how we, CRAs and fraud prevention agencies may use your information for these purposes and your data privacy rights, is set out in a leaflet entitled ",
    paragraph2Post: " available on our website, or we can post you a brochure.",
    link: "Credit Scoring, Credit Reference and Fraud Prevention Agencies",
    url: "https://bank.marksandspencer.com/pdf/CreditScoring.pdf"
  },
  emailNotification: {
    paragraph: "By selecting 'Submit', I agree that M&S Bank may send me documents relating to this Credit Card application, including my Credit Card Agreement, to the email address I have provided.",
  },
  editButton: "Edit"
};
