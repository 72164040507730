import React from "react";
import {
  Heading,
  Paragraph,
  Loading as PiggybankLoading,
  LogoMsGlobal
} from "@piggybank/core";

const Loading = ({ show = true, showHeading = true }) => (
  <PiggybankLoading
    show={show}
    getApplicationNode={() => document.getElementById("root")}
    logoSlot={<LogoMsGlobal />}
  >
    {showHeading && <Heading level={3}>Processing your application</Heading>}
    <Paragraph marginBottom={0} aria-live={showHeading ? null : "assertive"}>
      Please don&#39;t refresh your browser or use the back button
    </Paragraph>
  </PiggybankLoading>
);

export default Loading;
