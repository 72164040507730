import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Section,
  Heading,
  Paragraph,
  Link,
  HorizontalRule,
  VisuallyHidden,
  IconEdit
} from "@piggybank/core";
import {
  Form,
  Navigation,
  Checkbox,
  Field,
  FieldFeedback,
  FormFeedback
} from "@piggybank/form";
import content from "../../i18n/content/review";
import { AppConsumer } from "../../shared/appContext";
import { reviewSchema } from "../../shared/validation-schema.js";
import { link, view } from "../../shared/analytics/tealium/events";
import { TRACK_REVIEW_PAGE } from "../../shared/analytics/pageData";
import Timer from "../../components/Timer";
import { utagGlobal, validationErrors } from "../../shared/analytics/tealium";
import "./Review.css";
import ReviewPersonalDetails from "../../organisms/ReviewPersonalDetails";
import ReviewContactDetails from "../../organisms/ReviewContactDetails";
import ReviewEmploymentDetails from "../../organisms/ReviewEmploymentDetails";
import ReviewSetUpPreferences from "../../organisms/ReviewSetUpPreferences";
import ReviewFinancialDetails from "../../organisms/ReviewFinancialDetails";
import FormWrapper from "../../organisms/FormWrapper";
import formatTitle from "../../shared/transformations/formatTitle";
// const initialValues = {};

const eventTaggingConstants = {
  page_name: TRACK_REVIEW_PAGE.page_name,
  page_url: TRACK_REVIEW_PAGE.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_REVIEW_PAGE.funnel_step_name
};
class Review extends Component {
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_REVIEW_PAGE));
    document.title = formatTitle(content.pageTitle);
  }

  render() {
    const { navigateTo } = this.props;
    return (
      <FormWrapper>
        <div className="review">
          <Section marginBottom={7}>
            <Heading level={1}>Review</Heading>
            <Paragraph lead>
              Please take a moment to check that everything is correct. You can
              edit anything that is not right.
            </Paragraph>
            <HorizontalRule marginBottom={7} />
          </Section>
          <Form
            initialValues={{ "email-confirm": false }}
            validationSchema={reviewSchema}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
          >
            {({ values }) => (
              <>
                <FormFeedback />
                <ReviewPersonalDetails
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "personal details: edit"
                    });
                    navigateTo(0);
                  }}
                />
                <ReviewContactDetails
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "contact details: edit"
                    });
                    this.context.divaInScope ? navigateTo(2) : navigateTo(1);
                  }}
                />
                <ReviewEmploymentDetails
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "employment details: edit"
                    });
                    this.context.divaInScope ? navigateTo(3) : navigateTo(2);
                  }}
                />
                <ReviewFinancialDetails
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "financial details: edit"
                    });
                    this.context.divaInScope ? navigateTo(4) : navigateTo(3);
                  }}
                />
                <ReviewSetUpPreferences
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "card setup details: edit"
                    });
                    this.context.divaInScope ? navigateTo(5) : navigateTo(4);
                  }}
                />
                <div className="heading">
                  <div className="heading heading--header">
                    <Heading
                      marginBottom={3}
                      level={2}
                      data-bdd="email-confirm-header"
                    >
                      {content.emailConfirmation.title}
                    </Heading>
                  </div>
                  <div className="heading heading--editlink">
                    <Link
                      strong
                      data-bdd="email-confirm-edit"
                      id="edit-email"
                      onClick={() => {
                        this.context.divaInScope
                          ? navigateTo(2)
                          : navigateTo(1);
                      }}
                      elementType="button"
                      type="button"
                      buttonPadding
                      iconSlotRight={<IconEdit inline />}
                    >
                      {content.editButton}
                      <VisuallyHidden>
                        {content.emailConfirmation.hiddenCopy}
                      </VisuallyHidden>
                    </Link>
                  </div>
                </div>
                <HorizontalRule />
                {content.emailConfirmation.hint}
                <Section data-bdd="email-confirm">
                  <Field name="email-confirm" data-bdd="email-confirm">
                    <Checkbox>
                      {content.emailConfirmation.label}
                      <span style={{ wordBreak: "break-word" }}>
                        {values.email}
                      </span>
                    </Checkbox>
                    <FieldFeedback />
                  </Field>
                </Section>
                <Section data-bdd="cra-fraud-prevention-copy">
                  <Heading level={2}>{content.craFraudHeading}</Heading>
                  <Heading
                    level={4}
                    marginBottom={3}
                    role="heading"
                    aria-level="3"
                  >
                    {content.cra.heading}
                  </Heading>
                  <Paragraph marginBottom={3}>
                    {content.cra.paragraph1}
                  </Paragraph>
                  <Paragraph marginBottom={3}>
                    {content.cra.paragraph2}
                  </Paragraph>
                  <Paragraph>{content.cra.paragraph3}</Paragraph>
                  <Heading
                    level={4}
                    marginBottom={3}
                    role="heading"
                    aria-level="3"
                  >
                    {content.fraud.heading}
                  </Heading>
                  <Paragraph marginBottom={3}>
                    {content.fraud.paragraph1}
                  </Paragraph>
                  <Paragraph marginBottom={3}>
                    {content.fraud.paragraph2Pre}
                    <Link external href={content.fraud.url}>
                      {content.fraud.link}
                    </Link>
                    {content.fraud.paragraph2Post}
                  </Paragraph>
                  <Paragraph marginBottom={6}>
                    {content.emailNotification.paragraph}
                  </Paragraph>
                </Section>

                <HorizontalRule />
                <Section>
                  <Navigation id="next" />
                </Section>
              </>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormWrapper>
    );
  }
}

Review.propTypes = {
  values: PropTypes.object,
  navigateTo: PropTypes.func
};

Review.displayName = "Review";
Review.contextType = AppConsumer;
export default Review;
