export default {
  pageIntro:
    "We're aware of this issue and are looking into it. Please try again later.",
  pageSubheading: "What you can do now",
  pageCopy: "If you continue to have problems please",
  link: {
    copy: "contact us",
    url: "https://bank.marksandspencer.com/explore/contact-us/"
  },
  buttonLabel: "Start again"
};
