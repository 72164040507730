import * as yup from "yup";
import {
  isBeforeDateOfBirth,
  isAddressOneOf,
  requiredForUKAddress,
  requiredForAddress2to5,
  requiredAfterCountry,
  isAddressSequenceValid,
  areMoreAddressesRequired,
  isGreaterThanPersonalIncome,
  isPersonalIncomeLessThanMaxValue,
  isJointIncomeLessThanMaxValue,
  isJointIncomeNotBlank,
  isNotCiiomPostcode,
  initiateDiva
} from "./custom-validations.js";

yup.addMethod(yup.bool, "initiateDiva", initiateDiva);
yup.addMethod(yup.date, "isBeforeDateOfBirth", isBeforeDateOfBirth);
yup.addMethod(yup.string, "isAddressOneOf", isAddressOneOf);
yup.addMethod(yup.string, "requiredForUKAddress", requiredForUKAddress);
yup.addMethod(yup.string, "requiredForAddress2to5", requiredForAddress2to5);
yup.addMethod(yup.string, "requiredAfterCountry", requiredAfterCountry);
yup.addMethod(yup.date, "isAddressSequenceValid", isAddressSequenceValid);
yup.addMethod(yup.date, "areMoreAddressesRequired", areMoreAddressesRequired);
yup.addMethod(
  yup.string,
  "isGreaterThanPersonalIncome",
  isGreaterThanPersonalIncome
);
yup.addMethod(
  yup.string,
  "isPersonalIncomeLessThanMaxValue",
  isPersonalIncomeLessThanMaxValue
);
yup.addMethod(
  yup.string,
  "isJointIncomeLessThanMaxValue",
  isJointIncomeLessThanMaxValue
);
yup.addMethod(yup.string, "isJointIncomeNotBlank", isJointIncomeNotBlank);
yup.addMethod(yup.string, "isNotCiiomPostcode", isNotCiiomPostcode);

export default yup;
