import capitalize from 'lodash/capitalize'
import { GenderEnum } from '../../constants';

const GenderMapping = Object.freeze({
  Mr: GenderEnum.Male,
  Mrs: GenderEnum.Female,
  Miss: GenderEnum.Female,
  Ms: GenderEnum.Female,
  Dr: GenderEnum.Unknown,
  Lady: GenderEnum.Female,
  Lord: GenderEnum.Male,
  Rev: GenderEnum.Unknown
});

export default title => GenderMapping[capitalize(title)];
