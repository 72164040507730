export const employmentStatus = [
  { value: "FULL", label: "Employed full-time" },
  { value: "PART", label: "Employed part-time" },
  { value: "SELF", label: "Self-employed" },
  { value: "HOME", label: "Homemaker" },
  { value: "PENSION", label: "Receiving pension" },
  { value: "DISABILITY", label: "Receiving disability benefit" },
  { value: "STUDENT", label: "Student" },
  { value: "UNEMPLOYED", label: "Unemployed" }
];
