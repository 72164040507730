export default [
  {
    value: "U",
    label: "UK only"
  },
  {
    value: "O",
    label: "UK and other jurisdictions"
  },
  {
    value: "N",
    label: "Non UK jurisdictions"
  }
];
