import React, { Component } from "react";
import PropTypes from "prop-types";
import { Paragraph, Heading, Section } from "@piggybank/core";
import { Navigation } from "@piggybank/form";
import content from "../../../i18n/content/divaReturned.js";

class DivaReturned extends Component {
  componentDidMount() {
    document.title = `${content.pageTitle}`;
    this.setFocus("page-title");
  }

  setFocus = id => {
    if (document.getElementById(id) != null) {
      document.getElementById(id).focus();
    }
  };

  render() {
    return (
      <Section id="diva-returned-bio">
        <Heading level={1} data-bdd="page-title" id="page-title" tabIndex={-1}>
          {content.pageTitle}
        </Heading>

        <Paragraph data-bdd="paragraph-one">{content.paragraphOne}</Paragraph>

        <Navigation
          textMap={{ previous: content.backText, next: content.ctaText }}
          isLastPage={false}
          currentPage={1}
          onPrevious={(next, rest) => {
            next(rest);
          }}
          id="cta-button"
          data-bdd="cta-button"
        />
      </Section>
    );
  }
}

DivaReturned.propTypes = {
  values: PropTypes.object
};

export default DivaReturned;
