import React, { Component } from "react";
import { Paragraph, Link, IconNewWindow } from "@piggybank/core";
import { isiOS, isOSX, isSafari } from "../../shared/browser";
import "./DocumentLink.css";

class DocumentLink extends Component {
  state = {
    filename: "full-terms-and-conditions.pdf",
    fileIsRead: false,
    pdfError: false,
    hideDownloadHref: true,
    size: "0"
  };

  componentDidMount() {
    const { pdf } = this.props;
    this.reader = new FileReader();
    pdf ? this.setState({ size: pdf.size }) : this.setState({ pdfError: true });
  }

  handleClick = e => {
    e.preventDefault();
    this.state.pdfError ? this.handleRedirect() : this.handleDownload();
  };

  handleRedirect = () => {
    this.directToError.click();
  };

  setHideDownloadHref = state => {
    this.setState({
      hideDownloadHref: state
    });
  };

  handleDownload = () => {
    this.setHideDownloadHref(false);
    const file = new Blob([this.props.pdf], { type: "application/pdf" });

    // IE11 and Edge 17
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(file, this.state.filename);

      // Every other browser
    } else {
      this.reader.readAsDataURL(file);
      this.reader.onloadend = () => {
        this.reader.result.replace(
          /application\/pdf;/,
          "application/pdf; charset=utf-8;"
        );
        this.setState(
          {
            fileIsRead: true
          },
          () => {
            this.hiddenLink.click();
          }
        );
      };
    }
  };

  handleHiddenLinkClick = e => {
    this.setHideDownloadHref(true);
  };

  render() {
    return (
      <Paragraph>
        {/* 
          This is the link that the user will see and click.
          It must have an href="" attribute to be a valid link, but it actually is not using it.
        */}
        <Link
          external
          href={this.state.filename}
          iconSlotRight={<IconNewWindow inline />}
          onClick={this.handleClick}
        >
          Download and print your documents (PDF,
          {Math.round(this.state.size / 1000)}KB)
        </Link>

        {/* 
          This link will be clicked programatically
          to trigger the correct response 
          depending on the browser' supported functionality 
        */}
        <Link
          className="hidden"
          external
          href={
            this.state.hideDownloadHref === true
              ? ""
              : this.state.fileIsRead
              ? this.reader.result
              : ""
          }
          download={isSafari && (isiOS || isOSX) ? false : this.state.filename}
          onClick={this.handleHiddenLinkClick}
          ref={hiddenLink => (this.hiddenLink = hiddenLink)}
        ></Link>

        <Link
          className="hidden"
          external
          href={"/#/error-pdf"}
          ref={directToError => (this.directToError = directToError)}
        ></Link>
      </Paragraph>
    );
  }
}

export default DocumentLink;
