import React, { Component } from "react";
import { AppConsumer } from "../../shared/appContext";
import Timer from "../../components/Timer";
import { withContext, FormLayout } from "@piggybank/core";
import { WizardContext } from "@piggybank/form";
import FormWrapper from "../FormWrapper";
import Loading from "../../components/Loading";
import Headers from "../../shared/headers";

import DivaIntro from "./DivaIntro";
import DivaError from "./DivaError";
import DivaSkip from "./DivaSkip";
import DivaReturned from "./DivaReturned";
import "./index.css";

import { callApi } from "../../shared/submitApplication";

import { link, view } from "../../shared/analytics/tealium/events";
import {
  TRACK_DIVA,
  TRACK_CAMERA_ACCESS_DENIED
} from "../../shared/analytics/pageData";
import { utagGlobal } from "../../shared/analytics/tealium";

// diva-sdk controlled
import divaHandlers from "diva-web-sdk/dist/main.min";
require("diva-web-sdk/dist/style.css");

/*
    Custom journey text
    Will overwrite the base diva text
 */
const localOptions = require("./customEN");

const eventTaggingConstants = {
  page_name: TRACK_DIVA.page_name,
  page_url: TRACK_DIVA.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_DIVA.funnel_step_name
};

export class DivaApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      divaInitPending: false,
      divaInitError: false,
      divaIntroContinue: false,
      divaLoaded: false,
      timerFinished: false,
      step: "intro",
      awsRegion: process.env.REACT_APP_DIVA_AWS_REGION,
      divaUrl: process.env.REACT_APP_DIVA_URL,
      theme: process.env.REACT_APP_DIVA_THEME
    };
    this.handleClickContinue = this.handleClickContinue.bind(this);
    this.mountDiva = this.mountDiva.bind(this);
    this.unmountDiva = this.unmountDiva.bind(this);
  }
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_DIVA));
    if (
      this.context.divaSkipped === true ||
      this.context.divaCompleted === true
    ) {
      this.setState({ step: "returned" });
    } else {
      return this.callDivaInit();
    }
  }

  callDivaInit = () => {
    const { values } = this.props;
    const custData = {
      applicationId: this.context.sessionId,
      firstName: values.forename,
      lastName: values.surname,
      dateOfBirth: values["date-of-birth"]
    };

    return this.divaInit(custData);
  };

  divaInit = async custData => {
    this.setState({ divaInitPending: true });

    const response = await callApi(
      process.env.REACT_APP_DIVA_INIT,
      JSON.stringify(custData),
      Headers
    );

    if (response.success) {
      this.context.divaXHsbcState = response.result.headers["x-hsbc-state"];
      this.context.divaTransactionId = response.result.data.transactionId;
      this.setState({
        ...response.result.data,
        divaInitPending: false
      });

      if (this.state.divaIntroContinue && this.state.step !== "diva") {
        this.showDiva();
      }
    } else {
      this.setState({
        divaInitPending: false,
        divaInitError: true
      });

      if (this.state.divaIntroContinue) {
        this.context.divaSkipped = true;
        this.setState({ loading: false, step: "error" });
      }
    }
  };

  // Current list of call backs that the diva-sdk will require
  // unmount the diva-sdk and render the skip component
  divaSkip = skipCode => {
    const { values } = this.props;
    const { currentPage, navigateTo, onNext } = this.props.wizardContext;
    const goToContactDetails = ["diva_skip_002"];
    this.context.divaSkipped = true;

    // Go straight to Contact Details:
    // - if the camera access is denied
    if (goToContactDetails.includes(skipCode)) {
      link(Object.assign(eventTaggingConstants, TRACK_CAMERA_ACCESS_DENIED));
      this.unmountDiva(divaHandlers);
      onNext(values);
      navigateTo(currentPage + 1, values);

      // Go to the skip page:
      // - for any other skip code
    } else {
      this.unmountDiva(divaHandlers, "skip");
    }
  };

  // render the exit component
  divaExit = () => {
    const { values } = this.props;
    const { navigateTo, currentPage, onNext } = this.props.wizardContext;
    this.context.divaCompleted = true;
    this.unmountDiva(divaHandlers);
    onNext(values);
    navigateTo(currentPage + 1, values);
  };

  // unmount the diva-sdk and render the error component
  divaError = errorCode => {
    this.context.divaSkipped = true;
    this.unmountDiva(divaHandlers, "error");
  };

  // unmount the diva-sdk and render the intro component
  // The Back call back is used so that the diva-sdk knows what to do when the back button is pressed
  // on the first diva controlled page, which is the document selection
  divaBack = () => this.unmountDiva(divaHandlers, "intro");

  componentDidUpdate() {
    if (this.state.divaLoaded && this.state.timerFinished) {
      this.setState({
        loading: false,
        divaLoaded: false,
        timerFinished: false
      });
    }
  }

  mountDiva() {
    this.setState({ loading: true });
    return divaHandlers
      .giveMeDiva(this.state, localOptions, {
        divaJourneyComplete: this.divaExit,
        divaErrorFunction: this.divaError,
        divaBackFunction: this.divaBack,
        divaSkipFunction: this.divaSkip,
        divaNotSupported: this.divaError
      })
      .then(() => {
        this.setState({ divaLoaded: true });
      });
  }

  unmountDiva(divaHandlers, step) {
    divaHandlers.removeDiva();
    if (step) {
      this.setState({ step: step });
    }
  }

  showDiva = async () => {
    this.setState({ step: "diva" });
    await this.mountDiva();
  };

  handleClickContinue() {
    if (!this.state.divaInitPending && this.state.divaInitError) {
      this.context.divaSkipped = true;
      this.setState({ step: "error" });
    } else {
      setTimeout(() => {
        this.setState({ timerFinished: true });
      }, 2000);
      if (this.state.divaInitPending) {
        this.setState({
          loading: true,
          divaIntroContinue: true
        });
      } else {
        this.showDiva();
      }
    }
  }

  render() {
    return (
      <div id="diva-app-container">
        <FormWrapper>
          <FormLayout>
            {this.state.step === "intro" && (
              <DivaIntro callback={this.handleClickContinue} />
            )}
            {/* The DIVA App needs to be visiually hidden while
             the loading screen is showing, otherwise it takes the focus
             for screen readers away from loading screen */}
            {this.state.step === "diva" && (
              <div id="diva-app" className={this.state.loading && "hide"}></div>
            )}
            {this.state.step === "returned" && <DivaReturned />}
            {this.state.step === "skip" && <DivaSkip />}
            {this.state.step === "error" && <DivaError />}

            <Timer pageTags={eventTaggingConstants} />
          </FormLayout>
        </FormWrapper>
        <Loading show={this.state.loading} showHeading={false} />
      </div>
    );
  }
}

DivaApp.displayName = "DivaApp";
DivaApp.contextType = AppConsumer;

// grab wizard context and pass it to component
export default withContext(WizardContext, wizardContext => ({ wizardContext }))(
  DivaApp
);
