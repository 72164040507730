export default {
  pageTitle: "Employment details",
  pageIntro: "Next, we need to know your employment details.",
  employmentStatus: {
    heading: "Employment",
    label: "Employment status",
    error: "Select your employment status"
  },
  occupation: {
    label: "Occupation",
    error: "Select your occupation"
  },
  companyName: {
    label: "Company name",
    errorBlank: "Enter the company name",
    errorMin: "Your company name must have at least 2 characters",
    errorInvalid:
      "Enter your company name using only alphanumeric characters and basic punctuation"
  },
  employmentStartDate: {
    label: "Employment start date",
    hint: "For example 07 1998.",
    errorBlank: "Enter when you started with your current employer",
    errorMin: "The date you entered is before your date of birth",
    errorMax: "The date you entered is in the future",
    errorInvalidMonth: "The month you entered is invalid",
    errorInvalidFormat: "Enter your employment start date in the format MM YYYY"
  },
  atHome: {
    label: "Do you receive income from benefits payments?",
    errorBlank: "Tell us if you receive income from any benefits"
  },
  unemploymentBenefits: {
    label: "Do you receive income from benefits payments?",
    errorBlank: "Tell us if you receive income from any benefits"
  },
  courseEndDate: {
    label: "Course end date",
    errorBlank: "Tell us your course end date",
    errorInvalidFormat: "Enter your course end date in the format DD MM YYYY",
    errorMin: "The date you entered is in the past"
  }
};
