import React, { Component } from "react";
import { Fieldset, FieldFeedback, Legend, DateInput } from "@piggybank/form";

class EndDate extends Component {
  render() {
    const content = this.props.content;
    return (
      <>
        <Fieldset name="course-end-date" data-bdd="course-end-date">
          <Legend marginBottom={1}>{content.label}</Legend>
          <DateInput format="YYYY-MM-DD" />
          <FieldFeedback />
        </Fieldset>
      </>
    );
  }
}

export default EndDate;
