export default (code, codes) => {
  let status;
  for (var i = codes.length; i--; ) {
    if (codes[i].value === code) {
      status = codes[i].label;
      break;
    }
  }
  return status;
};
