import React, { Component } from "react";
import { AppConsumer } from "../../shared/appContext";

import {
  Callout,
  Heading,
  Paragraph,
  UnorderedList,
  ListItem,
  Section,
  PlaybackKey,
  PlaybackList,
  PlaybackValue,
  Reveal
} from "@piggybank/core";
import {
  FieldFeedback,
  Hint,
  Field,
  Label,
  Fieldset,
  NumberInput,
  Legend,
  Radio,
  YesNoRadio
} from "@piggybank/form";
import repaymentMethods from "../../i18n/lookups/repaymentMethods";
import "./Account.css";

import axios from "axios";
import FindAddressButton from "../../components/FindAddressButton";
import buttonContent from "../../i18n/content/validateBankDetails";
import bankDetailsContent from "../../i18n/content/bankDetails";
import BankDetailsErrors from "../../components/BankDetailsErrors";
import headers from "../../shared/headers";
import DirectDebitIcon from "./DirectDebit";

const prependZeros = (n, len) => {
  if (n.length >= 1) {
    return (new Array(len + 1).join("0") + n).slice(-len);
  } else return n;
};

// helper function?
// Pass in title, forename, surname to function return name
const concatenatedName = values => {
  let titleInput = values["title"];
  let title = titleInput.charAt(0).toUpperCase() + titleInput.slice(1) + " ";
  let forenameInput = values["forename"];
  let forename = forenameInput.split(" ")[0] + " ";
  let surname = values["surname"];
  let firstInitial = forename.slice(0, 1) + " ";
  let fullNameConcatenation = title + forename + surname;
  let firstInitialConcatenation = title + firstInitial + surname;
  let noInitialConcatenation = title + surname;
  if (fullNameConcatenation.length <= 30) {
    values["account-holders-name"] = fullNameConcatenation;
    return fullNameConcatenation;
  }
  if (fullNameConcatenation.length > 30) {
    if (firstInitialConcatenation.length <= 30) {
      values["account-holders-name"] = firstInitialConcatenation;
      return firstInitialConcatenation;
    } else {
      values["account-holders-name"] = noInitialConcatenation;
      return noInitialConcatenation;
    }
  }
};

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BDVValid: false,
      timerFinished: false,
      loading: false,
      BDVError: false,
      BDVReceived: false,
      BDVCallError: false
    };
    this.BVDCall = this.BVDCall.bind(this);
  }

  setFocus = values => {
    if (document.getElementById("accountNumber-field") != null) {
      document.getElementById("accountNumber-field").focus();
      document.getElementById("accountNumber-field").click();
    }
  };

  setDirectDebitInitialValue = values => {
    if (this.context.BDV > 2 && this.context.BDVVerified !== true) {
      values.directDebit = "no";
      return "no";
    }
    if (
      this.context.BDVVerified === "true" &&
      values.sortCode === "" &&
      values.accountNumber === ""
    ) {
      values.directDebit = "yes";
      values.bankDetailsCorrect = "Y";
      // is this needed if we say new journey new attempts?
      values.sortCode = this.context.verifiedSortCode;
      values.accountNumber = this.context.verifiedAccountNumber;
    }
  };

  bdvCounterCheck = values => {
    if (this.context.BDV >= 2) {
      this.context.BDV = parseInt(this.context.BDV) + 1;
      this.context.BDVVerified = false;
      values.directDebit = "no";
      values.repaymentMethod = "";
      values["account-holders-name"] = undefined;
      values.bankDetailsCorrect = "N";
      values.bankName = "";
      var directDebitElement = document.getElementById("directDebit");
      directDebitElement.scrollIntoView();
    }
  };

  resetBdvValues = values => {
    this.context.BDVVerified = false;
    values.bankDetailsCorrect = "N";
    values.bankName = "";
  };

  // helper function - time and callback as params ?
  timer = time => {
    setTimeout(() => {
      this.loaded();
    }, time);
  };

  loaded = () => {
    // why changing timerFinished state twice here?

    this.setState(() => ({
      timerFinished: !this.state.timerFinished
    }));

    this.state.timerFinished &&
      this.state.BDVReceived &&
      this.setState(() => ({
        // why three?
        loading: false,
        timerFinished: false,
        BDVReceived: false
      }));
  };

  clickHandler = async values => {
    // this is set in context and checked in error message to decide whch error message?
    // We also set it in state? Why?
    this.context.Continue = false;

    // if sort code valid/invalid set flag
    if (this.validation(values)) {
      this.context.BDV = parseInt(this.context.BDV) + 1;

      // better way to do this? We shoudl compare against values, no need for context. When do values update
      // we check in onblur on these fields
      this.context.verifiedSortCode = values.sortCode;
      this.context.verifiedAccountNumber = values.accountNumber;

      // set state for no validation errors
      this.setState(
        {
          BDVError: false
        },
        // call api
        () => this.BVDCall(values)
      );
    } else {
      // field validation fails
      // set continue for errors - why state and context
      this.context.Continue = true;
      this.bdvCounterCheck(values);
      //set state for errors - why state and context
      this.setState({
        BDVError: true
      });
    }
  };

  validation = values => {
    // validation check - simple
    return values.sortCode.length !== 6 || values.accountNumber.length !== 8
      ? false
      : true;
  };

  BVDCall = values => {
    // should be a helper function
    this.timer(2000);

    this.setState({ loading: true });

    axios({
      url: process.env.REACT_APP_BDV,
      method: "post",
      data: JSON.stringify({
        sortCode: values.sortCode,
        accountNumber: values.accountNumber
      }),
      headers: headers,

      validateStatus: status => {
        this.timerFinished = true;
        return status === 200;
      }
    })
      // response received
      .then(response => {
        this.setState(() => ({ BDVReceived: true }));
        // are they valid?
        response.data.bankDetailsValid === true
          ? this.BDVValid(response, values)
          : this.BDVInvalid(response, values);
      })
      // No response received
      .catch(() => {
        this.setState({ loading: false, BDVCallError: true });

        // are we incrementing twice here? Once hee and another in bdvCountercheck
        this.context.BDV = parseInt(this.context.BDV) + 1;
        this.bdvCounterCheck(values);
      })
      .then(() => {
        this.setState({ timerFinished: false });
        // loaded logic not right
        this.loaded();
        // call formick validateForm()
        this.props.validateForm();
      });
  };

  BDVValid = (response, values) => {
    values.bankDetailsCorrect = "Y";
    values.bankName = response.data.bankName;
    this.context.BDVVerified = true;
  };

  BDVInvalid = (response, values) => {
    this.setState({ BDVCallError: true });
    if (response.data.messageCode === "MSG000116") {
      this.context.BDV = parseInt(this.context.BDV) + 1;
    }
    this.bdvCounterCheck(values);
  };

  handleDirectDebitChange = values => {
    if (values.directDebit === "no") {
      values["repayment-account"] = "";
      values.repaymentMethod = "";
      this.context.verifiedSortCode = "";
      this.context.verifiedAccountNumber = "";
      this.resetBdvValues(values);
      values.sortCode = "";
      values.accountNumber = "";
    }
  };

  handleRepaymentAccount = values => {
    if (values["repayment-account"] === "no") {
      this.context.verifiedSortCode = "";
      this.context.verifiedAccountNumber = "";
      this.resetBdvValues(values);
      values.sortCode = "";
      values.accountNumber = "";
    }
  };
  render() {
    const { values, content } = this.props;

    return (
      <>
        <Section
          marginBottom={values.directDebit === "yes" ? 7 : 6}
          aria-live="assertive"
        >
          <Heading level={2} data-bdd="direct-debit-repayments">
            {content.directDebit.sectionTitle}
          </Heading>
          {
            // Direct debit component starts here
          }
          <Fieldset
            name="directDebit"
            data-bdd="directDebit"
            marginBottom={0}
            id="directDebit"
            selectedvalue={this.setDirectDebitInitialValue(values)}
            onChange={this.handleDirectDebitChange(values)}
          >
            <Legend marginBottom={1}>{content.directDebit.labelRadio}</Legend>
            <YesNoRadio
              disabled={
                this.context.BDV < 2 && !this.state.loading ? false : true
              }
            />
            <FieldFeedback marginTop={1} />
          </Fieldset>
          {
            // Direct debit component finishes here
          }
          <Reveal marginTop={2}>
            {// this can be simplified
            this.context.BDV >= 2 &&
              this.context.BDVVerified === false &&
              this.state.loading === false && (
                <Callout type="warning" aria-live="assertive" marginBottom={0}>
                  <Paragraph
                    marginBottom={0}
                    style={{
                      fontWeight: "bold",
                      marginTop: 0
                    }}
                    className="bdvValid"
                  >
                    {bankDetailsContent.directDebit.errorFailed}
                  </Paragraph>
                </Callout>
              )}
          </Reveal>
          <Reveal duration={400} accentBar={true} marginTop={2}>
            {values.directDebit === "yes" && (
              <>
                {
                  // repayment method component starts here
                }
                <Heading level={3} data-bdd="repayment-method-heading">
                  {content.repaymentMethod.sectionTitle}
                </Heading>
                <Section id="repayment-amount-section">
                  <Fieldset
                    name="repaymentMethod"
                    data-bdd="repaymentMethod"
                    marginBottom={4}
                  >
                    <Legend marginBottom={1}>
                      {content.repaymentMethod.label}
                    </Legend>
                    {repaymentMethods.map(rm => (
                      <Radio fullWidth value={rm.value} key={rm.value}>
                        {rm.label}
                        <Hint data-bdd={`repaymentMethod-${rm.value}-Hint`}>
                          {rm.hint}
                        </Hint>
                      </Radio>
                    ))}
                    <FieldFeedback marginTop={1} />
                  </Fieldset>

                  <Callout type="information" data-bdd="repaymentMethodCallout">
                    <Paragraph marginBottom={0}>
                      {content.repaymentMethod.info}
                    </Paragraph>
                  </Callout>
                </Section>
                {
                  // repayment method component ends here
                }

                <Heading level={3} data-bdd="repayment-account">
                  {content.repaymentAccount.sectionTitle}
                </Heading>
                <Section id="repayment-account-copy" marginBottom={4}>
                  <Paragraph marginBottom={2}>
                    {content.repaymentAccount.sectionIntro}
                  </Paragraph>
                  <UnorderedList>
                    <ListItem>{content.repaymentAccount.criteria1}</ListItem>
                    <ListItem>{content.repaymentAccount.criteria2}</ListItem>
                    <ListItem>{content.repaymentAccount.criteria3}</ListItem>
                  </UnorderedList>
                  <Fieldset
                    name="repayment-account"
                    data-bdd="repayment-account"
                    onChange={this.handleRepaymentAccount(values)}
                  >
                    <Legend>{content.repaymentAccount.labelRadio}</Legend>
                    <YesNoRadio />
                    <FieldFeedback />
                  </Fieldset>
                  {values["repayment-account"] === "no" && (
                    <Reveal>
                      <Callout type="warning" id="warning-callout">
                        {content.repaymentAccount.copy}
                      </Callout>
                    </Reveal>
                  )}
                </Section>
                {values["repayment-account"] === "yes" && (
                  <Reveal>
                    <Section marginBottom={0}>
                      <Paragraph>
                        {content.repaymentAccount.sectionCopy}
                      </Paragraph>
                      <PlaybackList marginBottom={5} id="accountHolderName">
                        <PlaybackKey>
                          {bankDetailsContent.accountHolderName.label}
                        </PlaybackKey>
                        <PlaybackValue
                          id="account-holder-name"
                          marginBottom={3}
                        >
                          {concatenatedName(values)}
                        </PlaybackValue>
                      </PlaybackList>

                      {
                        // BANK DETAILS VALIDATION
                      }
                      <Fieldset name="bank-details-correct">
                        <Field
                          name="sortCode"
                          data-bdd="sortCode"
                          onBlur={(next, rest) => {
                            if (
                              values.sortCode !== this.context.verifiedSortCode
                            ) {
                              // bug here if you reset values
                              // closes the panel no explaination. counts twice if change both sort and account -- see below
                              this.resetBdvValues(values);
                              this.bdvCounterCheck(values);
                            }
                            next(rest);
                          }}
                        >
                          <Label>
                            {bankDetailsContent.directDebit.sortCode.label}
                          </Label>
                          <NumberInput style={{ width: 190 }} maxLength={6} />
                          <FieldFeedback />
                        </Field>

                        <Field
                          debounceWait={10}
                          name="accountNumber"
                          data-bdd="accountNumber"
                          marginBottom={4}
                          onBlur={(next, rest) => {
                            values.accountNumber = prependZeros(
                              values.accountNumber,
                              8
                            );
                            if (
                              values.accountNumber !==
                              this.context.verifiedAccountNumber
                            ) {
                              // bug here if you reset values
                              // closes the panel no explaination. counts twice if change both sort and account - see above
                              this.resetBdvValues(values);
                              this.bdvCounterCheck(values);
                            }
                            next(rest);
                          }}
                        >
                          <Label>
                            {bankDetailsContent.directDebit.accountNumber.label}
                          </Label>
                          <NumberInput
                            style={{ width: 190 }}
                            maxLength={8}
                            allowLeadingZeros={true}
                          />
                          <FieldFeedback />
                        </Field>

                        {this.context.BDVVerified !== true && (
                          <Paragraph
                            marginBottom={5}
                            style={{ fontWeight: "bold" }}
                            data-bdd="descText"
                          >
                            {bankDetailsContent.descriptiveText}
                          </Paragraph>
                        )}

                        <div
                          className="validateButton"
                          style={{
                            display: "block",
                            minHeight: "50px",
                            marginLeft: "-20px",
                            // hack to make it look semi ok in meantime
                            marginBottom: "20px"
                          }}
                        >
                          <FindAddressButton
                            path={""}
                            content={buttonContent}
                            loading={this.state.loading}
                            disabled={
                              // can verify function
                              (!this.context.BDV < 2 && this.state.loading) ||
                              (this.context.BDVVerified === true &&
                                values.sortCode ===
                                  this.context.verifiedSortCode &&
                                values.accountNumber ===
                                  this.context.verifiedAccountNumber)
                                ? true
                                : false
                            }
                            clickHandler={e => {
                              e.stopPropagation();
                              this.clickHandler(values, "");
                              this.setFocus(values);
                            }}
                          />
                        </div>
                        <BankDetailsErrors
                          accountState={this.state}
                          values={values}
                        />
                      </Fieldset>
                      {
                        // END OF BANK DETAILS VALIDATION
                      }
                    </Section>
                    <Section marginBottom={0}>
                      <div id="directDebitIcon">
                        <DirectDebitIcon
                          value="directdebiticon"
                          icon={DirectDebitIcon}
                        />
                      </div>
                      <Heading
                        data-bdd="directDebitGuaranteeHeading"
                        level={4}
                        marginBottom={3}
                        role="heading"
                        aria-level="3"
                      >
                        {bankDetailsContent.directDebitGuarantee.sectionTitle}
                      </Heading>
                      <Paragraph
                        marginBottom={2}
                        data-bdd="directDebitGuarantee"
                      >
                        {bankDetailsContent.directDebitGuarantee.sectionIntro}
                      </Paragraph>
                      <UnorderedList id="directDebitGuarantee" marginBottom={0}>
                        <ListItem>
                          {bankDetailsContent.directDebitGuarantee.criteria1}
                        </ListItem>
                        <ListItem>
                          {bankDetailsContent.directDebitGuarantee.criteria2}
                        </ListItem>
                        <ListItem>
                          {bankDetailsContent.directDebitGuarantee.criteria3}
                        </ListItem>
                        <ListItem>
                          {bankDetailsContent.directDebitGuarantee.criteria4}
                        </ListItem>
                      </UnorderedList>
                    </Section>
                  </Reveal>
                )}
              </>
            )}
          </Reveal>
        </Section>
      </>
    );
  }
}

Account.contextType = AppConsumer;
export default Account;
