// This needs to be on component level, so that
// the event listeners can reference the same instance
export const handleBeforeunloadEvent = e => {
  // Cancel the default event
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = "";
};

/*
 * This function should be used with caution!
 * It overrides the default browser behaviour for handling history
 * by pushing an extra item in the history array, thus when the user
 * presses "Back" in the browser, they actually keep going back to the same page,
 * i.e. not going anywhere. This is potentially confusing for the user and there
 * are a few side effects.
 *
 * 1) You lose the ability to observe window.onpopstate anywhere else in the app
 * since it's defined here.
 *
 * 2) Using any in-app loading, React Router (potentially) will not have "Back" functionality
 * provided by the browser and as such it MUST be designed inside the app with state and re-rendering.
 * If not, it is very likely that the user will find themselves in a "dead-end" situation
 * without any options to navigate away.
 */

export const disableBackButton = () => {
  const { history, location } = window;

  history.pushState(null, null, location.href);
  window.onpopstate = function() {
    history.go(1);
  };
};

/* MSIE used to detect old browsers and Trident used to newer ones*/
export const isIE =
  navigator.userAgent.indexOf("MSIE ") > -1 ||
  navigator.userAgent.indexOf("Trident/") > -1;

export const isiOS =
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const isOSX =
  !!navigator.platform && /Mac68K|MacPPC|MacIntel/.test(navigator.platform);

export const isSafari =
  navigator.userAgent.indexOf("Safari") !== -1 &&
  navigator.userAgent.indexOf("Chrome") === -1
    ? true
    : false;
