import React, { Component } from "react";
import {
  Heading,
  Paragraph,
  Button,
  Link,
  HorizontalRule
} from "@piggybank/core";
import content from "../../i18n/content/error.js";
import { AppConsumer } from "../../shared/appContext";
import GenericErrorPage from "../../pages/GenericErrorPage";
import { view } from "../../shared/analytics/tealium/events";
import { TRACK_TECHNICAL_ERROR } from "../../shared/analytics/pageData.js";
import { utagGlobal } from "../../shared/analytics/tealium";

const eventTaggingConstants = {
  page_url: TRACK_TECHNICAL_ERROR.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_TECHNICAL_ERROR.funnel_step_name
};
class Error extends Component {
  componentDidMount() {
    TRACK_TECHNICAL_ERROR.event_subcategory = this.props.errorCode;
    view(Object.assign(utagGlobal, TRACK_TECHNICAL_ERROR));
  }
  handleRedirect(queryString) {
    window.location = "/" + queryString;
  }

  render() {
    return (
      <GenericErrorPage
        eventTaggingConstants={eventTaggingConstants}
        {...this.props}
      >
        <Paragraph lead data-bdd="page-intro">
          {content.pageIntro}
        </Paragraph>
        <HorizontalRule marginBottom={7} />
        <Heading level={2}>{content.pageSubheading}</Heading>
        <Paragraph data-bdd="page-copy">
          {content.pageCopy}{" "}
          <Link data-bdd="contact-link" external href={content.link.url}>
            {content.link.copy}
          </Link>
        </Paragraph>
        {this.props.paramsInvalid === false && (
          <AppConsumer.Consumer>
            {val => {
              return (
                <Button
                  data-bdd="start-again-button"
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    this.handleRedirect(val.queryString);
                  }}
                >
                  {content.buttonLabel}
                </Button>
              );
            }}
          </AppConsumer.Consumer>
        )}
      </GenericErrorPage>
    );
  }
}

export default Error;
