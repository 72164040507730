import { trimmedCountryName } from ".";

export default array => {
  if (array && array.filter(value => value !== "").length > 0) {
    const countries = [];
    array.forEach((countryCode, i) => {
      (function(i) {
        countries.push(country(countryCode, i));
      })(i);
    });
    return countries;
  } else {
    return undefined;
  }
};

const country = (country, index) => ({
  index,
  resCountryForTax: trimmedCountryName(country),
  resCntryCodeForTax: country
});
