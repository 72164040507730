import React, { Component } from "react";
import {
  FormLayout,
  Section,
  Heading,
  Paragraph,
  Button,
  IconStatusInformationOnlight,
  Link,
  PlaybackList,
  PlaybackValue,
  PlaybackKey
} from "@piggybank/core";
import content from "../../i18n/content/refer.js";
import { view } from "../../shared/analytics/tealium/events";
import { utagGlobal } from "../../shared/analytics/tealium";
import { TRACK_REFER_PAGE } from "../../shared/analytics/pageData.js";
import formatTitle from "../../shared/transformations/formatTitle";

class Refer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    TRACK_REFER_PAGE.application_id = this.props.applicationId;
    view(Object.assign(utagGlobal, TRACK_REFER_PAGE));
    document.title = formatTitle(content.pageTitle);
  }

  handleRedirect() {
    window.location = "https://bank.marksandspencer.com/";
  }

  render() {
    return (
      <FormLayout>
        <Section>
          <div style={{ margin: "3px 0px 3px -2px", padding: "3px" }}>
            <IconStatusInformationOnlight width="36" data-bdd="info-icon" />
          </div>

          <Heading level={1} data-bdd="page-title" marginBottom={5}>
            {content.pageTitle}
          </Heading>
          <PlaybackList id="app-ref-number">
            <PlaybackKey>{content.pageCopy1}</PlaybackKey>
            <PlaybackValue>{this.props.applicationId}</PlaybackValue>
          </PlaybackList>
          <Paragraph data-bdd="page-copy2" marginBottom={7}>
            {content.pageCopy2}
          </Paragraph>
          <Heading level={2} data-bdd="page-sub-heading">
            {content.pageSubHeading}
          </Heading>
          <Paragraph data-bdd="page-copy3" marginBottom={3}>
            {content.pageCopy3}
          </Paragraph>
          <Paragraph data-bdd="page-copy4" marginBottom={5}>
            {content.pageCopy4}
            <Link
              href={content.pageLinkUrl1}
              external
              textMap={{ newWindow: "Opens in a new window" }}
            >
              {content.pageLink1}.
            </Link>
          </Paragraph>
          <Paragraph style={{ fontWeight: "bold" }} marginBottom={0}>
            {content.pageCopy5}
          </Paragraph>
          <Paragraph marginBottom={5}>
            {content.addressLine1}
            <br />
            {content.addressLine2}
            <br />
            {content.addressLine3}
            <br />
            {content.addressLine4}
            <br />
            {content.addressLine5}
          </Paragraph>
          <Paragraph data-bdd="page-copy7" marginBottom={4}>
            {content.pageCopy7}
          </Paragraph>
          <Paragraph data-bdd="page-copy8" marginBottom={6}>
            {content.pageCopy8}
            <Link
              href={content.pageLinkUrl3}
              external
              textMap={{ newWindow: "Opens in a new window" }}
            >
              {content.pageLink3}.
            </Link>
          </Paragraph>
          <Button
            data-bdd="home-page-button"
            type="button"
            onClick={e => {
              e.stopPropagation();
              this.handleRedirect();
            }}
          >
            {content.buttonLabel}
          </Button>
        </Section>
      </FormLayout>
    );
  }
}

export default Refer;
