export default [
  {
    value: "0",
    label: "Apply increases automatically unless I contact you to decline it",
    tealiumLabel: "apply"
  },
  {
    value: "4",
    label: "Do not apply future credit increases without my explicit consent",
    tealiumLabel: "don't apply"
  }
];
