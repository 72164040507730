export const utagGlobal = {
  page_business_line: "rbwm",
  page_customer_group: "general",
  page_language: "en",
  page_security_level: "0",
  site_type: "single_page",
  site_section: "pws",
  page_type: "application",
  page_category: "application",
  page_subcategory: "credit cards",
  product_category: "credit cards",
  product_id: "510",
  product_subcategory: "",
  funnel_name: "credit cards ntb - marks and spencer"
};

export const valueToLabel = (inputValue, field) => {
  var status;
  for (var i = field.length; i--; ) {
    if (field[i].value === inputValue) {
      status = field[i].label;
      break;
    }
  }
  return status.toLowerCase();
};

export const valueToTealiumLabel = (inputValue, field) => {
  var status;
  for (var i = field.length; i--; ) {
    if (field[i].value === inputValue) {
      status = field[i].tealiumLabel;
      break;
    }
  }
  if (status == null) {
    return "";
  } else {
    return status.toLowerCase();
  }
};

export const validationErrors = errors => {
  const validationKeysArray = Object.keys(errors);

  for (var i = 0; i < validationKeysArray.length; i++) {
    validationKeysArray[i] = "invalid " + validationKeysArray[i];
  }

  return validationKeysArray.join(" & ");
};
