import { countries } from "../../i18n/lookups/countries";

export default code => {
  var label;
  for (var i = countries.length; i--; ) {
    if (countries[i].value === code) {
      label = countries[i].label.substring(0, 40);
      break;
    }
  }
  return label;
};
