import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Section,
  Heading,
  Reveal,
  Paragraph,
  HorizontalRule,
  Row,
  Cell
} from "@piggybank/core";
import {
  Form,
  Field,
  Label,
  Select,
  FieldFeedback,
  FormFeedback,
  Navigation
} from "@piggybank/form";
import { employmentSchema } from "../../shared/validation-schema.js";
import { employmentStatus } from "../../i18n/lookups/employmentStatus";
import content from "../../i18n/content/employmentDetails.js";
import RadioButton from "../../organisms/RadioButton";
import Employed from "../../organisms/Employed";
import EndDate from "../../organisms/EndDate";
import { link, view } from "../../shared/analytics/tealium/events";
import {
  valueToLabel,
  utagGlobal,
  validationErrors
} from "../../shared/analytics/tealium";
import { TRACK_EMPLOYMENT_DETAILS } from "../../shared/analytics/pageData.js";
import Timer from "../../components/Timer";
import FormWrapper from "../../organisms/FormWrapper/index.js";
import formatTitle from "../../shared/transformations/formatTitle";

const initialValues = {
  "employment-status": "",
  occupation: "",
  "company-name": "",
  "employment-start-date": "",
  "address-line-1": "",
  "address-line-2": "",
  postcode: ""
};

// eslint-disable-next-line
// const testingValues = {
//   "employment-status": "EF",
//   occupation: "PRO",
//   "company-name": "M&S"
// };

const reviewData = values => {
  if (!isEmployedStatus(values["employment-status"])) {
    removeFields(values, employedFields);
  }
  if (!isAtHomeStatus(values["employment-status"])) {
    removeFields(values, atHomeFields);
  }
  if (!isUnemployedStatus(values["employment-status"])) {
    removeFields(values, unemployedFields);
  }
  if (!isStudentStatus(values["employment-status"])) {
    removeFields(values, studentFields);
  }
};

const removeFields = (values, array) => {
  for (var i = 0; i < array.length; i++) {
    values[array[i]] = undefined;
  }
};

const isEmployedStatus = status => ["FULL", "PART", "SELF"].includes(status);
const isAtHomeStatus = status => ["HOME"].includes(status);
const isUnemployedStatus = status => ["UNEMPLOYED"].includes(status);
const isStudentStatus = status => ["STUDENT"].includes(status);

const employedFields = [
  "occupation",
  "company-name",
  "employment-start-date",
  "address-line-1",
  "address-line-2",
  "postcode"
];
const atHomeFields = ["income-from-benefits"];
const unemployedFields = ["unemployment-benefit"];
const studentFields = ["course-end-date"];

const eventTaggingConstants = {
  page_name: TRACK_EMPLOYMENT_DETAILS.page_name,
  page_url: TRACK_EMPLOYMENT_DETAILS.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_EMPLOYMENT_DETAILS.funnel_step_name
};

class EmploymentDetails extends Component {
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_EMPLOYMENT_DETAILS));
    document.title = formatTitle(content.pageTitle);
  }

  render() {
    return (
      <FormWrapper>
        <div className="employment-details">
          <Section marginBottom={6}>
            <Heading level={1}>{content.pageTitle}</Heading>
            <Paragraph lead marginBottom={4}>
              {content.pageIntro}
            </Paragraph>
            <HorizontalRule />
          </Section>

          <Form
            initialValues={initialValues}
            validationSchema={employmentSchema}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
            onSubmit={(next, rest) => {
              link({
                ...eventTaggingConstants,
                event_category: "content",
                event_action: "button",
                event_content: `employment status: ${valueToLabel(
                  rest.values["employment-status"],
                  employmentStatus
                )}`
              });
              next(rest);
            }}
          >
            {({ values }) => (
              <>
                <FormFeedback />

                <Section marginBottom={3}>
                  <Heading level={2} marginBottom={4}>
                    {content.employmentStatus.heading}
                  </Heading>
                  <Field name="employment-status" data-bdd="employment-status">
                    <Label>{content.employmentStatus.label}</Label>
                    <Row>
                      <Cell sizeMedium={7} sizeLarge={5}>
                        <Select
                          fullWidth
                          options={employmentStatus}
                          placeholder="Please select"
                        />
                      </Cell>
                    </Row>
                    <FieldFeedback />
                  </Field>
                </Section>

                <Reveal marginBottom={2} duration={400}>
                  {isEmployedStatus(values["employment-status"]) && (
                    <Employed content={content} values={values} />
                  )}
                  {isAtHomeStatus(values["employment-status"]) && (
                    <RadioButton
                      content={content.atHome}
                      name="income-from-benefits"
                    />
                  )}
                  {isUnemployedStatus(values["employment-status"]) && (
                    <RadioButton
                      content={content.unemploymentBenefits}
                      name="unemployment-benefit"
                    />
                  )}
                  {isStudentStatus(values["employment-status"]) && (
                    <EndDate content={content.courseEndDate} />
                  )}
                </Reveal>

                <Section>
                  <HorizontalRule />
                  <Navigation
                    id="next"
                    textMap={{ previous: "Back", next: "Continue" }}
                    onNext={(next, rest) => {
                      reviewData(values);
                      next(rest);
                    }}
                  />
                </Section>
              </>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormWrapper>
    );
  }
}

EmploymentDetails.propTypes = {
  values: PropTypes.object
};
export default EmploymentDetails;
