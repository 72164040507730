import { isNotEmptyObject } from "../utils";
import { sanitiseField } from "../utils";

export default previousNames => {
  const names = [];
  for (var i = 0; i < previousNames.length; i++) {
    if (isNotEmptyObject(previousNames[i])) {
      (function(i) {
        names.push(
          name(
            sanitiseField(previousNames[i].previousForename),
            previousNames[i].previousMiddlenames
              ? sanitiseField(previousNames[i].previousMiddlenames)
              : undefined,
            sanitiseField(previousNames[i].previousSurname),
            i
          )
        );
      })(i);
    }
  }
  return names;
};

const name = (forename, middleName, surname, index) => ({
  index,
  forename,
  middleName,
  surname
});
